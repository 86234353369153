import React from 'react';
import {useLocation} from "react-router-dom";
import MDBox from "../components/MDBox";
import Footer from "./Footer";
import Header from "./Header";
import MainRoutes from "../routes";

const MainContent = ({collapsed, toggled, handleCollapse, handleToggle}) => {
    const {pathname} = useLocation();
    let routes = pathname.split('/') ?? [];
    routes = routes.map(route => route.length === 24 ? 'User' : route);

    return (
        <MDBox component="main" bgColor="light">
            <Header routes={routes} handleToggle={handleToggle}/>
            <MainRoutes/>
            <Footer/>
        </MDBox>
    );
};

export default MainContent;