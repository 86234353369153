import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "elements/DataTable/DataTableHeadCell";
import DataTableBodyCell from "elements/DataTable/DataTableBodyCell";
import {
    faChevronLeft,
    faChevronRight,
    faCoins,
    faEdit,
    faEllipsisV,
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import MDButton from "../../components/MDButton";
import {Menu, MenuItem, Tooltip} from "@mui/material";
import {separateCamelCases, withoutSpaces} from "../../utils/functions";

function DataTable({
                       entriesPerPage,
                       canSearch,
                       showTotalEntries,
                       hideHeader,
                       table,
                       pagination,
                       isSorted,
                       noEndBorder,
                       icon,
                       manage,
                       onManageClick
                   }) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries.map((el) => el.toString())
        : ["5", "10", "15", "20", "25"];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const title = useMemo(() => table.title, [table]);

    const generateAnchorElList = table => table.rows.reduce((a, v) => ({...a, [v._id]: null}), {});
    const [anchorElList, setAnchorElList] = useState(() => generateAnchorElList(table));
    const handleOpen = (e, id) => setAnchorElList({...anchorElList, [id]: e.currentTarget});
    const handleClose = () => setAnchorElList(generateAnchorElList(table));

    const tableInstance = useTable(
        {columns, data, initialState: {pageIndex: 0}},
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: {pageIndex, pageSize, globalFilter},
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = (value) => setPageSize(value);

    // Render the pagination's
    const renderPagination = pageOptions.map((option) => (
        <MDPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </MDPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({target: {value}}) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({target: value}) => gotoPage(Number(value.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asc";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    const ifCellIsArray = cell => {
        return (<>
            {cell.value?.length ?
                cell.value.map((val, i) => {
                    return <p key={i}>{val}</p>
                })
                : ''
            }
        </>);
    }

    return (
        <>
            {icon ?
                <MDBox display="flex" px={2} py={1}>
                    <MDBox
                        color="white"
                        bgColor="info"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                        mt={-3}
                        mr={2}
                        width={60}
                        height={60}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {icon}
                    </MDBox>
                    <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                        {title ? separateCamelCases(title) : ''}
                    </MDTypography>
                </MDBox>
                :
                <MDTypography variant="h6" textAlign="center" sx={{my: 2}} textTransform="uppercase">
                    {title ? separateCamelCases(title) : ''}
                </MDTypography>
            }

            {entriesPerPage || canSearch ? (
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <MDBox display="flex" alignItems="center">
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{width: "5rem"}}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                            <MDTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                            </MDTypography>
                        </MDBox>
                    )}
                    {canSearch && (
                        <MDBox width="12rem" ml="auto">
                            <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({currentTarget}) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    )}
                </MDBox>
            ) : null}
            <Table {...getTableProps()}>
                {hideHeader ? null
                    :
                    <MDBox component="thead">
                        {headerGroups.length > 0 ?
                            headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <DataTableHeadCell
                                            {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                            width={column.width ? column.width : "auto"}
                                            align={column.align ? column.align : "left"}
                                            sorted={setSortedValue(column)}
                                        >
                                            {column.render("Header")}
                                        </DataTableHeadCell>
                                    ))}
                                    {manage &&
                                        <MDBox
                                            component="th"
                                            bgColor="light"
                                            width="20%!important"
                                            textAlign="end"
                                            py={1.5}
                                            px={3}
                                            sx={({palette: {light}, borders: {borderWidth}}) => ({
                                                borderBottom: `${borderWidth[1]} solid ${light.main}`,
                                            })}
                                        >
                                            <MDButton iconOnly circular color="success"
                                                      onClick={() => onManageClick(true, withoutSpaces(title), null)}
                                            >
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </MDButton>

                                        </MDBox>}
                                </TableRow>
                            ))
                            :
                            <TableRow>
                                <MDBox
                                    component="th"
                                    bgColor="light"
                                    width="20%!important"
                                    textAlign="end"
                                    py={1.5}
                                    px={3}
                                    sx={({palette: {light}, borders: {borderWidth}}) => ({
                                        borderBottom: `${borderWidth[1]} solid ${light.main}`,
                                    })}
                                >
                                    <MDButton iconOnly circular color="success"
                                              onClick={() => onManageClick(true, withoutSpaces(title), null)}
                                    >
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </MDButton>

                                </MDBox>
                            </TableRow>
                        }
                    </MDBox>
                }
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                        return (
                                            <DataTableBodyCell
                                                style={{
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                    width: "100%"
                                                }}
                                                noBorder={noEndBorder && rows.length - 1 === key}
                                                align={cell.column.align ? cell.column.align : "left"}

                                                {...cell.getCellProps()}
                                            >
                                                {cell.column?.Header === 'img' ?
                                                    (
                                                        <MDBox
                                                            component="img"
                                                            src={process.env.REACT_APP_API_URL + '/' + cell.value}
                                                            alt="image"
                                                            width="120px"

                                                        />
                                                    )
                                                    :
                                                    (
                                                        typeof cell.value !== 'object' ?
                                                            cell.value.length > 50 ?
                                                                <Tooltip title={cell.value} arrow>
                                                                    <MDButton color="light" size="small">Text</MDButton>
                                                                </Tooltip>
                                                                :
                                                                cell.render("Cell")
                                                            :
                                                            ifCellIsArray(cell)
                                                    )
                                                }
                                            </DataTableBodyCell>
                                        )
                                    }
                                )}
                                {manage &&
                                    <MDBox
                                        component="td"
                                        py={1.5}
                                        px={3}
                                        textAlign="end"
                                        sx={({palette: {light}, typography: {size}, borders: {borderWidth}}) => ({
                                            fontSize: size.sm,
                                            borderBottom: noEndBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
                                        })}
                                    >
                                        <MDButton
                                            iconOnly size="small" circular
                                            onClick={e => handleOpen(e, row.original._id)}
                                        >
                                            <FontAwesomeIcon icon={faEllipsisV}/>
                                        </MDButton>
                                        <Menu
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            anchorEl={anchorElList[row.original._id]}
                                            open={Boolean(anchorElList[row.original._id])}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                                <MDButton variant="contained" color="white" size="small" iconOnly
                                                          onClick={() => onManageClick(true, withoutSpaces(title), row.original?._id)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </MDButton>
                                            </MenuItem>
                                            <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                                <MDButton variant="contained" color="white" size="small" iconOnly
                                                          onClick={() => onManageClick(false, withoutSpaces(title), row.original?._id)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </MDButton>
                                            </MenuItem>
                                        </Menu>
                                    </MDBox>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <MDBox
                display="flex"
                flexDirection={{xs: "column", sm: "row"}}
                justifyContent="space-between"
                alignItems={{xs: "flex-start", sm: "center"}}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <MDBox mb={{xs: 3, sm: 0}}>
                        <MDTypography variant="button" color="secondary" fontWeight="regular">
                            Showing {entriesStart} to {entriesEnd} of {rows.length} entries
                        </MDTypography>
                    </MDBox>
                )}
                {pageOptions.length > 1 && (
                    <MDPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <MDPagination item onClick={() => previousPage()}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{fontWeight: 'bold'}}/>
                            </MDPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <MDBox width="5rem" mx={1}>
                                <MDInput
                                    inputProps={{type: "number", min: 1, max: customizedPageOptions.length}}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={(handleInputPagination, handleInputPaginationValue)}
                                />
                            </MDBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <MDPagination item onClick={() => nextPage()}>
                                <FontAwesomeIcon icon={faChevronRight} style={{fontWeight: 'bold'}}/>
                            </MDPagination>
                        )}
                    </MDPagination>
                )}
            </MDBox>
        </>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    table: {title: '', rows: [], columns: []},
    entriesPerPage: {defaultValue: 10, entries: [5, 10, 15, 20, 25]},
    canSearch: false,
    showTotalEntries: true,
    pagination: {variant: "gradient", color: "info"},
    isSorted: true,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.any).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    manage: PropTypes.bool,
};

export default DataTable;
