import React, {useContext, useEffect, useState} from 'react';
import {Card, InputAdornment} from "@mui/material";
import MDBox from "../components/MDBox";
import MDButton from "../components/MDButton";
import useServer from "../hooks/useServer";
import {PAYMENT, PAYMENT_SEARCH} from "../utils/serverUrls";
import usePagination from "../hooks/usePagination";
import PaymentsTable from "../elements/PaymentsTable";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import {toast, ToastContainer} from "react-toastify";
import {initialPayment, paymentStatuses, toastConfig} from "../utils/constants";
import useFilters from "../hooks/useFilters";
import useModal from "../hooks/useModal";
import Swal from "sweetalert2";
import MDInput from "../components/MDInput";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const sortOptions = [
    {label: 'newest', value: {column: 'paidAt', order: 'desc'}},
    {label: 'oldest', value: {column: 'paidAt', order: 'asc'}},
    {label: 'amount (low to high)', value: {column: 'amount', order: 'asc'}},
    {label: 'amount (high to low)', value: {column: 'amount', order: 'desc'}}
];

const selectOptions = [
    {label: 'Status', column: 'status', value: paymentStatuses}
];

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [searchPaymentsResponse, searchPaymentsError, searchPaymentsIsLoading, searchPaymentsSendRequest, searchPaymentsSetError] = useServer(PAYMENT_SEARCH);
    const [paymentResponse, paymentError, paymentIsLoading, paymentSendRequest, paymentSetError] = useServer(PAYMENT);
    const [searchById, setSearchById] = useState('');

    const paymentsPagination = usePagination(10);
    const paymentsFilter = useFilters({
        sortOptions,
        selectOptions,
        dateRange: true
    })

    const handleFilterById = () => {
        if (searchById.length > 0) {
            paymentsFilter.setFilters(prevState => ({...prevState, id: searchById}))
            setSearchById('');
        }
    }

    const modal = useModal();
    useEffect(() => {
        if (modal.sendRequestOptions) paymentSendRequest(modal.sendRequestOptions);
    }, [modal.sendRequestOptions])

    // On manage payment y clicking filter/add/edit/delete button
    const handleChangePayment = (actionType, value, column) => {
        switch (actionType) {
            case 'filter':
                if (column === 'date') {
                    if (Object.keys(paymentsFilter.filters).find(key => key === 'startDate' || key === 'endDate')) {
                        paymentsFilter.setFilters(prevState => {
                            const {startDate, endDate, ...newState} = prevState;
                            return newState;
                        })
                    } else paymentsFilter.setFilters(prevState => ({...prevState, startDate: value, endDate: value}))
                } else {
                    if (Object.keys(paymentsFilter.filters).find(key => key === column)) {
                        paymentsFilter.setFilters(prevState => {
                            const newState = {...prevState};
                            delete newState[column];
                            return newState;
                        })
                    } else paymentsFilter.setFilters(prevState => ({...prevState, [column]: value}))
                }
                break;
            case 'add':
                modal.setFormData({title: 'payment', item: initialPayment});
                break;
            case 'edit':
                modal.setFormData({title: 'payment', item: payments.find(payment => payment._id === value)})
                break;
            case 'delete':
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    confirmButtonText: 'delete',
                    confirmButtonColor: 'red',
                    showDenyButton: true,
                    denyButtonText: 'cancel',
                    denyButtonColor: 'green',
                    width: '320px'
                }).then(result => {
                    if (result.isConfirmed) {
                        const option = {method: 'delete', data: {id: value}};
                        paymentSendRequest(option);
                    } else if (result.isDenied) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Item is not deleted',
                            showConfirmButton: false,
                            timer: 1500,
                            width: '320px'
                        })
                    }
                })
                break;
            default:
                break;
        }
    }

    // On Paginate change send search payments request
    useEffect(() => {
        if (paymentsPagination?.paginate || paymentsFilter?.filters || paymentsFilter?.sort) {
            searchPaymentsSendRequest({
                method: 'post', params: Object.assign(
                    {...paymentsPagination.paginate},
                    {...paymentsFilter.filters},
                    {...paymentsFilter.sort}
                )
            })
        }

    }, [paymentsPagination.paginate])

    // On filters or sort change set paginate
    useEffect(() => {
        paymentsPagination.setPaginate(prevState => ({...prevState, offset: 0}))
    }, [paymentsFilter.filters, paymentsFilter.sort])

    // On Payments Response
    useEffect(() => {
        if (searchPaymentsResponse) {
            setPayments(searchPaymentsResponse.data?.payments);
            paymentsPagination.setPagination(searchPaymentsResponse.data?.pagination)
        }
    }, [searchPaymentsResponse])

    // On Payment Response
    useEffect(() => {
        if (paymentResponse) {
            if (typeof paymentResponse.data === 'object') {
                if (modal.formData?.item?._id) {
                    setPayments(prevState => (prevState.map(payment => payment._id === paymentResponse.data._id ?
                        paymentResponse.data : payment
                    )));
                } else {
                    setPayments(prevState => ([paymentResponse.data, ...prevState]));
                    paymentsPagination.setPagination(prevState => (
                        {...prevState, total: prevState.total + 1, pages: Math.ceil((prevState.total + 1) / prevState.onPage)}
                    ));
                }
            } else if (typeof paymentResponse.data === 'string') {
                setPayments(prevState => (prevState.filter(payment => payment._id !== paymentResponse.data)));
                paymentsPagination.setPagination(prevState => (
                    {...prevState, total: prevState.total - 1, pages: Math.ceil((prevState.total - 1) / prevState.onPage)}
                ));
            }
        }
    }, [paymentResponse])

    // On Error
    useEffect(() => {
        if (searchPaymentsError) {
            toast.error(searchPaymentsError.data?.message ?? 'Something went wrong', toastConfig);
            searchPaymentsSetError(null);
        }
        if (paymentError) {
            toast.error(paymentError.data?.message ?? 'Something went wrong', toastConfig);
            paymentSetError(null);
        }
        return () => {
            searchPaymentsSetError(false);
            paymentSetError(false);
        }
    }, [searchPaymentsError, paymentError]);

    // On Loading
    useEffect(() => {
        if (searchPaymentsIsLoading || paymentIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [searchPaymentsIsLoading, paymentIsLoading])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <MDBox>
                <MDBox>
                    <Card sx={{mb: 2, p: 1}}>
                        <MDBox display="flex">
                            <MDButton size="small" color="info" sx={{mr: 1}}>NEW ORDER</MDButton>
                            <MDButton size="small" color="info" sx={{ml: 1}}>NEW REFUND</MDButton>
                            <MDInput
                                label="Search by ID"
                                value={searchById}
                                onChange={e => setSearchById(e.target.value)}
                                size="small"
                                sx={{ml: 'auto'}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <MDButton size="small" iconOnly
                                                      color={searchById.length > 0 ? 'info' : 'white'}
                                                      onClick={handleFilterById}
                                            >
                                                <FontAwesomeIcon icon={faSearch}/>
                                            </MDButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MDBox>
                    </Card>
                </MDBox>
                <MDBox>
                    <Card sx={{my: 3, p: 1}}>
                        {paymentsFilter.filtersContainer}
                        <PaymentsTable
                            hideTitle={true}
                            payments={payments}
                            showUser={true}
                            change={handleChangePayment}
                        />
                        {paymentsPagination.paginationContainer}
                    </Card>
                </MDBox>
            </MDBox>
            {modal.modalContainer}
        </MDBox>
    );
};

export default Payments;