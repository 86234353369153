import React, {useEffect, useState} from 'react';
import MDPagination from "../components/MDPagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";

const usePagination = limit => {
    const [pagination, setPagination] = useState({currentPage: 1, onPage: 10, pages: 1, total: 0});
    const [paginate, setPaginate] = useState({offset: 0, limit});
    const [paginationContainer, setPaginationContainer] = useState();
    const {currentPage, onPage, pages, total} = pagination;

    const renderPagination = Array.from(new Array(pages < 5 ? pages : 5)).map((v, i) => (
        <MDPagination key={i} item active={currentPage === i + (pages < 5 ? 1 : currentPage)}
                      onClick={() => handlePagination(i + (pages < 5 ? 1 : currentPage))}
        >
            {i + (pages < 5 ? 1 : currentPage)}
        </MDPagination>
    ))

    const setDefaultPaginate = () => setPaginate({offset: 0, limit});

    // Handle pagination control
    const handlePagination = page => {
        let offset = page === 0 ? pagination.onPage * (pagination.pages - 1) : pagination.onPage * (page - 1);
        setPaginate({...paginate, offset});
    }

    useEffect(() => {
        setPaginationContainer(
            <MDBox sx={{my: 2, mx: 1}}>
                <MDPagination>
                    <MDBox mr="auto">
                        <MDTypography variant="button">
                            {total === 0
                                ? 0
                                : ((currentPage - 1) * onPage + 1) + ' - ' + (currentPage !== pages ? (currentPage - 1) * onPage + onPage : total)
                            } of {total}
                        </MDTypography>
                    </MDBox>
                    <MDPagination item onClick={() => handlePagination(1)} disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </MDPagination>
                    <MDPagination item onClick={() => handlePagination(currentPage - 1)} disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </MDPagination>
                    {renderPagination}
                    <MDPagination item onClick={() => handlePagination(currentPage + 1)}
                                  disabled={currentPage === pages}>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </MDPagination>
                    <MDPagination item onClick={() => handlePagination(0)} disabled={currentPage === pages}>
                        <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </MDPagination>
                </MDPagination>
            </MDBox>
        );
    }, [pagination])


    return {pagination, setPagination, paginate, setPaginate, renderPagination, paginationContainer, handlePagination, setDefaultPaginate};
};

export default usePagination;