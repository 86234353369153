import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Line} from "react-chartjs-2";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "./configs";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClockLoader} from "react-spinners";
import {spinnerColor, spinnerCss} from "../../utils/constants";
import {Tab, Tabs} from "@mui/material";

const spinner = (
    <MDBox display="flex" flex="1 0 auto" alignItems="center" height="100%">
        <ClockLoader color={spinnerColor} css={spinnerCss} size={100}/>
    </MDBox>
);

function ReportsLineChart({color, title, description, date, chart, activeTab, handleSetTab}) {
    const {data, options} = configs(chart.labels || [], chart.datasets || {});

    return (
        <Card sx={{height: "100%"}}>
            <MDBox padding="1rem">
                {useMemo(
                    () => (
                        <MDBox
                            variant="gradient"
                            bgColor={color}
                            borderRadius="lg"
                            coloredShadow={color}
                            py={2}
                            pr={0.5}
                            mt={-5}
                            height="12.5rem"
                        >
                            {chart?.datasets ?
                                <Line data={data} options={options}/>
                                : spinner
                            }
                        </MDBox>
                    ),
                    [chart, color]
                )}
                <MDBox my={2}>
                    <Tabs value={activeTab ?? 0} onChange={handleSetTab}>
                        <Tab label="week"/>
                        <Tab label="month"/>
                        <Tab label="year"/>
                        <Tab label="10 years"/>
                    </Tabs>
                </MDBox>
                <MDBox pt={3} pb={1} px={1}>
                    <MDTypography variant="h6" textTransform="capitalize">
                        {title}
                    </MDTypography>
                    <MDTypography component="div" variant="button" color="text" fontWeight="light">
                        {description}
                    </MDTypography>
                    <Divider/>
                    <MDBox display="flex" alignItems="center">
                        <MDTypography variant="button" color="text" lineHeight={1} sx={{mt: 0.15, mr: 0.5}}>
                            <FontAwesomeIcon icon={faClock}/>
                        </MDTypography>
                        <MDTypography variant="button" color="text" fontWeight="light">
                            {date}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
    color: "dark",
    description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    date: PropTypes.string.isRequired,
    chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
