import React from 'react';
import MDTypography from "../components/MDTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import MDBox from "../components/MDBox";

const Footer = () => {
    return (
        <MDBox component="footer" sx={{mt: 'auto', textAlign: 'center'}}>
            <MDTypography component="div" variant="overline">
                © {new Date().getFullYear()} made with <FontAwesomeIcon icon={faHeart} style={{color: 'red'}}/> by
                -{' '}
                <MDBox component="a" target="_blank" rel="noopener noreferrer" href="https://ivan4usa.com">
                    Ivan Poluboiarynov
                </MDBox>
            </MDTypography>
        </MDBox>
    );
};

export default Footer;