import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Card,
    CardActions,
    CardContent, Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal, OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Slide,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import MDBox from "../components/MDBox";
import {colors, modalStyle, statuses} from "../utils/constants";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {cutAmountOf} from "../utils/functions";
import MDInput from "../components/MDInput";
import {StyledDropZone} from "react-drop-zone";
import useServer from "./useServer";
import {AUTH, COMMON_DATA} from "../utils/serverUrls";
import MDDatePicker from "../components/MDDatePicker";
import moment from "moment";

const productContent = [
    {title: 'Gifts', value: 'amountOfGifts'},
    {title: 'Letters', value: 'amountOfLetters'},
    {title: 'Actions', value: 'amountOfActions'}
];

const getCommonData = key => {
    switch (key) {
        case 'maritalStatus':
            return 'maritalStatuses';
        case 'goal':
            return 'goals';
        case 'religion':
            return 'religions';
        case 'education':
            return 'educations';
        case 'eyeColor':
            return 'eyeColors';
        case 'hairColor':
            return 'hairColors';
        case 'smoking':
            return 'smokingHabits';
        case 'drinking':
            return 'drinkingHabits';
        case 'children':
            return 'children';
        case 'seeking':
            return 'genders';
        default:
            return key;
    }
}

const useModal = () => {
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [sendRequestOptions, setSendRequestOptions] = useState(null);
    const [usersResponse, , usersIsLoading, usersSendRequest] = useServer(AUTH);
    const [commonDataResponse, commonDataError, commonDataIsLoading, commonDataSendRequest, commonDataSetError] = useServer(COMMON_DATA);
    const [openUsers, setOpenUsers] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [commonData, setCommonData] = useState(null);
    const previewFile = file => URL.createObjectURL(file);

    // On close modal
    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData(null);
    }

    // On save changes in modal window
    const handleSaveChanges = () => {
        if (!formData) return;
        let options;
        if (formData.item?._id) {
            options = {method: 'patch', data: formData.item}
        } else {
            options = {method: 'post', data: formData.item}
        }
        if (formData.item?.img) {
            if (typeof formData.item.img === 'object') {
                let fd = new FormData();
                fd.append('img', formData.item.img)
                fd.append('item', JSON.stringify(formData.item))
                options.data = fd;
            }
        }
        setSendRequestOptions(options);
        setOpenModal(false);
    }

    // Handle select content
    const handleSelectContent = (e, v) => setSelectedOption(v);

    // Handle add content
    const handleAddContent = () => {
        if (selectedOption && formData?.item?.content) {
            let content = [...formData.item.content];
            const index = formData.item.content.findIndex(item => item.option === selectedOption);
            if (index >= 0) content[index].value++;
            else content.push({option: selectedOption, value: 1});
            setFormData({...formData, item: {...formData.item, content}})
        }
    }

    // Handle change regular form inputs
    const handleChangeFormData = (key, value) => {
        setFormData({...formData, item: {...formData.item, [key]: (key === 'height' || key === 'weight') ? (value ? +value : '') : value}})
    }

    const handleChangeDate = (key, value) => {
        setFormData({
            ...formData,
            item: {...formData.item, [key]: value ? moment(value).utc().endOf('day').toDate() : null}
        })
    }

    const handleChangeSelectData = (key, value) => {
        setFormData({...formData, item: {...formData.item, [key]: value}});
    }

    const handleChangeMultipleSelectData = (key, value) => {
        if (formData.item[key].find(data => data._id === value[value.length - 1]._id)) {
            setFormData({...formData,
                item: {
                    ...formData.item,
                    [key]: formData.item[key].filter(item => item._id !== value[value.length - 1]._id)
                }
            })
        } else {
            setFormData({...formData, item: {...formData.item, [key]: value}});
        }
    }

    // Handle change content form inputs
    const handleChangeContent = (key, value) => {
        const content = [...formData.item.content];
        if (value === 'remove') {
            content.splice(key, 1);
        } else {
            content[key] = {...content[key], value};
        }
        setFormData({...formData, item: {...formData.item, content}})
    }

    // Load Common Data
    useEffect(() => {
        if (formData?.title === 'profile') {
            commonDataSendRequest({method: 'get'});
        }
    }, [formData])

    // On Common Data Response
    useEffect(() => {
        if (commonDataResponse) {
            setCommonData(commonDataResponse.data);
        }
    }, [commonDataResponse])

    // Open Modal on FormData set
    useEffect(() => {
        if (formData) setOpenModal(true);
        else if (!formData && openModal) setOpenModal(false);
        return () => setOpenModal(false);
    }, [formData]);

    // On select the user from the usersList
    const handleChangeUserSearch = e => {
        const value = e.target.value;
        if (value && value.length > 2) {
            usersSendRequest({method: 'get', params: {email: value, limit: 100}});
        }
    }

    // On Users Response
    useEffect(() => {
        if (usersResponse) setUsersList(usersResponse.data.users);
    }, [usersResponse])

    // On close users autocomplete input
    useEffect(() => {
        if (!openUsers) setUsersList([]);
    }, [openUsers]);

    // Container of modal window
    const modalContainer = (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '100vh',
                overflowY: 'auto'
            }}
        >
            <Slide direction="down" in={openModal} timeout={700}>
                <MDBox sx={modalStyle}>
                    <Card>
                        <CardContent>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                <MDTypography variant="h5">
                                    {formData?.item?._id ? 'Edit item in ' : 'Add item to '}
                                    {formData?.title ? `'${formData.title}'` : ''}
                                </MDTypography>
                                <MDButton iconOnly sx={{mr: -2}} onClick={handleCloseModal}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </MDButton>
                            </MDBox>
                            {formData && formData.item && Object.keys(formData.item).map(key => {
                                switch (key) {
                                    case '_id':
                                    case '__v':
                                    case 'createdAt':
                                    case 'paidAt':
                                        return null;
                                    case 'img':
                                        return <MDBox key={key} position="relative">
                                            {formData.item?.img ?
                                                <>
                                                    <MDBox component="img" width="300px"
                                                           src={typeof formData.item?.img === 'string' ?
                                                               process.env.REACT_APP_API_URL + '/' + formData.item?.img
                                                               :
                                                               previewFile(formData.item?.img)
                                                           }
                                                    />

                                                    <MDButton
                                                        iconOnly
                                                        color="error"
                                                        sx={{position: 'absolute', bottom: 0, right: 0}}
                                                        onClick={() => setFormData({
                                                            ...formData,
                                                            item: {...formData.item, img: null}
                                                        })}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes}/>
                                                    </MDButton>
                                                </>
                                                :
                                                <StyledDropZone
                                                    accept="image/*"
                                                    onDrop={file => setFormData({
                                                        ...formData,
                                                        item: {...formData.item, img: file}
                                                    })}
                                                    children={"Add Image *"}
                                                    className="picture-dropzone"
                                                />
                                            }
                                        </MDBox>;
                                    case 'color':
                                        return <Autocomplete
                                            key={key}
                                            id="colors"
                                            fullWidth
                                            options={colors}
                                            value={formData.item?.color || null}
                                            onChange={(e, v) => handleChangeFormData(key, v)}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            size="small"
                                            autoHighlight
                                            sx={{my: 1}}
                                            renderOption={(props, option) => (
                                                <MDBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <MDBox
                                                        bgColor={option}
                                                        borderRadius="lg"
                                                        shadow="lg"
                                                        p={2}
                                                        mr={2}
                                                    />
                                                    {option}
                                                </MDBox>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose a color"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />;
                                    case 'description':
                                    case 'review':
                                    case 'short':
                                        return <MDInput
                                            key={key}
                                            value={formData.item[key] ?? ''}
                                            onChange={e => handleChangeFormData(key, e.target.value)}
                                            fullWidth
                                            label={`${key}...`}
                                            multiline
                                            rows={5}
                                            sx={{my: 1}}
                                        />;
                                    case 'onHomePage':
                                        return <FormControl key={key}>
                                            <MDTypography variant="button" color="text">On Home Page</MDTypography>
                                            <RadioGroup row name="on-home-page"
                                                        value={formData.item[key]}
                                                        onChange={e => handleChangeFormData(key, e.target.value)}
                                            >
                                                <FormControlLabel value="true" control={<Radio/>} label="Yes"/>
                                                <FormControlLabel value="false" control={<Radio/>} label="No"/>
                                            </RadioGroup>
                                        </FormControl>;
                                    case 'isPremium':
                                        return <FormControl key={key}>
                                            <MDTypography variant="button" color="text">Membership</MDTypography>
                                            <RadioGroup row name="on-home-page"
                                                        value={formData.item[key]}
                                                        onChange={e => handleChangeFormData(key, e.target.value)}
                                            >
                                                <FormControlLabel value="true" control={<Radio/>} label="Premium"/>
                                                <FormControlLabel value="false" control={<Radio/>} label="Free"/>
                                            </RadioGroup>
                                        </FormControl>;
                                    case 'maritalStatus':
                                    case 'goal':
                                    case 'seeking':
                                    case 'religion':
                                    case 'children':
                                    case 'education':
                                    case 'eyeColor':
                                    case 'hairColor':
                                    case 'smoking':
                                    case 'drinking':
                                        if (!commonData || !commonData[getCommonData(key)]) return null;
                                        else return <FormControl fullWidth key={key} style={{margin: '10px auto'}}>
                                            <InputLabel id={key + 'label'}>{key}</InputLabel>
                                            <Select
                                                labelId={key + 'label'}
                                                id={key}
                                                label={key}
                                                value={commonData[getCommonData(key)].find(data => data._id === formData?.item[key]?._id) ?? ''}
                                                sx={{py: 1}}
                                                onChange={e => handleChangeSelectData(key, e.target.value)}
                                            >
                                                {commonData[getCommonData(key)].length > 0 ?
                                                    commonData[getCommonData(key)].map((data, i) => (
                                                        <MenuItem key={formData.title + i}
                                                                  value={data}>{data.name}</MenuItem>
                                                    ))
                                                    : <CircularProgress color="inherit"
                                                                        size={20}/>
                                                }
                                            </Select>
                                        </FormControl>
                                    case 'languages':
                                    case 'hobbies':
                                        if (!commonData || !commonData[getCommonData(key)]) return null;
                                        else return <FormControl fullWidth key={key} style={{margin: '10px auto'}}>
                                            <InputLabel id={key + 'label'}>{key}</InputLabel>
                                            <Select
                                                labelId={key + 'label'}
                                                id={key}
                                                multiple
                                                value={formData?.item[key] ?? []}
                                                onChange={e => handleChangeMultipleSelectData(key, e.target.value)}
                                                input={<OutlinedInput label={key}/>}
                                                sx={{py: 1}}
                                                renderValue={(selected) => (
                                                    <MDBox sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                        {selected.map((value) => (
                                                            <Chip key={value._id} label={value.name}/>
                                                        ))}
                                                    </MDBox>
                                                )}
                                            >
                                                {commonData[getCommonData(key)].length > 0 ?
                                                    commonData[getCommonData(key)].map((data, i) => (
                                                        <MenuItem
                                                            key={formData.title + i}
                                                            value={data}
                                                            style={
                                                                formData.item[key].find(item => item._id === data._id)
                                                                    ? {background: '#e1e0e0'} : {}
                                                        }
                                                        >
                                                            {data.name}
                                                        </MenuItem>
                                                    ))
                                                    : <CircularProgress color="inherit"
                                                                        size={20}/>
                                                }
                                            </Select>
                                        </FormControl>;
                                    case 'premiumEnds':
                                        return <MDBox key={key}>
                                            <MDTypography variant="caption" color="text">premium ends</MDTypography>
                                            <MDDatePicker
                                                value={formData.item[key]}
                                                input={{
                                                    placeholder: "select date",
                                                    variant: "standard",
                                                    fullWidth: true
                                                }}
                                                onChange={(e, v) => handleChangeDate(key, v)}
                                            />
                                        </MDBox>
                                    case 'content':
                                        return <MDBox key={key}>
                                            <MDTypography variant="body1" sx={{
                                                my: 2,
                                                textAlign: 'center'
                                            }}>
                                                Content
                                            </MDTypography>
                                            {formData.item['content'].map((row, i) =>
                                                <MDBox key={'content-' + i} display="flex" alignItems="center">
                                                    <MDTypography variant="button">
                                                        {cutAmountOf(row.option)}
                                                    </MDTypography>
                                                    <MDInput
                                                        variant="standard"
                                                        type="number"
                                                        data-field={row.option}
                                                        value={row.value}
                                                        onChange={e => handleChangeContent(i, e.target.value)}
                                                        sx={{width: '40px', ml: 'auto'}}
                                                    />
                                                    <MDButton iconOnly
                                                              onClick={() => handleChangeContent(i, 'remove')}>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </MDButton>
                                                </MDBox>
                                            )}
                                            <MDBox textAlign="center" sx={{mt: 2}} display="flex"
                                                   flexDirection="column">

                                                <ToggleButtonGroup
                                                    color="success"
                                                    size="small"
                                                    value={selectedOption}
                                                    fullWidth
                                                    exclusive
                                                    onChange={handleSelectContent}
                                                    sx={{my: 2}}
                                                >
                                                    {productContent.map(content =>
                                                        <ToggleButton key={content.value}
                                                                      value={content.value}>{content.title}</ToggleButton>
                                                    )}
                                                </ToggleButtonGroup>


                                                <MDButton color="warning" size="small"
                                                          onClick={handleAddContent}>
                                                    <FontAwesomeIcon icon={faPlus}
                                                                     style={{marginRight: '10px'}}/>Add content
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>;
                                    case 'user':
                                        return <Autocomplete
                                            key={key}
                                            fullWidth
                                            open={openUsers}
                                            onOpen={() => setOpenUsers(true)}
                                            onClose={() => setOpenUsers(false)}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            getOptionLabel={(option) => option.email}
                                            options={usersList}
                                            value={formData.item?.user || null}
                                            onChange={(e, v) => handleChangeFormData(key, v)}
                                            loading={usersIsLoading}
                                            renderInput={(params) => (
                                                <MDInput
                                                    key={key}
                                                    variant="standard"
                                                    {...params}
                                                    label={key}
                                                    onChange={handleChangeUserSearch}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {usersIsLoading ? <CircularProgress color="inherit"
                                                                                                    size={20}/> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />;
                                    case 'status':
                                        return <Autocomplete
                                            key={key}
                                            id="status"
                                            fullWidth
                                            options={Object.keys(statuses).map(key => statuses[key])}
                                            value={formData.item?.status || null}
                                            onChange={(e, v) => handleChangeFormData(key, v)}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            size="small"
                                            autoHighlight
                                            sx={{my: 1}}
                                            renderOption={(props, option) => (
                                                <MDBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <MDBox
                                                        bgColor={option === statuses.PAID ? 'success' : (option === statuses.PENDING ? 'light' : 'error')}
                                                        borderRadius="lg"
                                                        shadow="lg"
                                                        p={1}
                                                        mr={2}
                                                    />
                                                    {option}
                                                </MDBox>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose a status"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />;
                                    default:
                                        return <MDBox key={key}>
                                            <MDInput
                                                variant="standard"
                                                label={key}
                                                data-field={key}
                                                type={typeof formData.item[key]}
                                                value={formData.item[key] ?? ''}
                                                onChange={e => handleChangeFormData(key, e.target.value)}
                                                fullWidth
                                                sx={{my: 1}}
                                            />
                                        </MDBox>;
                                }
                            })}
                        </CardContent>
                        <CardActions>
                            <MDBox display="flex" justifyContent="space-around" sx={{width: "100%"}}>
                                <MDButton size="small" color="success" sx={{px: 3}}
                                          onClick={handleSaveChanges}
                                >
                                    Save
                                </MDButton>
                                <MDButton size="small" color="dark" onClick={handleCloseModal}>Cancel</MDButton>
                            </MDBox>
                        </CardActions>
                    </Card>
                </MDBox>
            </Slide>
        </Modal>
    );

    return {openModal, setOpenModal, formData, setFormData, sendRequestOptions, modalContainer};
};

export default useModal;