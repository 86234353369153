import React from 'react';
import MDBox from "../../components/MDBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase, faEdit} from "@fortawesome/free-solid-svg-icons";
import MDTypography from "../../components/MDTypography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import MDBadgeDot from "../../components/MDBadgeDot";
import MDButton from "../../components/MDButton";

const ProfileDataTable = ({profile, change}) => {
    return (
        <>
            <MDBox display="flex" px={2}>
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    mt={-3}
                    mr={2}
                    width={60}
                    height={60}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FontAwesomeIcon icon={faDatabase}/>
                </MDBox>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    Profile Data
                </MDTypography>
                <MDBox ml="auto">
                    <MDButton color="success" iconOnly onClick={change}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </MDButton>
                </MDBox>
            </MDBox>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Marital Status
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.maritalStatus ? profile.maritalStatus.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Goal:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.goal ? profile.goal.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Seeking:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.seeking ? profile.seeking.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Children:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.childrenAmount ? profile.childrenAmount.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Religion:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.religion ? profile.religion.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Education:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.education ? profile.education.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Occupation:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.occupation ?? ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Weight:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.weight ?? ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Height:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.height ?? ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Eye Color:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.eyeColor ? profile.eyeColor.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Hair Color:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.hairColor ? profile.hairColor.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Smoking:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.smokingHabit ? profile.smokingHabit.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Drinking:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.drinkingHabit ? profile.drinkingHabit.name : ''}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Languages:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1, pr: 0}} align="right">
                            <MDBox display="flex" alignItems="center" flexWrap="wrap" justifyContent="end">
                                {profile.languages &&
                                    profile.languages.map(language =>
                                        <MDBadgeDot key={language._id} badgeContent={language.name}
                                                    color="success"
                                                    size="md"/>
                                    )}
                            </MDBox>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                Hobbies:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1, pr: 0}} align="right">
                            <MDBox display="flex" alignItems="center" flexWrap="wrap" justifyContent="end">
                                {profile.hobbies &&
                                    profile.hobbies.map(hobby =>
                                        <MDBadgeDot key={hobby._id} badgeContent={hobby.name}
                                                    color="success"
                                                    size="md"/>
                                    )}
                            </MDBox>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </>
    );
};

export default ProfileDataTable;