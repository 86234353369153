import React from 'react';
import MDBox from "../../components/MDBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faEdit} from "@fortawesome/free-solid-svg-icons";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import moment from "moment";

const FinDataTable = ({profile, change}) => {
    if (!profile) return null;
    else return (
        <>
            <MDBox display="flex" px={2}>
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    mt={-3}
                    mr={2}
                    width={60}
                    height={60}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FontAwesomeIcon icon={faCoins}/>
                </MDBox>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    Financial Data
                </MDTypography>
                <MDBox ml="auto">
                    <MDButton color="success" iconOnly onClick={change}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </MDButton>
                </MDBox>
            </MDBox>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Membership
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.isPremium ? "Premium" : "Free"}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    {profile?.premiumEnds &&
                        <TableRow>
                            <TableCell sx={{py: 1}}>
                                <MDTypography variant="subtitle2" fontWeight="bold"
                                              textTransform="capitalize">
                                    Premium Ends:
                                </MDTypography>
                            </TableCell>
                            <TableCell sx={{py: 1}} align="right">
                                <MDTypography variant="body2" textTransform="capitalize">
                                    {profile.premiumEnds ? moment(profile.premiumEnds).format('DD MMM, YYYY') : null}
                                </MDTypography>
                            </TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Letters:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfLetters ?? 0}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Gifts:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfGifts ?? 0}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Actions:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfActions ?? 0}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Greetings:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfGreetings ?? 'unlimited'}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Ice Breakers:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfIceBreakers ?? 'unlimited'}
                            </MDTypography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{py: 1}}>
                            <MDTypography variant="subtitle2" fontWeight="bold"
                                          textTransform="capitalize">
                                Let's Match:
                            </MDTypography>
                        </TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDTypography variant="body2" textTransform="capitalize">
                                {profile.amountOfLetsMatch ?? 'unlimited'}
                            </MDTypography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default FinDataTable;