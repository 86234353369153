import React, {useContext, useEffect, useState} from 'react';
import {Card, CardContent, FormControlLabel, FormGroup, Switch} from "@mui/material";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDInput from "../components/MDInput";
import MDButton from "../components/MDButton";
import MDAvatar from "../components/MDAvatar";
import {toast, ToastContainer} from "react-toastify";
import {toastConfig} from "../utils/constants";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import {AuthContext} from "../contexts/auth/AuthContext";

const Login = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const {login, isLoading, error, setError} = useContext(AuthContext);

    const handleLogin = e => {
        e.preventDefault();
        if (email.trim().length > 0 && password.trim().length > 0) {
            const data = {email, password, rememberMe};
            login(data)
        }
    }

    // On Loading
    useEffect(() => {
        if (isLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [isLoading])

    // On Error
    useEffect(() => {
        if (error) {
            toast.error(error.data?.message ?? 'Something went wrong', toastConfig);
            setError(null);
            return () => setError(false);
        }
    }, [error])

    return (
        <Card style={{minWidth: "360px"}}>
            <ToastContainer/>
            <CardContent>
                <MDBox color="white"
                       bgColor="info"
                       borderRadius="lg"
                       shadow="lg"
                       variant="gradient"
                       px={2}
                       py={2}
                       mt={-3}
                       mb={4}
                >
                    <MDAvatar
                        size="xxl"
                        alt="Avatar"
                        shadow="xxl"
                        bgColor="success"
                        sx={{mx: 'auto', mt: -4, mb: 2}}
                    />
                    <MDTypography color="white" textAlign="center" variant="h4">Sign in</MDTypography>
                </MDBox>
                <MDBox component="form" method="post" autocomplete="off">
                    <MDInput
                        value={email}
                        onChange={e => setEmail(e.target.value ?? '')}
                        fullWidth
                        label="Email"
                        type="text"
                        sx={{my: 1}}
                    />
                    <MDInput
                        value={password}
                        onChange={e => setPassword(e.target.value ?? '')}
                        fullWidth
                        label="Password"
                        type="password"
                        sx={{my: 2}}
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>}
                            label={<MDTypography variant="button" color="text">Remember me</MDTypography>}/>
                    </FormGroup>
                    <MDButton type="submit" color="success" fullWidth sx={{my: 2}}
                              onClick={handleLogin}
                              disabled={email.trim().length === 0 || password.length < 6}
                    >
                        Login
                    </MDButton>
                </MDBox>
            </CardContent>
        </Card>
    );
};

export default Login;