import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleRight,
    faCheckCircle,
    faMinusCircle,
    faSearch,
    faTimes,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import defaultUser from "../../assets/images/default/default-picture.jpg";
import moment from "moment";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TableCell
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import MDInput from "../../components/MDInput";


const ProfilesList = ({
                          title,
                          users,
                          shadow,
                          handleSearch,
                          handleFilter,
                          handleSort,
                          searchOptions,
                          sortOptions,
                          toggleOptions,
                          handleDeleteUsers,
                          paginationContainer,
                          handleClickDetails,
                      }) => {
    const [searchText, setSearchText] = useState('');
    const [searchField, setSearchField] = useState(searchOptions[0]);
    const [sortField, setSortField] = useState(sortOptions[0]);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const isMountRefSort = useRef(false);
    const isMountRefFilter = useRef(false);

    const [toggles, setToggles] = useState(() => toggleOptions);
    useEffect(() => {
        if (isMountRefFilter.current) handleFilter(toggles);
        isMountRefFilter.current = true;
    }, [toggles])

    useEffect(() => {
        if (isMountRefSort.current) {
            handleSort(sortField);
        }
        isMountRefSort.current = true;
    }, [sortField])

    const renderUsers = users?.length ? users.map((user, i) => (
        <TableRow key={'user-' + i + user._id}>
            <TableCell sx={{maxWidth: '48px'}}>
                <Checkbox
                    checked={checkedUsers.includes(user._id)}
                    onChange={
                        (event, checked) => setCheckedUsers(prev => checked
                            ? [...prev, user._id]
                            : prev.filter(item => item !== user._id)
                        )}
                    inputProps={{'aria-label': 'controlled'}}
                />
            </TableCell>
            <TableCell sx={{maxWidth: '48px'}}>
                <MDAvatar
                    src={user.profile?.avatar ? (title === 'Users' ? process.env.REACT_APP_API_URL + '/' + user.profile.avatar : user.profile.avatar) : defaultUser}
                    alt="avatar"
                    shadow="md"
                    sx={{p: 0, m: 0}}
                />
            </TableCell>
            <TableCell>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center"
                       sx={{ml: 2}}>
                    <MDTypography variant="button" fontWeight="medium">
                        {user.profile?.firstname ?? ''} {user.profile?.lastname ?? ''}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                        {user.email ?? ''}
                    </MDTypography>
                </MDBox>
            </TableCell>
            <TableCell>
                {user.activated ?
                    <MDBox display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faCheckCircle} style={{color: 'orange'}}/>
                        <MDTypography component="div" variant="caption">active</MDTypography>
                    </MDBox>
                    :
                    <MDBox display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faMinusCircle}/>
                        <MDTypography component="div" variant="caption">inactive</MDTypography>
                    </MDBox>
                }
            </TableCell>
            <TableCell>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" mx={2}>
                    <MDTypography variant="button" fontWeight="medium">
                        Registered:
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                        {user.registeredAt ? moment(user.registeredAt).format('MMM DD, YYYY') : ''}
                    </MDTypography>
                </MDBox>
            </TableCell>
            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" mx={2}>
                    <MDTypography variant="button" fontWeight="medium">
                        Last activity:
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                        {user.userLastAction?.actionAt ? moment(user.userLastAction?.actionAt).format('MMM DD, YYYY') : ''}
                    </MDTypography>
                </MDBox>
            </TableCell>
            <TableCell width="100px">
                {user.profile ?
                    <>
                        <MDButton
                            sx={{display: {xs: 'block', md: 'none'}}}
                            component={Link} to={user.profile ? '/users/' + user.profile._id : '/users'}
                            variant="contained"
                            circular iconOnly
                            color="info"
                        >
                            <FontAwesomeIcon icon={faAngleDoubleRight}/>
                        </MDButton>
                        <MDButton
                            sx={{display: {xs: 'none', md: 'block'}}}
                            onClick={() => handleClickDetails(user.profile && user.profile._id ? user.profile._id : user._id)}
                            color="info"
                            size="small"
                        >
                            details
                        </MDButton>
                    </>
                    :
                    null
                }
            </TableCell>
            <TableCell>
                <MDButton iconOnly color="error" size="small" onClick={() => {
                    handleDeleteUsers([user._id]);
                    setCheckedUsers(prev => prev.filter(item => item !== user._id))
                }
                }>
                    <FontAwesomeIcon icon={faTrash}/>
                </MDButton>
            </TableCell>
        </TableRow>
    )) : null;

    return (
        <Card sx={{height: "100%", boxShadow: !shadow && "none"}}>
            <MDBox pt={2} px={2}>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    {title ?? ''}
                </MDTypography>

                <MDBox display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <MDBox display="flex" my={1} flexGrow={1}>
                        <MDBox width="120px">
                            <FormControl fullWidth>
                                <InputLabel id="field-label">Field</InputLabel>
                                <Select
                                    labelId="field-label"
                                    id="field"
                                    value={searchField}
                                    onChange={e => setSearchField(e.target.value)}
                                    label="Field"
                                    sx={{py: 1}}
                                >
                                    {searchOptions.map((option, i) => (
                                        <MenuItem key={option.value ?? i} value={option}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </MDBox>
                        <MDBox width="100%">
                            <MDInput
                                label="Search"
                                size="small"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <>
                                            {searchText.trim().length ?
                                                <>
                                                    <MDButton variant="text" color="dark" circular iconOnly
                                                              onClick={() => {
                                                                  setSearchText('');
                                                                  handleSearch('', searchField);
                                                              }}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes}/>
                                                    </MDButton>
                                                    <MDButton variant="text" color="info" circular iconOnly
                                                              onClick={() => handleSearch(searchText, searchField)}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch}/>
                                                    </MDButton>
                                                </>
                                                : ''
                                            }
                                        </>
                                }}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox width={'120px'} my={1}>
                        <FormControl fullWidth>
                            <InputLabel id="sort-label">Sort By</InputLabel>
                            <Select
                                labelId="sort-label"
                                id="sort"
                                value={sortField}
                                onChange={e => setSortField(e.target.value)}
                                label="Sort By"
                                sx={{py: 1}}
                            >
                                {sortOptions.map((option, i) => (
                                    <MenuItem key={"option-" + i} value={option}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MDBox>
                </MDBox>
                <Grid container>
                    <Grid item xs={12}>
                        <MDBox display="flex" alignItems="center" justifyContent="start" flexWrap="wrap">
                            <FormControlLabel
                                sx={{whiteSpace: 'nowrap', px: 1}}
                                control={
                                    <Checkbox
                                        checked={!!(users.length && checkedUsers.length === users.length)}
                                        onChange={(event, checked) => checked
                                            ? setCheckedUsers(users.map(user => user._id))
                                            : setCheckedUsers([])
                                        }
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                }
                                label=""/>
                            {checkedUsers.length
                                ? <MDButton color="error" size="small" iconOnly
                                            onClick={() => {
                                                handleDeleteUsers(checkedUsers);
                                                setCheckedUsers([]);
                                            }}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </MDButton>
                                : ''
                            }

                            {toggles && toggles.length ? toggles.map((option, i) => (
                                <FormControlLabel sx={{whiteSpace: 'nowrap', px: 2}}
                                                  key={'toggle-' + i}
                                                  control={<Switch size="small"
                                                                   value={option.value}
                                                                   onChange={() => setToggles(prev => prev
                                                                       .map(toggle => toggle.label === option.label
                                                                           ? {...option, value: !option.value}
                                                                           : toggle))}
                                                  />}
                                                  label={option.label}/>
                            )) : ''
                            }
                        </MDBox>

                    </Grid>
                </Grid>
            </MDBox>
            <Table sx={{my: 2}} aria-label="simple table">
                <TableBody>
                    {renderUsers}
                </TableBody>
            </Table>
            {paginationContainer}
        </Card>
    )
}

export default ProfilesList;
