import {createContext, useState} from "react";
import {css} from "@emotion/react";
import {RiseLoader, ScaleLoader} from "react-spinners";
import Grid from "@mui/material/Grid";
import {spinnerColor, spinnerCss} from "../../utils/constants";

export const SpinnerContext = createContext(undefined);

const SpinnerProvider = ({children}) => {
    const [showSpinner, setShowSpinner] = useState(false);

    return (
        <SpinnerContext.Provider value={[showSpinner, setShowSpinner]}>
            {showSpinner
                ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            minHeight: '100vh',
                            position: 'fixed', left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: "#000000",
                            opacity: .6,
                            zIndex: 1000
                        }}
                    >
                        <ScaleLoader
                            color={spinnerColor} loading={true} css={spinnerCss} size={15} margin={2}/>
                    </Grid>
                )
                : null
            }
            {children}
        </SpinnerContext.Provider>
    );
}

export default SpinnerProvider;