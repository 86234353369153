export const COMMON_DATA = '/api/common-data';
export const UPLOAD_DATA = '/api/auth/upload-data';
export const CHECK_DUPLICATE_EMAILS = '/api/auth/check-duplicate-emails';
export const AUTH = '/api/auth';
export const ACTIVATE_ACCOUNT = '/api/auth/activate';
export const CONFIRM_EMAIL = '/api/auth/confirm-email';
export const ADMIN_LOGIN = '/api/auth/admin-login';

export const PRODUCT = '/api/product';

export const STRIPE_KEY = '/api/stripe/key';
export const STRIPE_CHARGE = '/api/stripe/charge';

export const PAYMENT = '/api/payment';
export const PAYMENT_SEARCH = '/api/payment/search';
export const PAYMENT_STATUS = '/api/payment/status';
export const MEMBERSHIP = '/api/membership';
export const CHANGE_PASSWORD = '/api/auth/change-password';
export const MESSAGE = '/api/message';
export const MESSAGE_ADMIN = '/api/message/admin';

export const MESSAGE_ALL = '/api/message/all';

export const PROFILE = '/api/profile';
export const PROFILE_ME = '/api/profile/me';
export const PROFILE_LIKE = '/api/profile/like';
export const PROFILE_FAVORITE = '/api/profile/favorite';
export const PROFILE_BLOCK = '/api/profile/block';
export const PROFILE_FINDATA = '/api/profile/findata';
export const PROFILE_DATA = '/api/profile/data';

export const GREETING = '/api/greeting';
export const ICE_BREAKER = '/api/ice-breaker';
export const GIFT = '/api/gift';
export const ACTION = '/api/action';

export const MATCH = '/api/match';
export const STAT_USERS = '/api/stat/users';
export const STAT_PROFILES = '/api/stat/profiles';
export const STAT_ACTIVITY = '/api/stat/activity';
export const STAT_SALES = '/api/stat/sales';
export const STAT_ACCOUNTS = '/api/stat/accounts';
export const STAT_INFO = '/api/stat/info';

export const ADVANTAGE = '/api/advantage';
export const STORY = '/api/story';
export const MEMBER = '/api/member';
export const CONCEPT = '/api/concept';
export const ARTICLE = '/api/article';
export const ARTICLE_SEARCH = '/api/article/search';
export const EMAIL_DESIGN = '/api/email/design';
export const EMAIL_TEMPLATE = '/api/email/template';
export const EMAIL_NEWSLETTER = '/api/email/newsletter';
export const EMAIL_NEWSLETTER_SEND = '/api/email/newsletter/send';


