import React, {useContext, useEffect} from 'react';
import * as ReactDOM from "react-dom";
import './index.scss';
import theme from "./assets/theme";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {MaterialUIControllerProvider} from "context";
import {AuthStateProvider} from "./contexts/auth/AuthState";
import {TokenInterceptor} from "./interseptors/TokenInterceptor";
import SpinnerProvider from "./contexts/spinner/Spinner";
import {AuthContext} from "./contexts/auth/AuthContext";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";

// Token interceptor runs with every request
TokenInterceptor();

const App = () => {
    const {autoLogin, isAuthenticated} = useContext(AuthContext);
    useEffect(() => autoLogin(), []);
    return (
        <Routes>
            <Route path="/*" element={isAuthenticated ? <MainLayout/> : <AuthLayout/>}/>
        </Routes>
    );
};

const Index = () => {
    return (
        <AuthStateProvider>
            <SpinnerProvider>
                <React.StrictMode>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Router>
                            <MaterialUIControllerProvider>
                                <App/>
                            </MaterialUIControllerProvider>
                        </Router>
                    </ThemeProvider>
                </React.StrictMode>
            </SpinnerProvider>
        </AuthStateProvider>
    )
}

ReactDOM.render(<Index/>, document.getElementById('root'));
