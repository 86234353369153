import React from 'react';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SubMenu} from "react-pro-sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft,
    faArrowCircleRight, faCog, faDownload,
    faEnvelope,
    faExternalLinkAlt,
    faInfo, faShoppingBasket,
    faSlidersH,
    faSmile,
    faStore,
    faTachometerAlt,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import MDButton from "../components/MDButton";
import MDTypography from "../components/MDTypography";
import {Link, useLocation} from "react-router-dom";
import MDBox from "../components/MDBox";

const AsideBar = ({image, collapsed, toggled, handleToggle, handleCollapse}) => {
    const {pathname} = useLocation();

    return (
        <ProSidebar
            image={image ?? ''}
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="sm"
            onToggle={handleToggle}
        >
            <SidebarContent>
                <Menu iconShape="circle">
                    <MenuItem
                        icon={<MDButton iconOnly variant="text" onClick={() => handleCollapse(!collapsed)}>
                            {collapsed
                                ? <FontAwesomeIcon icon={faArrowCircleRight}/>
                                : <FontAwesomeIcon icon={faArrowCircleLeft}/>
                            }
                        </MDButton>}
                    >
                        <MDTypography color="white"> Admin console</MDTypography>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faTachometerAlt}/>}
                              className={pathname === '/' ? 'sidebar-item-active' : ''}
                    >
                        Dashboard<Link to="/"/>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faSlidersH}/>}
                              className={pathname === '/profile-options' ? 'sidebar-item-active' : ''}
                    >
                        Profile Options<Link to="/profile-options"/>
                    </MenuItem>

                    <SubMenu
                        icon={<FontAwesomeIcon icon={faStore}/>}
                        title="Product Options"
                        className={pathname.includes('/product-options') ? 'sidebar-menu-active' : ''}
                    >
                        <MenuItem className={pathname === '/product-options' ? 'sidebar-item-active' : ''}>
                            Products<Link to="/product-options"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/product-options/memberships' ? 'sidebar-item-active' : ''}>
                            Memberships<Link to="/product-options/memberships"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/product-options/gifts' ? 'sidebar-item-active' : ''}>
                            Gifts<Link to="/product-options/gifts"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/product-options/actions' ? 'sidebar-item-active' : ''}>
                            Actions<Link to="/product-options/actions"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/product-options/ice-breakers' ? 'sidebar-item-active' : ''}>
                            Ice Breakers<Link to="/product-options/ice-breakers"/></MenuItem>
                        <MenuItem className={pathname === '/product-options/greetings' ? 'sidebar-item-active' : ''}>
                            Greetings<Link to="/product-options/greetings"/>
                        </MenuItem>
                    </SubMenu>

                    <SubMenu
                        icon={<FontAwesomeIcon icon={faInfo}/>}
                        title="Info Options"
                        className={pathname.includes('/front-options') ? 'sidebar-menu-active' : ''}
                    >
                        <MenuItem className={pathname === '/front-options' ? 'sidebar-item-active' : ''}>
                            Front Info<Link to="/front-options"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/front-options/advantages' ? 'sidebar-item-active' : ''}>
                            Advantages<Link to="/front-options/advantages"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/front-options/testimonials' ? 'sidebar-item-active' : ''}>
                            Happy Stories<Link to="/front-options/stories"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/front-options/concepts' ? 'sidebar-item-active' : ''}>
                            Concepts<Link to="/front-options/concepts"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/front-options/members' ? 'sidebar-item-active' : ''}>
                            Members<Link to="/front-options/members"/>
                        </MenuItem>
                        <MenuItem className={pathname === '/front-options/articles' ? 'sidebar-item-active' : ''}>
                            Articles<Link to="/front-options/articles"/>
                        </MenuItem>
                    </SubMenu>

                    <MenuItem icon={<FontAwesomeIcon icon={faUsers}/>}
                              className={pathname === '/users' ? 'sidebar-item-active' : ''}
                    >
                        Users<Link to="/users"/>
                    </MenuItem>
                    <MenuItem icon={<FontAwesomeIcon icon={faShoppingBasket}/>}
                              className={pathname === '/payments    ' ? 'sidebar-item-active' : ''}
                    >
                        Payments<Link to="/payments"/>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faEnvelope}/>}
                              className={pathname === '/newsletters' ? 'sidebar-item-active' : ''}
                    >
                        Newsletters<Link to="/newsletters"/>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faDownload}/>}
                              className={pathname === '/import-data' ? 'sidebar-item-active' : ''}
                    >
                        Import Data<Link to="/import-data"/>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faCog}/>}
                              className={pathname === '/settings' ? 'sidebar-item-active' : ''}
                    >
                        Settings<Link to="/settings"/>
                    </MenuItem>
                </Menu>
            </SidebarContent>

            <SidebarFooter style={{textAlign: 'center'}}>
                <MDBox className="sidebar-btn-wrapper" sx={{p: '20px 24px'}}>
                    <MDBox component="a"
                           href="https://idealcouple.com"
                           target="_blank"
                           className="sidebar-btn"
                           rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                        <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            View Website
                        </span>
                    </MDBox>
                </MDBox>
            </SidebarFooter>
        </ProSidebar>
    )
}

export default AsideBar;