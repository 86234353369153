import React from 'react';
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {separateCamelCases} from "../../utils/functions";

const ImgTable = ({data, title, icon, onClick}) => {
    return (
        <>
            {icon ?
                <MDBox display="flex" px={2} py={1}>
                    <MDBox
                        color="white"
                        bgColor="info"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                        mt={-3}
                        mr={2}
                        width={60}
                        height={60}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {icon}
                    </MDBox>
                    <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                        {title ? separateCamelCases(title) : ''}
                    </MDTypography>
                </MDBox>
            :
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    {title ? separateCamelCases(title) : ''}
                </MDTypography>
            }

            <MDBox display="flex" bgColor="light" sx={{py: 1, px: 3}}>
                <MDButton color="success" circular iconOnly sx={{ml: 'auto'}}
                          onClick={() => onClick(true, title, null)}><FontAwesomeIcon
                    icon={faPlus}/></MDButton>
            </MDBox>
            <MDBox display="flex" flexWrap="wrap" justifyContent="center">
                {data && data.map((item, i) => (
                    <MDBox key={item._id ?? i} display="flex" flexDirection="column" alignItems="center" sx={{m: 1}}>
                        <MDBox component="img"
                               src={process.env.REACT_APP_API_URL + '/' + item.img}
                               sx={{width: '140px', height: 'auto'}}
                        />
                        <MDTypography variant="body2" sx={{mt: 'auto', py: 1}}>{item.name ?? ''}</MDTypography>
                        <MDButton fullWidth size="small" variant="outlined" color="success" sx={{my: 1}}
                                  onClick={() => onClick(true, title, item._id)}
                        >edit</MDButton>
                        <MDButton fullWidth size="small" variant="outlined" color="error" sx={{my: 1}}
                                  onClick={() => onClick(false, title, item._id)}
                        >delete</MDButton>
                    </MDBox>
                ))}
            </MDBox>
        </>
    );
};

export default ImgTable;