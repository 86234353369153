import React, {useEffect, useState} from 'react';
import MDBox from "../components/MDBox";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch} from "@mui/material";
import MDInput from "../components/MDInput";
import MDButton from "../components/MDButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import MDBadge from "../components/MDBadge";
import MDTypography from "../components/MDTypography";
import MDDatePicker from "../components/MDDatePicker";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";


const useFilters = ({sortOptions, searchOptions, toggleOptions, selectOptions, dateRange}) => {
    const [sort, setSort] = useState(sortOptions && sortOptions.length > 0 ? sortOptions[0].value : {});
    const [search, setSearch] = useState(searchOptions && searchOptions.length > 0 ? searchOptions[0] : {});
    const [filters, setFilters] = useState({});

    const handleSearch = () => {
        if (search && search.column) {
            if (filters[search.column] && !search.value) {
                const newFilters = Object.keys(key => key !== search.column);
                setFilters(newFilters);
            } else
                setFilters({...filters, [search.column]: search.value});
        }
    }

    const handleToggleChange = toggle => {
        if (!toggle || !toggle.column) return;
        if (filters[toggle.column]) setFilters(prevState => {
            let newState = {};
            Object.keys(prevState).map(itemKey => {
                if (itemKey !== toggle.column) newState[itemKey] = prevState[itemKey];
            })
            return newState;
        });
        else setFilters(prevState => ({...prevState, [toggle.column]: true}));
    }

    const handleChangeDate = (key, [date]) => {
        if (date) setFilters(prevState => ({...prevState, [key]: date}));
        else setFilters(prevState => {
            let newState = {};
            Object.keys(prevState).map(itemKey => {
                if (itemKey !== key) newState[itemKey] = prevState[itemKey];
            })
            return newState;
        });
    }

    const handleSelectChange = (select, value) => {
        if (select && value) {
            setFilters(prevState => ({...prevState, [select.column]: value}))
        } else if (select) removeFilterItem(select.column);
    }

    // Render Search Bar
    const renderSearchBar = searchOptions => {
        if (!searchOptions || searchOptions.length === 0) return '';
        else return (
            <MDBox display="flex" my={1} flexGrow={1}>
                <MDBox width="120px">
                    <FormControl fullWidth>
                        <InputLabel id="field-label">Field</InputLabel>
                        <Select
                            labelId="field-label"
                            id="field"
                            value={searchOptions.find(option => option.column === search.column)}
                            onChange={e => setSearch(e.target.value)}
                            label="Field"
                            sx={{py: 1}}
                        >
                            {searchOptions.map((option, i) => (
                                <MenuItem key={option.label + i} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MDBox>
                <MDBox width="100%">
                    <MDInput
                        label="Search"
                        size="small"
                        value={search?.value ?? ''}
                        onChange={e => setSearch({...search, value: e.target.value})}
                        InputProps={{
                            endAdornment:
                                <>
                                    {search.value?.length > 0 &&
                                        <MDButton variant="text" color="dark" circular iconOnly
                                                  onClick={() => setSearch({...search, value: ''})}
                                        >
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </MDButton>
                                    }
                                    <MDButton variant="text" color="info" circular iconOnly
                                              onClick={handleSearch}
                                    >
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </MDButton>
                                </>
                        }}
                    />
                </MDBox>
            </MDBox>
        );
    }

    // Render Sort Bar
    const renderSortBar = sortOptions => {
        if (!sortOptions || sortOptions.length === 0) return '';
        else return (
            <MDBox width={'120px'} my={1}>
                <FormControl fullWidth>
                    <InputLabel id="sort-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-label"
                        id="sort"
                        value={sort}
                        onChange={e => setSort(e.target.value)}
                        label="Sort By"
                        sx={{py: 1}}
                    >
                        {sortOptions.map((option, i) => (
                            <MenuItem key={"option-" + i} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </MDBox>
        );

    }

    // Render Toggles Bar
    const renderTogglesBar = toggles => {
        if (!toggles || toggles.length === 0) return '';
        else return (
            <MDBox display="flex" alignItems="center" justifyContent="start" flexWrap="wrap">
                {
                    toggles.map((toggle, i) =>
                        <FormControlLabel key={toggle.label + i}
                                          sx={{whiteSpace: 'nowrap', px: 2}}
                                          control={
                                              <Switch
                                                  size="small"
                                                  checked={!!filters[toggle.column]}
                                                  onChange={() => handleToggleChange(toggle)}
                                              />
                                          }
                                          label={toggle.label ?? ''}
                        />
                    )
                }


            </MDBox>
        )
    }

    // Remove Filter Item
    const removeFilterItem = (key) => {
        const newFilters = {...filters};
        delete newFilters[key];
        setFilters(newFilters);
    }

    // Render Filter Tags
    const renderFilterTags = filters => {
        return (
            <MDBox>
                {Object.keys(filters).map(key => (
                    <MDBadge key={key} circular
                             badgeContent={
                                 <>
                                     <MDTypography variant="caption" color="white" sx={{mr: 2}}>{key}</MDTypography>
                                     <MDButton iconOnly size="small" circular color="dark"
                                               onClick={() => removeFilterItem(key)}>
                                         <FontAwesomeIcon icon={faTimes}/>
                                     </MDButton>
                                 </>

                             }
                             size="xs" container color="dark" variant="gradient"/>
                ))}
            </MDBox>
        );
    }

    // Render Date Range
    const renderDateRange = () => {
        if (!dateRange) return '';
        else return (
            <MDBox>
                <MDDatePicker
                    id="startDate"
                    name="startDate"
                    onChange={date => handleChangeDate('startDate', date)}
                    value={filters.startDate ?? null}
                    input={{
                        label: 'Start Date',
                        value: filters.startDate ? moment(new Date(filters.startDate)).format('YYYY-MM-DD') : ''
                    }}
                />
                <MDTypography variant="button" sx={{mx: 1}}>:</MDTypography>
                <MDDatePicker
                    id="endDate"
                    name="endDate"
                    onChange={date => handleChangeDate('endDate', date)}
                    value={filters.endDate ?? null}
                    input={{
                        label: 'End Date',
                        value: filters.endDate ? moment(new Date(filters.endDate)).format('YYYY-MM-DD') : ''
                    }}
                />

            </MDBox>
        );
    }

    // Render Selects Bar
    const renderSelectsBar = selects => {
        if (!selectOptions || selectOptions.length === 0) return '';
        else return (
            <MDBox my={2}>
                {selectOptions.map((select, i) => (
                    <MDBox key={select.label + i} width="120px">
                        <FormControl fullWidth>
                            <InputLabel id="field-label">{select.label ?? 'Label'}</InputLabel>
                            <Select
                                labelId={`${select.label}-label`}
                                id={select.label}
                                value={filters[select.column] ?? ''}
                                onChange={e => handleSelectChange(select, e.target.value,)}
                                label="Field"
                                sx={{py: 1}}
                            >
                                <MenuItem value={''} sx={{minHeight: '32px'}}>*** clear ***</MenuItem>
                                {select.value.map((option, i) => (
                                    <MenuItem key={option + i} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MDBox>
                ))}

            </MDBox>
        );
    }

    const filtersContainer = (
        <>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                {renderFilterTags(filters)}
                {renderSortBar(sortOptions)}
            </MDBox>
            {renderSearchBar(searchOptions)}
            {renderDateRange()}
            {renderTogglesBar(toggleOptions)}
            {renderSelectsBar(selectOptions)}

        </>
    );
    return {filtersContainer, filters, setFilters, sort};
};

export default useFilters;