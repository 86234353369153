import moment from "moment";

export const cutAmountOf = incomeData => {
    return incomeData.replace('amountOf', '');
}

export const generateTable = (array, title) => {
    const table = {rows: [], columns: [], title: withSpaces(title)}

    if (array && array.length > 0) {
        // generate rows
        Object.keys(array[0]).forEach(key => {
            if (key !== '_id' && key !== '__v') {
                table.columns.push({Header: key, accessor: key})
            }
        })
        // generate columns
        array.forEach(item => {
            let row = {};
            Object.keys(item).forEach(key => {
                if (key !== '__v') {
                    if (Array.isArray(item[key])) {
                        row[key] = item[key].map(content => {
                            content = Object.keys(content).map(key => (key !== '_id') ? content[key] : null);
                            content = JSON.stringify(content.join(' ').split(null));
                            content = cutAmountOf(content);
                            content = content.replace(/"|\]|\[/g, '');
                            return content;
                        });
                    } else {
                        if (key === 'paidAt' || key === 'createdAt') {
                            row[key] = [moment(item[key]).format('l')]
                        } else if (key === 'onHomePage') {
                            row[key] = item[key] ? 'yes' : 'no'
                        } else row[key] = item[key];
                    }
                }
            })
            table.rows.push(row);
        })
    }
    return table;
}

export const separateCamelCases = sting => sting ? sting.replace(/([a-z](?=[A-Z]))/g, '$1 ') : '';
export const withoutSpaces = string => string ? string.replace(/\s+/g, '') : '';
export const withSpaces = string => string ? (string.split(/(?=[A-Z])/)).join(' ') : '';
export const capitalizeFirstLetter = string => string ? string.charAt(0).toUpperCase() + string.slice(1) : '';