import React, {useEffect, useState} from 'react';
import MDBox from "../components/MDBox";
import {Card, Grid} from "@mui/material";
import {faBacteria, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Statistics from "../elements/Statistics/Statistics";
import ReportsLineChart from "../elements/ReportsLineChart";
import {faRProject} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import useServer from "../hooks/useServer";
import {STAT_ACCOUNTS, STAT_ACTIVITY, STAT_PROFILES, STAT_SALES, STAT_USERS} from "../utils/serverUrls";
import {toast, ToastContainer} from "react-toastify";
import {toastConfig} from "../utils/constants";

const periods = ['week', 'month', 'year', '10 years'];

const Dashboard = () => {
    const [statUsersResponse, statUsersError, , statUsersSendRequest, statUsersSetError] = useServer(STAT_USERS);
    const [statProfilesResponse, statProfilesError, , statProfilesSendRequest, statProfilesSetError] = useServer(STAT_PROFILES);
    const [statActivityResponse, statActivityError, , statActivitySendRequest, statActivitySetError] = useServer(STAT_ACTIVITY);
    const [statSalesResponse, statSalesError, , statSalesSendRequest, statSalesSetError] = useServer(STAT_SALES);
    const [statNewAccountsResponse, statNewAccountsError, , statNewAccountsSendRequest, statNewAccountsSetError] = useServer(STAT_ACCOUNTS);

    const [usersStat, setUsersStat] = useState();
    const [profilesStat, setProfilesStat] = useState();
    const [activityStat, setActivityStat] = useState();
    const [salesStat, setSalesStat] = useState({labels: [], datasets: null});
    const [newAccountsStat, setNewAccountsStat] = useState({labels: [], datasets: null});

    const [paymentsPeriod, setPaymentsPeriod] = useState();
    const [newAccountsPeriod, setNewAccountsPeriod] = useState();

    const generatePeriodData = period => {
        let startDate;
        const endDate = moment().format('YYYY-MM-DD 23:59:59').toString();
        let step;
        switch (period) {
            case '10 years':
                startDate = moment().subtract(10, 'years').startOf('year').format('YYYY-MM-DD 00:00:01').toString();
                step = 'years';
                break;
            case 'month':
                startDate = moment().startOf('month').format('YYYY-MM-DD 00:00:01').toString();
                step = 'days';
                break;
            case 'year':
                startDate = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD 00:00:01').toString();
                step = 'months';
                break;
            default:
                startDate = moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD 00:00:01').toString();
                step = 'days';
                break;
        }
        return {startDate, endDate, step};
    }

    const getPaymentsStat = () => {
        const data = generatePeriodData(periods[paymentsPeriod])
        statSalesSendRequest({method: 'post', data});
    }

    const getNewAccountsStat = () => {
        const data = generatePeriodData(periods[newAccountsPeriod])
        statNewAccountsSendRequest({method: 'post', data});
    }

    // On Load Page
    useEffect(() => {
        statUsersSendRequest({method: 'get'});
    }, [])

    // On users statistics response
    useEffect(() => {
        if (statUsersResponse) {
            setUsersStat(statUsersResponse.data);
            statProfilesSendRequest({method: 'get'});
        }
    }, [statUsersResponse])

    // On profiles statistics response
    useEffect(() => {
        if (statProfilesResponse) {
            setProfilesStat(statProfilesResponse.data);
            statActivitySendRequest({method: 'get'});
        }
    }, [statProfilesResponse])

    // On activity statistics response
    useEffect(() => {
        if (statActivityResponse) {
            setActivityStat(statActivityResponse.data);
            getPaymentsStat();
        }
    }, [statActivityResponse])

    // On sales statistics response
    useEffect(() => {
        if (statSalesResponse) {
            setSalesStat(statSalesResponse.data);
            getNewAccountsStat();
        }
    }, [statSalesResponse])

    // On new accounts statistics response
    useEffect(() => {
        if (statNewAccountsResponse) {
            setNewAccountsStat(statNewAccountsResponse.data);
        }
    }, [statNewAccountsResponse])

    // On Error
    useEffect(() => {
        if (statUsersError) {
            toast.error(statUsersError.data?.message ?? 'Something went wrong', toastConfig);
            statUsersSetError(null);
            return () => statUsersSetError(false);
        }
        if (statProfilesError) {
            toast.error(statProfilesError.data?.message ?? 'Something went wrong', toastConfig);
            statProfilesSetError(null);
            return () => statProfilesSetError(false);
        }
        if (statActivityError) {
            toast.error(statActivityError.data?.message ?? 'Something went wrong', toastConfig);
            statActivitySetError(null);
            return () => statActivitySetError(false);
        }
        if (statSalesError) {
            toast.error(statSalesError.data?.message ?? 'Something went wrong', toastConfig);
            statSalesSetError(null);
            return () => statSalesSetError(false);
        }
        if (statNewAccountsError) {
            toast.error(statNewAccountsError.data?.message ?? 'Something went wrong', toastConfig);
            statNewAccountsSetError(null);
            return () => statNewAccountsSetError(false);
        }

    }, [statUsersError, statProfilesError, statActivityError, statSalesError, statNewAccountsError])

    // Get Sales Statistics on period toggle change
    useEffect(() => {
        if (paymentsPeriod || paymentsPeriod === 0) getPaymentsStat();
    }, [paymentsPeriod])

    // Get New Accounts Statistics on period toggle change
    useEffect(() => {
        if (newAccountsPeriod || newAccountsPeriod === 0) getNewAccountsStat();
    }, [newAccountsPeriod])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{p: 1}}>
                        <Statistics
                            title="Users"
                            icon={<FontAwesomeIcon icon={faUser}/>}
                            color="info"
                            table={usersStat}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{p: 1}}>
                        <Statistics
                            title="Profiles"
                            icon={<FontAwesomeIcon icon={faRProject}/>}
                            color="warning"
                            table={profilesStat}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{p: 1}}>
                        <Statistics
                            title="Activity"
                            icon={<FontAwesomeIcon icon={faBacteria}/>}
                            color="dark"
                            table={activityStat}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox sx={{py: 2}}>
                        <ReportsLineChart
                            color="info"
                            title="Sales"
                            description="Sales by month during the year"
                            date={moment(Date.now()).format('MM/DD/YYYY hh:mm a')}
                            chart={salesStat}
                            activeTab={paymentsPeriod}
                            handleSetTab={(e, v) => setPaymentsPeriod(v)}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox sx={{py: 2}}>
                        <ReportsLineChart
                            color="warning"
                            title="New accounts"
                            description="Registered accounts by month during the year"
                            date={moment(Date.now()).format('MM/DD/YYYY hh:mm a')}
                            chart={newAccountsStat}
                            activeTab={newAccountsPeriod}
                            handleSetTab={(e, v) => setNewAccountsPeriod(v)}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default Dashboard;