import React, {useState} from 'react';
import {Menu, MenuItem, ProSidebar, SubMenu} from "react-pro-sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSoap} from "@fortawesome/free-solid-svg-icons";
import AsideBar from "../parts/AsideBar";
import MainContent from "../parts/MainContent";
import bgImage from "assets/images/backgrounds/bg-sidebar.jpeg";
import MDBox from "../components/MDBox";

const MainLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);

    const handleCollapse = () => setCollapsed(!collapsed);
    const handleToggle = value => setToggled(value);

    return (
        <MDBox className="app">
            <AsideBar
                image={bgImage}
                collapsed={collapsed}
                toggled={toggled}
                handleCollapse={handleCollapse}
                handleToggle={handleToggle}
            />
            <MainContent
                collapsed={collapsed}
                toggled={toggled}
                handleCollapse={handleCollapse}
                handleToggle={handleToggle}
            />
        </MDBox>
    );
};

export default MainLayout;