import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import useServer from "../hooks/useServer";
import {MESSAGE_ADMIN, PAYMENT, PROFILE, PROFILE_DATA, PROFILE_FINDATA} from "../utils/serverUrls";
import {toast, ToastContainer} from "react-toastify";
import MDBox from "../components/MDBox";
import {Card, Grid, TableCell} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import MDTypography from "../components/MDTypography";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCommentDots, faMinusCircle, faUser} from "@fortawesome/free-solid-svg-icons";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCards} from "swiper";
import "swiper/css";
import {
    initialPayment,
    initialPaymentFilters,
    initialPaymentSort,
    spinnerColor,
    spinnerCss,
    toastConfig
} from "../utils/constants";
import PaymentsTable from "../elements/PaymentsTable";
import MDButton from "../components/MDButton";
import usePagination from "../hooks/usePagination";
import useModal from "../hooks/useModal";
import Swal from "sweetalert2";
import FinDataTable from "../elements/FinDataTable";
import {PuffLoader} from "react-spinners";
import ProfileDataTable from "../elements/ProfileDataTable";
import MDBadge from "../components/MDBadge";
import DataTable from "../elements/DataTable";

const UserInfo = () => {
    const {pathname} = useLocation();
    // const swiperRef = useRef(null);
    const profileId = pathname.split('/')[pathname.split('/').length - 1];
    const [profileResponse, profileError, profileIsLoading, profileSendRequest, profileSetError] = useServer(PROFILE + '/full/' + profileId ?? '');
    const [paymentsResponse, paymentsError, paymentsIsLoading, paymentsSendRequest, paymentsSetError] = useServer(PAYMENT + '/' + profileId ?? '');
    const [messagesResponse, messagesError, messagesIsLoading, messagesSendRequest, messagesSetError] = useServer(MESSAGE_ADMIN + '/' + profileId ?? '');
    const [paymentResponse, paymentError, paymentIsLoading, paymentSendRequest, paymentSetError] = useServer(PAYMENT);
    const [finDataResponse, finDataError, finDataIsLoading, finDataSendRequest, finDataSetError] = useServer(PROFILE_FINDATA);
    const [profileDataResponse, profileDataError, profileDataIsLoading, profileDataSendRequest, profileDataSetError] = useServer(PROFILE_DATA);
    const [profile, setProfile] = useState(null);
    const [payments, setPayments] = useState([]);
    const [messages, setMessages] = useState([]);
    const [sortPayments] = useState(initialPaymentSort);
    const [filterPayments] = useState(initialPaymentFilters);
    const messagesPagination = usePagination(10);
    const paymentsPagination = usePagination(10);
    const paymentsModal = useModal();
    const financialDataModal = useModal();
    const profileModal = useModal();

    const handleChangePayment = (actionType, id, column) => {
        switch (actionType) {
            case 'filter':
                break;
            case 'add':
                const newPayment = {...initialPayment, user: profile.user};
                paymentsModal.setFormData({title: 'payment', item: newPayment})
                break;
            case 'edit':
                paymentsModal.setFormData({title: 'payment', item: payments.find(payment => payment._id === id)})
                break;
            case 'delete':
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    confirmButtonText: 'delete',
                    confirmButtonColor: 'red',
                    showDenyButton: true,
                    denyButtonText: 'cancel',
                    denyButtonColor: 'green',
                    width: '320px'
                }).then(result => {
                    if (result.isConfirmed) {
                        const option = {method: 'delete', data: {id}};
                        paymentSendRequest(option);
                    } else if (result.isDenied) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Item is not deleted',
                            showConfirmButton: false,
                            timer: 1500,
                            width: '320px'
                        })
                    }
                })
                break;
            default:
                break;
        }
    }

    const getPayments = () => {
        if (pathname) {
            paymentsSendRequest({
                method: 'get',
                params: Object.assign(filterPayments, paymentsPagination.paginate, sortPayments)
            });
        }
    }

    const handleChangeFinancialData = () => {
        if (!profile) return;
        financialDataModal.setFormData({
            title: 'FinData', item: {
                _id: profile._id,
                isPremium: profile.isPremium,
                premiumEnds: profile.premiumEnds,
                letters: profile.amountOfLetters ?? 0,
                gifts: profile.amountOfGifts ?? 0,
                actions: profile.amountOfActions ?? 0,
                greetings: profile.amountOfGreetings ?? 'unlimited',
                'ice breakers': profile.amountOfIceBreakers ?? 'unlimited',
                'lets match': profile.amountOfLetsMatch ?? 'unlimited'
            }
        })
    }

    const handleChangeProfileData = () => {
        if (!profile) return;
        profileModal.setFormData({
                title: 'profile', item: {
                    _id: profile._id,
                    maritalStatus: profile.maritalStatus,
                    goal: profile.goal,
                    seeking: profile.seeking,
                    children: profile.childrenAmount,
                    religion: profile.religion,
                    education: profile.education,
                    occupation: profile.occupation,
                    weight: profile.weight,
                    height: profile.height,
                    eyeColor: profile.eyeColor,
                    hairColor: profile.hairColor,
                    smoking: profile.smokingHabit,
                    drinking: profile.drinkingHabit,
                    languages: profile.languages,
                    hobbies: profile.hobbies
                }
            }
        )

    }

    // On Load Page
    useEffect(() => {
        if (pathname) {
            profileSendRequest({method: 'get'});
            messagesSendRequest({method: 'get'})
        }
    }, [])

    // On Profile Response
    useEffect(() => {
        if (profileResponse) setProfile(profileResponse.data);
    }, [profileResponse])

    // On Payments Response
    useEffect(() => {
        if (paymentsResponse) {
            setPayments(paymentsResponse.data.payments);
            paymentsPagination.setPagination(paymentsResponse.data.pagination);
        }
    }, [paymentsResponse])

    useEffect(() => {
        if (messagesResponse) {
            setMessages(messagesResponse.data.messages);
            messagesPagination.setPagination(messagesResponse.data.pagination);
        }
    }, [messagesResponse])

    useEffect(() => {
        if (paymentsPagination.paginate) {
            getPayments();
        }
    }, [paymentsPagination.paginate])

    // On Payment Modal Change
    useEffect(() => {
        if (paymentsModal.sendRequestOptions) paymentSendRequest(paymentsModal.sendRequestOptions);
    }, [paymentsModal.sendRequestOptions])

    // On Payment Change Response
    useEffect(() => {
        if (paymentResponse) {
            if (typeof paymentResponse.data === 'object') {
                if (paymentsModal.formData?.item?._id) {
                    setPayments(prevState => (prevState.map(payment => payment._id === paymentResponse.data._id ?
                        paymentResponse.data : payment
                    )));
                } else {
                    setPayments(prevState => ([paymentResponse.data, ...prevState]));
                    paymentsPagination.setPagination(prevState => (
                        {
                            ...prevState,
                            total: prevState.total + 1,
                            pages: Math.ceil((prevState.total + 1) / prevState.onPage)
                        }
                    ));
                }
            } else if (typeof paymentResponse.data === 'string') {
                setPayments(prevState => (prevState.filter(payment => payment._id !== paymentResponse.data)));
                paymentsPagination.setPagination(prevState => (
                    {
                        ...prevState,
                        total: prevState.total - 1,
                        pages: Math.ceil((prevState.total - 1) / prevState.onPage)
                    }
                ));
            }
        }
    }, [paymentResponse])

    // On FinData Modal Change
    useEffect(() => {
        if (financialDataModal.sendRequestOptions) {
            finDataSendRequest(financialDataModal.sendRequestOptions);
        }
    }, [financialDataModal.sendRequestOptions])

    // On FinData Response
    useEffect(() => {
        if (finDataResponse) {
            setProfile(finDataResponse.data);
        }
    }, [finDataResponse])

    // On Profile Modal Change
    useEffect(() => {
        if (profileModal.sendRequestOptions) {
            profileDataSendRequest(profileModal.sendRequestOptions);
        }
    }, [profileModal.sendRequestOptions])

    // On Profile Data Response
    useEffect(() => {
        if (profileDataResponse) {
            setProfile(profileDataResponse.data);
        }
    }, [profileDataResponse])

    // On Error
    useEffect(() => {
        if (profileError) {
            toast.error(profileError.data?.message ?? 'Something went wrong', toastConfig);
            profileSetError(null);
        }
        if (paymentsError) {
            toast.error(paymentsError.data?.message ?? 'Something went wrong', toastConfig);
            paymentsSetError(null);
        }
        if (messagesError) {
            toast.error(messagesError.data?.message ?? 'Something went wrong', toastConfig);
            messagesSetError(null);
        }
        if (paymentError) {
            toast.error(paymentError.data?.message ?? 'Something went wrong', toastConfig);
            paymentSetError(null);
        }
        if (finDataError) {
            toast.error(finDataError.data?.message ?? 'Something went wrong', toastConfig);
            finDataSetError(null);
        }
        if (profileDataError) {
            toast.error(profileDataError.data?.message ?? 'Something went wrong', toastConfig);
            profileDataSetError(null);
        }
        return () => {
            profileSetError(null);
            paymentsSetError(null);
            messagesSetError(null);
            paymentSetError(null);
            finDataSetError(null);
            profileDataSetError(null);
        }
    }, [profileError, paymentsError, messagesError, paymentError, finDataError, profileDataError])


    if (!profile) return null;
    else return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <Card sx={{p: 1}}>
                        <MDBox component="img" sx={{borderRadius: 2}} width="100%"
                               src={process.env.REACT_APP_API_URL + '/' + profile.avatar}/>
                        {profile.user?.isAdmin ?
                            <MDBadge badgeContent="admin" color="warning" container size="lg"
                                     sx={{position: 'absolute', right: 0, top: 0}}
                            /> : ''
                        }
                        <Table style={{marginTop: 10}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Name:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}} textTransform="capitalize">
                                            {profile.firstname ?? ''} {profile.lastname ?? '' + ', '}
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Gender:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}}>
                                            {profile.gender ? profile.gender.name : ''}
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Email:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}}>
                                            {profile.user?.email ?? ''}
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Birthday:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}}>
                                            {profile.birthday ? moment(profile.birthday).format('MMM D, YYYY') : ''}
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Address:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}}>
                                            {profile.city ?? ''} ({profile.country ? profile.country.name : ''})
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            Source:
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                        <MDTypography variant="body2" sx={{my: 1}}>
                                            {profile.source ? profile.source.name : ''}
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <MDBox display="flex" flexDirection="column">
                            <MDButton size="small" color="warning" variant="gradient" sx={{my: 1}}>send
                                message</MDButton>
                            <MDButton size="small" color="warning" variant="gradient" sx={{my: 1}}>block</MDButton>
                            <MDButton size="small" color="warning" variant="gradient" sx={{my: 1}}>delete</MDButton>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card sx={{p: 1, minHeight: '400px'}}>
                        {profileIsLoading || profileDataIsLoading ?
                            <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                            :
                            <ProfileDataTable profile={profile} change={handleChangeProfileData}/>
                        }
                    </Card>
                </Grid>
                <Grid container spacing={2} item xs={12} xl={4}>
                    <Grid item xs={12} md={6} xl={12}>
                        <Card sx={{p: 1}}>
                            <MDBox display="flex" px={2}>
                                <MDBox
                                    color="white"
                                    bgColor="info"
                                    variant="gradient"
                                    borderRadius="lg"
                                    shadow="lg"
                                    opacity={1}
                                    p={2}
                                    mt={-3}
                                    mr={2}
                                    width={60}
                                    height={60}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FontAwesomeIcon icon={faUser}/>
                                </MDBox>
                                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                                    User's Info
                                </MDTypography>
                            </MDBox>
                            <MDTypography variant="subtitle2" fontWeight="bold" textAlign="center">
                                {profile._id ?? ''}
                            </MDTypography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{py: 1}}>
                                            <MDTypography variant="subtitle2" fontWeight="bold"
                                                          textTransform="capitalize">
                                                Activated:
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell sx={{py: 1}} align="right">
                                            <MDTypography variant="body2" textTransform="capitalize">
                                                {profile.user?.activated ?
                                                    <FontAwesomeIcon icon={faCheckCircle} style={{color: 'orange'}}/> :
                                                    <FontAwesomeIcon icon={faMinusCircle}/>}
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{py: 1}}>
                                            <MDTypography variant="subtitle2" fontWeight="bold"
                                                          textTransform="capitalize">
                                                Registered:
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell sx={{py: 1}} align="right">
                                            <MDTypography variant="body2" component="div">
                                                {profile.user?.registeredAt ? moment(profile.user.registeredAt)
                                                    .format('MMM DD, YYYY') : ''}
                                            </MDTypography>
                                            <MDTypography variant="caption" component="div">
                                                ({moment(profile.user?.registeredAt).startOf('day').fromNow()})
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 1}}>
                                            <MDTypography variant="subtitle2" fontWeight="bold"
                                                          textTransform="capitalize">
                                                Last activity:
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell sx={{py: 1}} align="right">
                                            <MDTypography variant="body2" component="div">
                                                {profile.user?.userLastAction ? moment(profile.user.userLastAction.actionAt)
                                                    .format('MMM DD, YYYY') : ''}
                                            </MDTypography>

                                            <MDTypography variant="caption" component="div">
                                                ({moment(profile.user?.userLastAction?.actionAt).startOf('day').fromNow()})
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{py: 1}}>
                                            <MDTypography variant="subtitle2" fontWeight="bold"
                                                          textTransform="capitalize">
                                                Activation Code:
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell sx={{py: 1}} align="right">
                                            <MDTypography variant="body2" textTransform="capitalize">
                                                {profile.user?.activationCode ?? ''}
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
                                            <MDTypography variant="subtitle2" fontWeight="bold"
                                                          textTransform="capitalize">
                                                Activation Code Exp:
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell style={{paddingTop: 0, paddingBottom: 0}} align="right">
                                            <MDTypography variant="body2" sx={{my: 1}} textTransform="capitalize">
                                                {profile.user?.activationExpired ? moment(profile.user?.activationExpired)
                                                    .format('MMM DD, YYY') : ''}
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} xl={12}>
                        <Card sx={{p: 1, minHeight: '300px'}}>
                            {finDataIsLoading ?
                                <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                                :
                                <FinDataTable profile={profile} change={handleChangeFinancialData}/>
                            }
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={8}>
                    <Card sx={{p: 1}}>
                        {paymentsIsLoading || paymentIsLoading ?
                            <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                            :
                            <PaymentsTable payments={payments} change={handleChangePayment}/>
                        }
                        {paymentsPagination.paginationContainer}
                    </Card>
                </Grid>
                <Grid item xs={12} xl={4}>
                    <Card sx={{p: 1}}>
                        {messagesIsLoading ?
                            <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                            :
                            <MDBox display="flex" px={2}>
                                <MDBox
                                    color="white"
                                    bgColor="info"
                                    variant="gradient"
                                    borderRadius="lg"
                                    shadow="lg"
                                    opacity={1}
                                    p={2}
                                    mt={-3}
                                    mr={2}
                                    width={60}
                                    height={60}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FontAwesomeIcon icon={faCommentDots}/>
                                </MDBox>
                                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                                    Messages
                                </MDTypography>

                            {/*    TODO Messages Table */}

                            </MDBox>
                        }
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{p: 1}}>
                        <MDBox display="flex" justifyContent="space-between">
                            {profile.photos?.length ?
                                profile.photos.map((photo, i) => (
                                    <MDBox component="img" sx={{borderRadius: 2, m: 1}} maxWidth="100%" key={'photo-' + i}
                                           src={process.env.REACT_APP_API_URL + '/' + photo}/>
                                ))
                                : ''
                            }
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>

            {paymentsModal.modalContainer}
            {financialDataModal.modalContainer}
            {profileModal.modalContainer}
        </MDBox>
    );
};

export default UserInfo;