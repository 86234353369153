import {css} from "@emotion/react";

export const spinnerColor = '#e3d843';

export const modalStyle = {
    minWidth: '320px',
    maxWidth: '400px',
    margin: 'auto',
    p: 2
}

export const modalWideStyle = {
    minWidth: '320px',
    maxWidth: '100%',
    margin: 'auto',
    p: 2
}

export const spinnerCss = css`
  display: block;
  margin: auto;
  border-color: red;
`;

export const toastConfig = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
}

export const initialFilters = {
    ageRange: [],
    heightRange: [],
    weightRange: [],
    countries: [],
    city: '',
    firstname: '',
    lastname: '',
    email: '',
    genders: [],
    goals: [],
    maritalStatuses: [],
    children: [],
    educations: [],
    occupation: '',
    hairColors: [],
    eyeColors: [],
    smokingHabits: [],
    drinkingHabits: [],
    religions: [],
    hobbies: []
};

export const initialSort = {
    column: '',
    order: 'asc'
}

export const initialPaginate = {
    offset: 0,
    limit: 10
}

export const initialUserSort = {
    column: 'registeredAt',
    order: 'desc'
}
export const initialUserFilters = {
    isAdmin: null,
    activated: null,
    hasProfile: null,
    isFake: null
}

export const initialUserSearch = {
    email: '',
    firstname: '',
    lastname: '',
}

export const initialPaymentSort = {
    column: 'paidAt',
    order: 'desc'
}

export const initialPaymentFilters = {
    amountRange: null,
    status: null,
    dateRange: null
}

export const paymentStatuses = [
    'paid', 'pending'
];

export const initialPayment = {
    amount: 0,
    description: '',
    user: null,
    status: '',
    paidAt: new Date()
};

export const colors = [
    'primary', 'secondary', 'info', 'warning', 'success', 'error', 'light', 'dark'
];

export const statuses = {
    PAID: 'paid',
    PENDING: 'pending'
}

export const DEFAULT_PAGINATION_ARTICLES = 20;

export const templateTitles = {
    WELCOME: 'welcome',
    BIRTHDAY: 'birthday',
    GREETING: 'greeting',
    ICEBREAKER: 'iceBreaker',
    GIFT: 'gift',
    ACTION: 'action',
    LETS: 'lets',
    MATCH: 'match',
    RESET_PASSWORD: 'reset_password',
    FORGOT_PASSWORD: 'forgot_password',
    ACTIVATION: 'activation'
}