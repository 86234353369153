import React from 'react';
import Login from "../pages/Login";
import MDBox from "../components/MDBox";
import bgImage from "../assets/images/backgrounds/bg-auth.jpeg";

const AuthLayout = () => {
    return (
        <MDBox display="flex" alignItems="center" justifyContent="center" height="100%"
               style={{
                   backgroundImage: `url(${bgImage})`,
                   backgroundSize: 'cover',
                   backgroundPosition: 'top',
                   backgroundRepeat: 'no-repeat'
        }}>
            <Login/>
        </MDBox>
    );
};

export default AuthLayout;