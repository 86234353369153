import React, {useState} from 'react';
import {Menu, MenuItem, Table, TableBody, TableCell, TableRow} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import moment from "moment";
import MDBadge from "../../components/MDBadge";
import {faCoins, faEdit, faEllipsisV, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDAvatar from "../../components/MDAvatar";
import {statuses} from "../../utils/constants";

const PaymentsTable = ({hideTitle, payments, showUser, change}) => {
    const generateAnchorElList = payments => payments.reduce((a, v) => ({...a, [v._id]: null}), {});
    const [anchorElList, setAnchorElList] = useState(() => generateAnchorElList(payments));
    const handleOpen = (e, id) => setAnchorElList({...anchorElList, [id]: e.currentTarget});
    const handleClose = () => setAnchorElList(generateAnchorElList(payments));

    return (
        <>
            <MDBox display={hideTitle ? 'none' : 'flex'} px={2}>
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    mt={-3}
                    mr={2}
                    width={60}
                    height={60}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FontAwesomeIcon icon={faCoins}/>
                </MDBox>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    Payments
                </MDTypography>
            </MDBox>
            <Table>
                <TableBody>
                    <TableRow>
                        {showUser &&
                            <TableCell sx={{py: 1}}>User</TableCell>
                        }
                        <TableCell sx={{py: 1}}>Date</TableCell>
                        <TableCell sx={{py: 1}} align="center">Status</TableCell>
                        <TableCell sx={{py: 1}}>Amount</TableCell>
                        <TableCell sx={{py: 1}}>Description</TableCell>
                        <TableCell sx={{py: 1}} align="right">
                            <MDButton iconOnly size="small" color="success" onClick={() => change('add')}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </MDButton>
                        </TableCell>
                    </TableRow>
                    {payments && payments.length > 0 && payments.map(payment =>
                        <React.Fragment key={payment._id}>
                            <TableRow sx={{py: 1}}>
                                <TableCell colSpan={7} sx={{py: 0}}>
                                    <MDTypography variant="caption">
                                        ID: {payment._id}
                                    </MDTypography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {showUser &&
                                    <TableCell sx={{py: 1}} onClick={() => change('filter', payment.user?._id, 'user')}>
                                        <MDBox display="flex">
                                            <MDAvatar
                                                src={process.env.REACT_APP_API_URL + '/' + payment.user?.profile?.avatar}/>
                                            <MDBox display="flex" flexDirection="column" sx={{ml: 2}}>
                                                <MDTypography variant="button" fontWeight="bold">
                                                    {payment.user?.profile?.firstname} {payment.user?.profile?.lastname}
                                                </MDTypography>
                                                <MDTypography variant="caption">
                                                    {payment.user?.email}
                                                </MDTypography>
                                            </MDBox>
                                        </MDBox>
                                    </TableCell>
                                }
                                <TableCell sx={{py: 1}} onClick={() => change('filter', payment.paidAt, 'date')}>
                                    <MDTypography component="div" variant="caption">
                                        {moment(payment.paidAt).format('MM/DD/YY')}
                                    </MDTypography>
                                    <MDTypography component="div" variant="caption">
                                        {moment(payment.paidAt).format('h:mm a')}
                                    </MDTypography>
                                </TableCell>
                                <TableCell sx={{py: 1}} align="center" onClick={() => change('filter', payments.status, 'status')}>
                                    <MDBadge color={payment.status === statuses.PAID ? 'success' :
                                        (payment.status === statuses.PENDING ? 'light' : 'error')}
                                             circular size="xs"
                                             badgeContent={payment.status} container
                                    />
                                </TableCell>
                                <TableCell sx={{py: 1}} onClick={() => change('filter', payment.amount, 'amount')}>
                                    <MDTypography component="div" variant="caption">
                                        $ {payment.amount}
                                    </MDTypography>
                                </TableCell>
                                <TableCell sx={{py: 1}} onClick={() => change('filter', payment.description, 'description')}>
                                    <MDTypography component="div" variant="caption">
                                        {payment.description}
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="right">
                                    <MDButton
                                        iconOnly size="small" circular
                                        onClick={e => handleOpen(e, payment._id)}
                                    >
                                        <FontAwesomeIcon icon={faEllipsisV}/>
                                    </MDButton>
                                    <Menu
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        anchorEl={anchorElList[payment._id]}
                                        open={Boolean(anchorElList[payment._id])}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                            <MDButton variant="contained" color="white" size="small" iconOnly
                                                      onClick={() => change('edit', payment._id)}
                                            >
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </MDButton>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                            <MDButton variant="contained" color="white" size="small" iconOnly
                                                      onClick={() => change('delete', payment._id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </MDButton>
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default PaymentsTable;