import React, {useEffect, useState} from 'react';
import {Card, Grid, Stack} from "@mui/material";
import MDButton from "../components/MDButton";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDInput from "../components/MDInput";
import {faEdit, faEye, faKey, faPalette, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EMAIL_DESIGN, EMAIL_TEMPLATE, STRIPE_KEY} from "../utils/serverUrls";
import useServer from "../hooks/useServer";
import {toast, ToastContainer} from "react-toastify";
import {spinnerColor, spinnerCss, templateTitles, toastConfig} from "../utils/constants";
import {PuffLoader} from "react-spinners";
import 'react-quill/dist/quill.snow.css';
import HtmlEditor from "./HtmlEditor";
import Swal from "sweetalert2";

const conventions = title => {
    const result = ['${email}', '${firstname}', '${lastname}'];
    switch (title) {
        case templateTitles.ACTIVATION:
            result.splice(0,result.length);
            result.push('${email}');
            result.push('${activationCode}');
            break;
        case templateTitles.GIFT:
            result.push('${sender-name}');
            result.push('${sender-age}');
            result.push('${sender-avatar}');
            result.push('${gift}');
            break;
        case templateTitles.GREETING:
            result.push('${sender-name}');
            result.push('${sender-age}');
            result.push('${sender-avatar}');
            result.push('${greeting}');
            break;
        case templateTitles.ACTION:
            result.push('${sender-name}');
            result.push('${sender-age}');
            result.push('${sender-avatar}');
            result.push('${action}');
            break;
        case templateTitles.ICEBREAKER:
            result.push('${sender-name}');
            result.push('${sender-age}');
            result.push('${sender-avatar}');
            result.push('${iceBreaker}');
            break;
        case templateTitles.FORGOT_PASSWORD:
            result.splice(0,result.length);
            result.push('${email}');
            result.push('${link}');
            break;
        case templateTitles.RESET_PASSWORD:
        case templateTitles.WELCOME:
            result.splice(0,result.length);
            result.push('${email}');
            break;
        case 'match':
        default:
            break;
    }

    return result;
}


const emailTemplateTitles = [
    templateTitles.WELCOME,
    templateTitles.BIRTHDAY,
    templateTitles.GREETING,
    templateTitles.ICEBREAKER,
    templateTitles.GIFT,
    templateTitles.ACTION,
    templateTitles.LETS,
    templateTitles.MATCH,
    templateTitles.RESET_PASSWORD,
    templateTitles.FORGOT_PASSWORD,
    templateTitles.ACTIVATION
];

const Settings = () => {
    const [stripeSecretKey, setStripeSecretKey] = useState('');
    const [modifiedKey, setModifiedKey] = useState('');
    const [showStripeSecretKey, setShowStripeSecretKey] = useState(false);
    const [stripeResponse, stripeError, stripeIsLoading, stripeSendRequest, stripeSetError] = useServer(STRIPE_KEY);

    const [emailDesignResponse, emailDesignError, emailDesignIsLoading, emailDesignSendRequest, emailDesignSetError] = useServer(EMAIL_DESIGN);
    const [emailDesign, setEmailDesign] = useState(null);
    const [editEmailDesign, setEditEmailDesign] = useState(null);

    const [emailTemplatesResponse, emailTemplatesError, emailTemplatesIsLoading, emailTemplatesSendRequest, emailTemplatesSetError] = useServer(EMAIL_TEMPLATE);
    const [emailTemplates, setEmailTemplates] = useState(null);
    const [editEmailTemplate, setEditEmailTemplate] = useState(null);

    // Change Stripe Secret Key
    const handleChangeStripeSecretKey = () => {
        if (modifiedKey) stripeSendRequest({method: 'post', data: {key: modifiedKey}});
    }

    // Load Email Design On Clicking Edit Button
    const handleEditEmailDesign = () => {
        setEditEmailDesign(emailDesign ?? {design: null, html: null});
    }

    // On Save Email Design
    const handleSaveEmailDesign = data => {
        if (data) emailDesignSendRequest({method: 'post', data});
        setEditEmailDesign(null);
    }

    // On Delete Email Design
    const handleDeleteEmailDesign = () => {
        emailDesignSendRequest({method: 'delete'});
    }

    // On Select Email Template
    const handleSelectEmailTemplate = title => {
        const template = emailTemplates.find(template => template.title === title);
        setEditEmailTemplate(template ?? {title});
    }

    // On Delete Email Template
    const handleDeleteEmailTemplate = title => {
        Swal.fire({
            icon: 'question',
            text: 'Delete item?',
            confirmButtonText: 'delete',
            confirmButtonColor: 'red',
            showDenyButton: true,
            denyButtonText: 'cancel',
            denyButtonColor: 'green',
            width: '320px'
        }).then(result => {
            if (result.isConfirmed) {
                emailTemplatesSendRequest({method: 'delete', data: {title}})
            } else if (result.isDenied) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Item is not deleted',
                    showConfirmButton: false,
                    timer: 1500,
                    width: '320px'
                })
            }
        })
    }

    const handleSaveEmailTemplate = data => {
        if (data && editEmailTemplate) emailTemplatesSendRequest({
            method: 'post',
            data: {...data, title: editEmailTemplate.title}
        });
        setEditEmailTemplate(null);
    }

    // On Load Page
    useEffect(() => {
        stripeSendRequest({method: 'get'});
        emailDesignSendRequest({method: 'get'});
        emailTemplatesSendRequest({method: 'get'});
    }, [])

    // On Stripe Key Response
    useEffect(() => {
        if (stripeResponse) {
            setShowStripeSecretKey(false);
            setStripeSecretKey(stripeResponse.data);
            setModifiedKey('');
        }
    }, [stripeResponse])

    // On Email Design Response
    useEffect(() => {
        if (emailDesignResponse) {
            setEmailDesign(emailDesignResponse.data);
        }
    }, [emailDesignResponse])

    // On Email Templates Response
    useEffect(() => {
        if (emailTemplatesResponse) {
            if (Array.isArray(emailTemplatesResponse.data)) {
                setEmailTemplates(emailTemplatesResponse.data);
            } else if (typeof emailTemplatesResponse.data === 'object') {
                if (emailTemplates.find(template => template.title === emailTemplatesResponse.data?.title)) {
                    setEmailTemplates(prevState => (prevState.map(template => template.title === emailTemplatesResponse.data?.title ?
                        emailTemplatesResponse.data : template
                    )))
                } else {
                    setEmailTemplates(prevState => [...prevState, emailTemplatesResponse.data]);
                }

            } else if (typeof emailTemplatesResponse.data === 'string') {
                setEmailTemplates(prevState => (prevState.filter(template => template.title !== emailTemplatesResponse.data)))
                Swal.fire({
                    icon: 'success',
                    text: 'Item successfully deleted',
                    showConfirmButton: false,
                    timer: 1500,
                    width: '320px'
                })
            }

        }
    }, [emailTemplatesResponse])

    // On Error
    useEffect(() => {
        if (stripeError) {
            toast.error(stripeError.data?.message ?? 'Something went wrong', toastConfig);
            stripeSetError(null);
        }
        if (emailDesignError) {
            toast.error(emailDesignError.data?.message ?? 'Something went wrong', toastConfig);
            emailDesignSetError(null);
        }
        if (emailTemplatesError) {
            toast.error(emailTemplatesError.data?.message ?? 'Something went wrong', toastConfig);
            emailTemplatesSetError(null);
        }
        return () => {
            stripeSetError(null);
            emailDesignSetError(null);
            emailTemplatesSetError(null);
        }
    }, [stripeError, emailDesignError, emailTemplatesError])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{p: 2, minHeight: '200px'}}>
                        <MDTypography variant="h5">Stripe Secret Key</MDTypography>
                        {stripeIsLoading ?
                            <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                            :
                            <>
                                <MDBox display="flex" mt={3} alignItems="center">
                                    <FontAwesomeIcon icon={faKey}/>

                                    <MDTypography variant="caption" sx={{overflowWrap: 'anywhere', mx: 2}}>
                                        {showStripeSecretKey ? (stripeSecretKey ?? 'no key') : '********************************'}
                                    </MDTypography>

                                    <MDButton size="small" variant="outlined" color="dark" sx={{ml: 'auto'}}
                                              onClick={() => setShowStripeSecretKey(!showStripeSecretKey)}
                                    >
                                        <FontAwesomeIcon icon={faEye} style={{marginRight: '5px'}}/>
                                        {showStripeSecretKey ? 'Hide' : 'Show'}
                                    </MDButton>
                                </MDBox>
                                <MDBox display="flex" mt={3} alignItems="center">
                                    <MDInput
                                        fullWidth
                                        sx={{ml: 'auto', minWidth: '400px'}}
                                        size="small"
                                        value={modifiedKey}
                                        onChange={e => setModifiedKey(e.target.value)}
                                    />
                                    <MDButton
                                        size="small"
                                        sx={{ml: 2}}
                                        disabled={!modifiedKey || modifiedKey === stripeSecretKey}
                                        color="success"
                                        onClick={handleChangeStripeSecretKey}
                                    >
                                        Change
                                    </MDButton>
                                </MDBox>
                            </>
                        }
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{p: 2, minHeight: '200px'}}>
                        <MDTypography variant="h5">Mailing Settings</MDTypography>
                        {emailDesignIsLoading ?
                            <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                            :
                            editEmailDesign ?
                                <HtmlEditor template={editEmailDesign} changeTemplate={handleSaveEmailDesign}/> :
                                <>
                                    <MDBox display="flex" mt={3} alignItems="center">
                                        <FontAwesomeIcon icon={faPalette}/>
                                        <MDTypography variant="button" sx={{overflowWrap: 'anywhere', mx: 2}}>
                                            Design Template
                                        </MDTypography>

                                        <Stack direction="row" spacing={1} sx={{ml: 'auto'}}>
                                            {emailDesign &&
                                                <MDButton size="small" variant="outlined" color="dark"
                                                          onClick={handleDeleteEmailDesign}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} style={{marginRight: '5px'}}/>
                                                    Delete
                                                </MDButton>
                                            }
                                            <MDButton size="small" variant="outlined" color="dark"
                                                      onClick={handleEditEmailDesign}
                                            >
                                                <FontAwesomeIcon icon={faEdit} style={{marginRight: '5px'}}/>
                                                {emailDesign ? 'Edit' : "New"}
                                            </MDButton>
                                        </Stack>
                                    </MDBox>
                                </>
                        }
                        <MDTypography variant="body2" fontWeight="bold" sx={{mt: 3, mb: 1, textAlign: 'center'}}>
                            {editEmailTemplate ? editEmailTemplate.design ? `Edit "${editEmailTemplate.title}" Template:` : `Add "${editEmailTemplate.title}" Template:` : 'Email Templates:'}
                        </MDTypography>

                        {editEmailTemplate ?
                            <>
                                <MDBox>
                                    {conventions(editEmailTemplate.title).map(item => (
                                        <MDTypography key={item} variant="button" sx={{mx: 2}}>{item}</MDTypography>
                                    ))}
                                </MDBox>
                                <HtmlEditor
                                    template={editEmailTemplate.design ? editEmailTemplate : emailDesign}
                                    changeTemplate={handleSaveEmailTemplate}
                                />
                            </>
                            :
                            <MDBox display="flex" justifyContent="space-between" flexWrap="wrap">
                                {emailTemplateTitles.map(title => (
                                    <MDBox key={title} m={1}>
                                        <MDButton
                                            variant={editEmailTemplate?.title === title ? 'contained' : 'outlined'}
                                            color={emailTemplates && emailTemplates.find(template => template.title === title) ? 'success' : 'dark'}
                                            size="small"
                                            onClick={() => handleSelectEmailTemplate(title)}
                                        >
                                            {title}
                                        </MDButton>
                                        {
                                            emailTemplates && emailTemplates.find(template => template.title === title) &&
                                            <MDButton iconOnly size="small"
                                                      onClick={() => handleDeleteEmailTemplate(title)}><FontAwesomeIcon
                                                icon={faTimes}/></MDButton>
                                        }
                                    </MDBox>
                                ))}
                            </MDBox>
                        }
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default Settings;