import React, {useContext, useEffect, useState} from 'react';
import MDBox from "../components/MDBox";
import {Card} from "@mui/material";
import useServer from "../hooks/useServer";
import {EMAIL_NEWSLETTER, EMAIL_NEWSLETTER_SEND, EMAIL_DESIGN} from "../utils/serverUrls";
import {toast} from "react-toastify";
import {spinnerColor, spinnerCss, toastConfig} from "../utils/constants";
import {PuffLoader} from "react-spinners";
import NewslettersTable from "../elements/NewslettersTable";
import HtmlEditor from "./HtmlEditor";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import Swal from "sweetalert2";
import usePagination from "../hooks/usePagination";

const initialNewsletter = {
    title: '',
    type: 'newsletter',
    html: '',
    design: null,
    recipients: '',
    createdAt: new Date(),
    sentAt: null
};

const PAGINATION_LIMIT = 20;

const Newsletters = () => {
    // Context
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    // States
    const [newsletters, setNewsletters] = useState([]);
    const [editNewsletter, setEditNewsletter] = useState(null);
    const [emailTemplate, setEmailTemplate] = useState(null);
    // Hooks
    const [newslettersResponse, newslettersError, newslettersIsLoading, newslettersSendRequest, newslettersSetError] = useServer(EMAIL_NEWSLETTER);
    const [sendNewslettersResponse, sendNewslettersError, sendNewslettersIsLoading, sendNewslettersSendRequest, sendNewslettersSetError] = useServer(EMAIL_NEWSLETTER_SEND);
    const [emailDesignResponse, emailDesignError, emailDesignIsLoading, emailDesignSendRequest, emailDesignSetError] = useServer(EMAIL_DESIGN);
    const newslettersPagination = usePagination(PAGINATION_LIMIT);

    // On Newsletters Change
    const handleNewslettersChange = (actionType, id) => {
        const newsletter = newsletters.find(newsletter => newsletter._id === id);
        switch (actionType) {
            case 'send':
                if (newsletter) sendNewslettersSendRequest({method: 'post', data: newsletter});
                break;
            case 'add':
                setEditNewsletter(initialNewsletter);
                break;
            case 'edit':
                if (newsletter.design) setEmailTemplate(newsletter);
                setEditNewsletter(newsletter);
                break;
            case 'delete':
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    confirmButtonText: 'delete',
                    confirmButtonColor: 'red',
                    showDenyButton: true,
                    denyButtonText: 'cancel',
                    denyButtonColor: 'green',
                    width: '320px'
                }).then(result => {
                    if (result.isConfirmed) {
                        newslettersSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Item is not deleted',
                            showConfirmButton: false,
                            timer: 1500,
                            width: '320px'
                        })
                    }
                })
                break;
            default:
                break;
        }
    }

    // Change Email: Send request
    const handleChangeEmail = email => {
        newslettersSendRequest({method: email._id ? 'patch' : 'post', data: email});
        setEditNewsletter(null);
    }

    // On Load Page
    useEffect(() => {
        emailDesignSendRequest({method: 'get'});
    }, [])

    // Load Newsletters on Pagination Change
    useEffect(() => {
        newslettersSendRequest({
            method: 'get', params: Object.assign(
                {...newslettersPagination.paginate}
            )
        });
    }, [newslettersPagination.paginate])

    // On Newsletters Response
    useEffect(() => {
        if (newslettersResponse) {
            if (newslettersResponse.data?.newsletters) {
                setNewsletters(newslettersResponse.data.newsletters);
                newslettersPagination.setPagination(newslettersResponse.data?.pagination);
            } else if (typeof newslettersResponse.data === 'string') {
                const id = newslettersResponse.data;
                setNewsletters(prevState => (prevState.filter(newsletter => newsletter._id !== id)));
                newslettersPagination.setPagination(prevState => (
                    {
                        ...prevState,
                        total: prevState.total - 1,
                        pages: Math.ceil((prevState.total - 1) / prevState.onPage)
                    }
                ));
                Swal.fire({
                    icon: 'success',
                    text: 'Item successfully deleted',
                    showConfirmButton: false,
                    timer: 1500,
                    width: '320px'
                })

            } else newslettersPagination.setDefaultPaginate();
        }
    }, [newslettersResponse])

    // On Email Design Response
    useEffect(() => {
        if (emailDesignResponse) setEmailTemplate(emailDesignResponse.data);
    }, [emailDesignResponse])

    // On Newsletters Send
    useEffect(() => {
        if (sendNewslettersResponse) {
            const message = sendNewslettersResponse.data?.message;
            const total = message?.total ?? 0;
            const error = sendNewslettersResponse.data?.error;
            const sent = message?.sent?.length ?? 0;
            const rejected = message?.rejected?.length ?? 0;
            const rejectedHtml = message?.rejected && message.rejected.length > 0 ?
                message.rejected.map(email => '<p>' + email + '</p>').join("") : '';

            const returnedNewsletter = sendNewslettersResponse.data?.newsLetter;
            setNewsletters(prevState => prevState.map(newsletter => newsletter._id === returnedNewsletter._id ? returnedNewsletter : newsletter))
            Swal.fire({
                icon: error ? 'error' : 'success',
                title: `Newsletter ${error ? 'NOT' : ''} sent`,
                text: `Total: ${total}, sent: ${sent}, rejected: ${rejected}. ${error ?? ''}`,
                showConfirmButton: rejected !== 0,
                showCancelButton: true,
                confirmButtonText: 'show rejected',
                cancelButtonText: 'got it!',
                cancelButtonColor: 'lightgreen',
                confirmButtonColor: 'red'
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        html: rejectedHtml
                    })
                }
            })
        }
    }, [sendNewslettersResponse])

    // On Error
    useEffect(() => {
        if (newslettersError) {
            toast.error(newslettersError.data?.message ?? 'Something went wrong', toastConfig);
            newslettersSetError(null);
        }
        if (emailDesignError) {
            toast.error(emailDesignError.data?.message ?? 'Something went wrong', toastConfig);
            emailDesignSetError(null);
        }
        if (sendNewslettersError) {
            toast.error(sendNewslettersError.data?.message ?? 'Something went wrong', toastConfig);
            sendNewslettersSetError(null);
        }
        return () => {
            newslettersSetError(null);
            emailDesignSetError(null);
            sendNewslettersSetError(null);
        }
    }, [newslettersError, emailDesignError, sendNewslettersError])

    // On Email Design Loading
    useEffect(() => {
        if (emailDesignIsLoading || sendNewslettersIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [emailDesignIsLoading, sendNewslettersIsLoading])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <Card sx={{p: 1, minHeight: '400px'}}>
                {newslettersIsLoading ?
                    <PuffLoader size={60} css={spinnerCss} color={spinnerColor}/>
                    :
                    <>
                        {editNewsletter ?
                            <HtmlEditor template={emailTemplate}
                                        changeTemplate={() => setEditNewsletter(null)}
                                        email={editNewsletter}
                                        changeEmail={handleChangeEmail}/>
                            :
                            <>
                                <NewslettersTable newsletters={newsletters} change={handleNewslettersChange}/>
                                {newslettersPagination.paginationContainer}
                            </>
                        }
                    </>
                }
            </Card>
        </MDBox>
    );
};

export default Newsletters;