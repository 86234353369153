import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import useServer from "../hooks/useServer";
import {AUTH} from "../utils/serverUrls";
import MDBox from "../components/MDBox";
import UsersList from "elements/UsersList";
import {toast, ToastContainer} from "react-toastify";
import {initialPaginate, initialUserFilters, initialUserSearch, initialUserSort} from "../utils/constants";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import Swal from "sweetalert2";
import usePagination from "../hooks/usePagination";

const searchOptions = [
    {label: 'First Name', value: 'firstname'},
    {label: 'Last Name', value: 'lastname'},
    {label: 'Email', value: 'email'}
];

const sortOptions = [
    {label: 'newest', value: {column: 'registeredAt', order: 'desc'}},
    {label: 'oldest', value: {column: 'registeredAt', order: 'asc'}},
    {label: 'email', value: {column: 'email', order: 'asc'}}
];

const toggleOptions = [
    {label: 'Without Profile', value: false, field: 'hasProfile', reverse: true},
    {label: 'Unactivated', value: false, field: 'activated', reverse: true},
    {label: 'Admins', value: false, field: 'isAdmin'},
    {label: 'Fake', value: false, field: 'isFake'},
];

const Users = () => {
    // States
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState(initialUserFilters);
    const [search, setSearch] = useState(initialUserSearch);
    const [sort, setSort] = useState(initialUserSort);
    const {setPagination, paginate, setPaginate, paginationContainer} = usePagination(50);
    const navigate = useNavigate();
    // Context
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    // Hooks
    const [usersResponse, usersError, usersIsLoading, usersSendRequest, usersSetError] = useServer(AUTH);

    const handleSearch = (searchText, searchField) => {
        let newSearch = {...initialUserSearch};
        if (searchText && searchField) newSearch[searchField.value] = searchText;
        setSearch(newSearch);
        setPaginate({...initialPaginate});
    }

    const handleFilter = data => {
        let newFilters = {...filters};
        for (let item of data) {
            if (item.value) {
                newFilters[item.field] = item.reverse ? !item.value : item.value;
            } else newFilters[item.field] = null;
        }
        setFilters(newFilters);
        setPaginate({...initialPaginate});
    }

    const handleSort = data => {
        setSort(data.value);
    }
    const searchUsers = () => {
        usersSendRequest({
            method: 'get',
            params: Object.assign(search, filters, paginate, sort)
        });
    }

    const handleDeleteUsers = arr => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                usersSendRequest({method: 'delete', data: arr});
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    const handleClickDetails = id => navigate(id ? '/users/' + id : '/users');

    // On pagination or filtration changes
    useEffect(() => {
        if (paginate || filters || sort || search) searchUsers();
    }, [paginate, filters, sort, search])


    // On Loading
    useEffect(() => {
        if (usersIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [usersIsLoading])

    // On Errors
    useEffect(() => {
        if (usersError) {
            toast.error(usersError.data?.message ?? 'Something went wrong');
            usersSetError(false)
        }
        return () => usersSetError(false);
    }, [usersError])

    // On Users response
    useEffect(() => {
        if (usersResponse) {
            if (usersResponse.data?.users && usersResponse.data.pagination) {
                setUsers(usersResponse.data.users);
                setPagination(usersResponse.data.pagination);
            }
            if (usersResponse.data?.deleted && usersResponse.data.deleted.length) {
                searchUsers();
            }
        }
    }, [usersResponse])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <UsersList
                title="Users"
                users={users}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                handleSort={handleSort}
                searchOptions={searchOptions}
                sortOptions={sortOptions}
                toggleOptions={toggleOptions}
                handleDeleteUsers={handleDeleteUsers}
                paginationContainer={paginationContainer}
                handleClickDetails={handleClickDetails}
            />
        </MDBox>
    )
}

export default Users;