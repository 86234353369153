import React, {useContext} from 'react';
import MDBox from "../components/MDBox";
import Breadcrumbs from "../elements/Breadcrumbs";
import {Home} from "@mui/icons-material";
import MDButton from "../components/MDButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../contexts/auth/AuthContext";

const Header = ({routes, handleToggle}) => {
    const {logout} = useContext(AuthContext);
    return (
        <MDBox component="header" display="flex" alignItems="center" sx={{p: {xs: 1, sm: 2}}}>
            <MDButton className="btn-toggle" onClick={() => handleToggle(true)} iconOnly color="dark" size="small"
                      sx={{mr: 1}}>
                <FontAwesomeIcon icon={faBars}/>
            </MDButton>
            <MDBox>
                <Breadcrumbs
                    icon={<Home fontSize="medium"/>}
                    title={routes[routes.length - 1]}
                    route={routes}
                />
            </MDBox>

            <MDBox display="flex" sx={{ml: 'auto'}}>
                <MDButton variant="outlined" color="dark" circular iconOnly size="small" sx={{ml: 1}}
                          onClick={() => logout()}
                >
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default Header;