import React, {useContext, useEffect, useState} from 'react';
import {Autocomplete, Card, CardActions, CardContent, Grid, Modal, Slide} from "@mui/material";
import useServer from "../hooks/useServer";
import {COMMON_DATA} from "../utils/serverUrls";
import DataTable from "../elements/DataTable";
import '@asseinfo/react-kanban/dist/styles.css'
import MDBox from "../components/MDBox";
import MDButton from "../components/MDButton";
import MDTypography from "../components/MDTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBaby,
    faBullseye,
    faEye,
    faGlassMartiniAlt,
    faGlobe,
    faGraduationCap,
    faGuitar,
    faLanguage,
    faLink,
    faPaintBrush,
    faPray,
    faRing,
    faSmoking,
    faTimes,
    faVenusMars
} from "@fortawesome/free-solid-svg-icons";
import MDInput from "../components/MDInput";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {SpinnerContext} from "../contexts/spinner/Spinner";
import {modalStyle} from "../utils/constants";
import {countries} from "../utils/countries";
import {capitalizeFirstLetter, generateTable, withoutSpaces, withSpaces} from "../utils/functions";

const icons = {
    goals: <FontAwesomeIcon icon={faBullseye}/>,
    children: <FontAwesomeIcon icon={faBaby}/>,
    countries: <FontAwesomeIcon icon={faGlobe}/>,
    'drinking Habits': <FontAwesomeIcon icon={faGlassMartiniAlt}/>,
    educations: <FontAwesomeIcon icon={faGraduationCap}/>,
    'eye Colors': <FontAwesomeIcon icon={faEye}/>,
    genders: <FontAwesomeIcon icon={faVenusMars}/>,
    'hair Colors': <FontAwesomeIcon icon={faPaintBrush}/>,
    hobbies: <FontAwesomeIcon icon={faGuitar}/>,
    languages: <FontAwesomeIcon icon={faLanguage}/>,
    'marital Statuses': <FontAwesomeIcon icon={faRing}/>,
    religions: <FontAwesomeIcon icon={faPray}/>,
    'smoking Habits': <FontAwesomeIcon icon={faSmoking}/>,
    sources: <FontAwesomeIcon icon={faLink}/>,
};


const ProfileOptions = () => {
    const [commonDataResponse, commonDataError, commonDataIsLoading, commonDataSendRequest, commonDataSetError] = useServer(COMMON_DATA);
    const [tables, setTables] = useState([]);
    const [commonData, setCommonData] = useState(null);
    const [formData, setFormData] = useState(null); // format: title, item
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData(null);
    }

    // On clicking for the action on data
    const handleManageClick = (isEdit, title, id) => {
        // On delete
        if (!isEdit && title && id) {
            Swal.fire({
                title: `Delete item from '${title}'?`,
                width: '320px',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Delete',
                confirmButtonColor: 'red',
                denyButtonText: `Cancel`,
                denyButtonColor: 'grey',
                icon: 'question'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setFormData({title: withoutSpaces(title), item: null});
                    commonDataSendRequest({method: 'delete', data: {title: withoutSpaces(title), id}});
                } else if (result.isDenied) {
                    Swal.close();
                }
            })
        }

        // On edit
        if (isEdit && title && id) {
            const item = commonData[withoutSpaces(title)].find(data => data._id === id);
            if (item) setFormData({title: withoutSpaces(title), item});
        }

        // On add
        if (isEdit && title && !id) {
            let newFormData = {};
            let obj = commonData[withoutSpaces(title)][0];
            if (obj) Object.keys(obj).map(key => newFormData[key] = '');
            else {
                if (title === 'countries') newFormData = {name: '', code: ''};
                else newFormData = {name: ''};
            }
            setFormData({title: withoutSpaces(title), item: newFormData});
        }
    }

    // On Load Page
    useEffect(() => {
        commonDataSendRequest({method: 'get'});
    }, [])

    // On data respond
    useEffect(() => {
        if (commonDataResponse) {
            let newTables = [];
            if (commonDataResponse.data instanceof Array) {
                const table = generateTable(commonDataResponse.data, formData?.title);
                if (table) {
                    tables.forEach(item => {
                        if (withoutSpaces(item?.title) === formData?.title) {
                            newTables.push(table);
                        } else newTables.push(item);
                    })
                }
                setTables(newTables);
                setCommonData({...commonData, [formData?.title]: commonDataResponse.data});
                handleCloseModal();
                Swal.fire({
                    width: '320px',
                    text: 'Successfully done',
                    icon: 'success'
                });
            } else {
                Object.keys(commonDataResponse.data).forEach(key => {
                    const table = generateTable(commonDataResponse.data[key], key);
                    if (table) newTables.push(table);
                })
                setTables(newTables);
                setCommonData(commonDataResponse.data);
            }
        }
    }, [commonDataResponse])

    // Open Modal on setFormData
    useEffect(() => {
        if (formData && formData.item) handleOpenModal();
        else if (openModal) handleCloseModal();
    }, [formData])

    // On Error
    useEffect(() => {
        if (commonDataError) {
            Swal.fire({
                icon: "error",
                width: '320',
                title: 'Error',
                text: commonDataError.message ?? 'Something went wrong'
            });
            commonDataSetError(null)
        }
        return () => commonDataSetError(null);
    }, [commonDataError])

    // On loading
    useEffect(() => {
        if (commonDataIsLoading) {
            setShowSpinner(true);
        } else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [commonDataIsLoading])

    const handleChangeFormData = (key, value) => {
        if (key && formData?.item) {
            if (key === 'countries') {
                let newItem = value ?? {};
                newItem._id = formData.item._id;
                setFormData({...formData, item: newItem});
            } else setFormData({...formData, item: {...formData.item, [key]: value}})
        }
    }

    const handleSaveChanges = () => {
        const id = formData?.item?._id;
        let method = 'get';
        if (id) {
            // edit
            method = 'patch';
        } else {
            // add
            method = 'post';
        }
        commonDataSendRequest({method, data: formData})
    }

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <Grid container spacing={3}>
                {tables.length > 0 && tables.map((table, index) => (
                    <Grid item xs={12} md={6} xl={4} xxl={3} key={'table-' + index}>
                        <Card>
                            <DataTable
                                table={table}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                icon={icons[table.title] ?? null}
                                manage
                                onManageClick={handleManageClick}/>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <Slide direction="down" in={openModal} timeout={700}>
                    <MDBox sx={modalStyle}>
                        <Card>
                            <CardContent>
                                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                    <MDTypography variant="h5">
                                        {formData?.item?._id ? 'Edit item in ' : 'Add item to '}
                                        {formData?.title ? `'${capitalizeFirstLetter(withSpaces(formData.title))}'` : ''}
                                    </MDTypography>
                                    <MDButton iconOnly sx={{mr: -2}} onClick={handleCloseModal}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </MDButton>
                                </MDBox>

                                {formData && formData.item &&
                                    (formData.title === 'countries' ?
                                        <Autocomplete
                                            onChange={(e, v) => handleChangeFormData('countries', v)}
                                            options={countries}
                                            autoHighlight
                                            fullWidth
                                            value={formData.item}
                                            getOptionLabel={(option) => option.name ?? ''}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderOption={(props, option) => (
                                                <MDBox component="li"
                                                       sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                        alt={option.name}
                                                    />
                                                    {option.name}
                                                </MDBox>
                                            )}
                                            renderInput={(params) =>
                                                <MDInput
                                                    {...params}
                                                    variant="standard"
                                                    // success={form.country.touched && form.country.valid}
                                                    // error={form.country.touched && !form.country.valid}
                                                    // label={`Country ${form.country.required ? '*' : ''}`}
                                                    InputProps={{...params.InputProps, autoComplete: "new-password"}}
                                                />
                                            }
                                        />
                                        :
                                        Object.keys(formData.item).map(key => (
                                            (key === '_id' || key === '__v') ? null :
                                                <MDBox key={key}>
                                                    <MDInput
                                                        variant="standard"
                                                        label={key}
                                                        data-field={key}
                                                        value={formData.item[key]}
                                                        onChange={e => handleChangeFormData(key, e.target.value)}
                                                        fullWidth
                                                    />
                                                </MDBox>
                                        )))}

                            </CardContent>
                            <CardActions>
                                <MDBox display="flex" justifyContent="space-around" sx={{width: "100%"}}>
                                    <MDButton size="small" color="success" sx={{px: 3}}
                                              onClick={handleSaveChanges}>Save</MDButton>
                                    <MDButton size="small" color="dark" onClick={handleCloseModal}>Cancel</MDButton>
                                </MDBox>
                            </CardActions>
                        </Card>
                    </MDBox>
                </Slide>
            </Modal>
        </MDBox>
    )
}

export default ProfileOptions;