import React, {Fragment} from 'react';
import MDBox from "../../components/MDBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUser} from "@fortawesome/free-solid-svg-icons";
import MDTypography from "../../components/MDTypography";
import {Divider} from "@mui/material";
import {ClockLoader} from "react-spinners";
import {spinnerColor, spinnerCss} from "../../utils/constants";

const spinner = (
    <MDBox display="flex" flex="1 0 auto" alignItems="center">
        <ClockLoader color={spinnerColor} css={spinnerCss} size={100}/>
    </MDBox>
);

const Statistics = ({title, color, icon, table}) => {
    return (
        <MDBox display="flex" flexDirection="column" minHeight='260px'>
            <MDBox display="flex" px={2} pt={1}>
                <MDBox
                    color="white"
                    bgColor={color ?? 'info'}
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    mt={-4}
                    mr={2}
                    width={60}
                    height={60}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {icon ?? <FontAwesomeIcon icon={faCheck}/>}
                </MDBox>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    {title ?? ''}
                </MDTypography>
            </MDBox>
            {table?.data && table.data.length > 0 ?
                <>
                    <MDBox textAlign="center">
                        <MDTypography variant="button" color="text" textTransform="uppercase">
                            total
                        </MDTypography>
                        <MDTypography variant="h4">
                            {table?.total ?? ''}
                        </MDTypography>
                    </MDBox>

                    {table.data.map((row, i) =>
                    <Fragment key={title + i}>
                        <Divider sx={{my: 1}}/>
                        <MDBox display="flex" justifyContent="space-around">
                            {Object.keys(row).map(key =>
                                <MDBox key={key}>
                                    <MDTypography component="span" variant="h6" color="warning">
                                        {row[key]}
                                    </MDTypography>
                                    <MDTypography component="span" variant="caption" color="warning" sx={{ml: 1}}>
                                        ({(row[key] * 100 / table.total).toFixed(2)}%)
                                    </MDTypography>
                                    <MDTypography component="div" variant="button" color="text">
                                        {key}
                                    </MDTypography>
                                </MDBox>
                            )}
                        </MDBox>
                    </Fragment>
                    )}
                </> : spinner
            }
        </MDBox>
    );
};

export default Statistics;