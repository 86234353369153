import React, {useContext, useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Modal, Slide, ToggleButton, ToggleButtonGroup} from "@mui/material";
import MDBox from "../components/MDBox";
import useServer from "../hooks/useServer";
import {ACTION, GIFT, GREETING, ICE_BREAKER, MEMBERSHIP, PRODUCT} from "../utils/serverUrls";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import Swal from "sweetalert2";
import DataTable from "../elements/DataTable";
import {cutAmountOf, generateTable} from "../utils/functions";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGift,
    faGifts,
    faHandPeace,
    faPlus,
    faRibbon,
    faSnowflake,
    faTimes,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import MDInput from "../components/MDInput";
import {modalStyle, toastConfig} from "../utils/constants";
import {toast, ToastContainer} from "react-toastify";
import ImgTable from "../elements/ImgTable";
import {StyledDropZone} from "react-drop-zone";
import 'react-drop-zone/dist/styles.css';
import FormData from "form-data";
import {Route, Routes, useLocation} from "react-router-dom";
import {faProductHunt} from "@fortawesome/free-brands-svg-icons";

const productContent = [
    {title: 'Gifts', value: 'amountOfGifts'},
    {title: 'Letters', value: 'amountOfLetters'},
    {title: 'Actions', value: 'amountOfActions'}
];

const emptyTable = title => ({rows: [], columns: [], title});

const ProductOptions = () => {
    // Contexts
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    // States
    const [products, setProducts] = useState([]);
    const [gifts, setGifts] = useState([]);
    const [actions, setActions] = useState([]);
    const [iceBreakers, setIceBreakers] = useState([]);
    const [greetings, setGreetings] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [formData, setFormData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    // Tables
    const [productTable, setProductTable] = useState(null);
    const [iceBreakerTable, setIceBreakerTable] = useState(null);
    const [greetingTable, setGreetingTable] = useState(null);
    const [membershipTable, setMembershipTable] = useState(null);
    // Hooks
    const [productsResponse, productsError, productsIsLoading, productsSendRequest, productsSetError] = useServer(PRODUCT);
    const [giftsResponse, giftsError, giftsIsLoading, giftsSendRequest, giftsSetError] = useServer(GIFT);
    const [actionsResponse, actionsError, actionsIsLoading, actionsSendRequest, actionsSetError] = useServer(ACTION);
    const [iceBreakersResponse, iceBreakersError, iceBreakersIsLoading, iceBreakersSendRequest, iceBreakersSetError] = useServer(ICE_BREAKER);
    const [greetingsResponse, greetingsError, greetingsIsLoading, greetingsSendRequest, greetingsSetError] = useServer(GREETING);
    const [membershipsResponse, membershipsError, membershipsIsLoading, membershipsSendRequest, membershipsSetError] = useServer(MEMBERSHIP);


    const {pathname} = useLocation();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData(null);
    }

    const previewFile = file => URL.createObjectURL(file);

    // Handle clicks from tables
    const handleManageClick = (isEdit, title, id) => {
        if (title === 'products') {
            if (isEdit && id) {
                setFormData({title, item: products.find(product => product._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {name: '', price: 0.00, content: []}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        productsSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
        if (title === 'memberships') {
            if (isEdit && id) {
                setFormData({title, item: memberships.find(membership => membership._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {months: 1, price: 0.00}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    if (result.isConfirmed) {
                        membershipsSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
        if (title === 'gifts') {
            if (isEdit && id) {
                setFormData({title, item: gifts.find(gift => gift._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {name: '', img: null}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        giftsSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
        if (title === 'actions') {
            if (isEdit && id) {
                setFormData({title, item: actions.find(action => action._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {name: '', img: null}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        actionsSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
        if (title === 'iceBreakers') {
            if (isEdit && id) {
                setFormData({title, item: iceBreakers.find(iceBreaker => iceBreaker._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {name: ''}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        iceBreakersSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
        if (title === 'greetings') {
            if (isEdit && id) {
                setFormData({title, item: greetings.find(greeting => greeting._id === id)})
            }
            if (isEdit && !id) {
                setFormData({title, item: {name: ''}})
            }
            if (!isEdit && id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Delete item?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: 'red',
                    cancelButtonColor: `grey`,
                    width: '320px'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        greetingsSendRequest({method: 'delete', data: {id}});
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            }
        }
    }

    // On save changes in modal window
    const handleSaveChanges = () => {
        if (!formData) return;
        let options;
        if (formData.item?._id) {
            options = {method: 'patch', data: formData.item}
        } else {
            options = {method: 'post', data: formData.item}
        }
        switch (formData?.title) {
            case 'products':
                productsSendRequest(options);
                break;
            case 'memberships':
                membershipsSendRequest(options);
                break;
            case 'gifts':
                if (formData.item?.img) {
                    if (typeof formData.item.img === 'object') {
                        let fd = new FormData();
                        fd.append('img', formData.item.img)
                        fd.append('item', JSON.stringify(formData.item))
                        options.data = fd;
                    }
                    giftsSendRequest(options);
                } else toast.error('No Image found', toastConfig);
                break;
            case 'actions':
                if (formData.item?.img) {
                    if (typeof formData.item.img === 'object') {
                        let fd = new FormData();
                        fd.append('img', formData.item.img)
                        fd.append('item', JSON.stringify(formData.item))
                        options.data = fd;
                    }
                    actionsSendRequest(options);
                } else toast.error('No Image found', toastConfig);
                break;
            case 'iceBreakers':
                iceBreakersSendRequest(options);
                break;
            case 'greetings':
                greetingsSendRequest(options);
                break;
            default:
                break;
        }
        setOpenModal(false);
    }

    // Handle select content
    const handleSelectContent = (e, v) => setSelectedOption(v);

    // Handle add content
    const handleAddContent = () => {
        if (selectedOption && formData?.item?.content) {
            let content = [...formData.item.content];
            const index = formData.item.content.findIndex(item => item.option === selectedOption);
            if (index >= 0) content[index].value++;
            else content.push({option: selectedOption, value: 1});
            setFormData({...formData, item: {...formData.item, content}})
        }
    }

    // Handle change regular form inputs
    const handleChangeFormData = (key, value) => {
        setFormData({...formData, item: {...formData.item, [key]: value}})
    }

    // Handle change content form inputs
    const handleChangeContent = (key, value) => {
        const content = [...formData.item.content];
        if (value === 'remove') {
            content.splice(key, 1);
        } else {
            content[key] = {...content[key], value};
        }
        setFormData({...formData, item: {...formData.item, content}})
    }

    // On Load Page
    useEffect(() => {
        const getOption = {method: 'get'};
        if (pathname === '/product-options') {
            productsSendRequest(getOption);
            membershipsSendRequest(getOption);
        }
        if (pathname === '/product-options/gifts') giftsSendRequest(getOption);
        if (pathname === '/product-options/ice-breakers') iceBreakersSendRequest(getOption);
        if (pathname === '/product-options/greetings') greetingsSendRequest(getOption);
        if (pathname === '/product-options/actions') actionsSendRequest(getOption);
    }, [pathname])

    // On Products Response
    useEffect(() => {
        if (productsResponse) {
            if (Array.isArray(productsResponse.data)) {
                const table = generateTable(productsResponse.data, 'products');
                setProductTable(table);
                setProducts(productsResponse.data);
            } else if (typeof productsResponse.data === 'object') {
                let newProducts;
                const isExists = products.some(product => product._id === productsResponse.data?._id);
                if (isExists) {
                    newProducts = products.map(product => product._id === productsResponse.data?._id ? productsResponse.data : product);
                } else {
                    newProducts = [...products];
                    newProducts.push(productsResponse.data);
                }
                const table = generateTable(newProducts, 'products');
                setProductTable(table);
                setProducts(newProducts);
            } else if (typeof productsResponse.data === 'string') {
                let newProducts = products.filter(product => product._id !== productsResponse.data);
                const table = generateTable(newProducts, 'products');
                setProductTable(table);
                setProducts(newProducts);
            }
        }
    }, [productsResponse])

    // On Memberships Response
    useEffect(() => {
        if (membershipsResponse) {
            if (Array.isArray(membershipsResponse.data)) {
                const table = generateTable(membershipsResponse.data, 'memberships');
                setMembershipTable(table);
                setMemberships(membershipsResponse.data);
            } else if (typeof membershipsResponse.data === 'object') {
                let newMemberships;
                const isExists = memberships.some(membership => membership._id === membershipsResponse.data?._id);
                if (isExists) {
                    newMemberships = memberships.map(membership => membership._id === membershipsResponse.data?._id ? membershipsResponse.data : membership);
                } else {
                    newMemberships = [...memberships];
                    newMemberships.push(membershipsResponse.data);
                }
                const table = generateTable(newMemberships, 'memberships');
                setMembershipTable(table);
                setMemberships(newMemberships);
            } else if (typeof membershipsResponse.data === 'string') {
                let newMemberships = memberships.filter(membership => membership._id !== membershipsResponse.data);
                const table = generateTable(newMemberships, 'memberships');
                setMembershipTable(table);
                setMemberships(newMemberships);
            }
        }
    }, [membershipsResponse])

    // On Gifts Response
    useEffect(() => {
        if (giftsResponse) {
            if (Array.isArray(giftsResponse.data)) {
                setGifts(giftsResponse.data);
            } else if (typeof giftsResponse.data === 'object') {
                let newGifts;
                const isExists = gifts.some(gift => gift._id === giftsResponse.data?._id);
                if (isExists) {
                    newGifts = gifts.map(gift => gift._id === giftsResponse.data?._id ? giftsResponse.data : gift);
                } else {
                    newGifts = [...gifts];
                    newGifts.push(giftsResponse.data);
                }
                setGifts(newGifts);
            } else if (typeof giftsResponse.data === 'string') {
                let newGifts = gifts.filter(gift => gift._id !== giftsResponse.data);
                setGifts(newGifts);
            }
        }
    }, [giftsResponse])

    // On Actions Response
    useEffect(() => {
        if (actionsResponse) {
            if (actionsResponse) {
                if (Array.isArray(actionsResponse.data)) {
                    setActions(actionsResponse.data);
                } else if (typeof actionsResponse.data === 'object') {
                    let newActions;
                    const isExists = actions.some(action => action._id === actionsResponse.data?._id);
                    if (isExists) {
                        newActions = actions.map(action => action._id === actionsResponse.data?._id ? actionsResponse.data : action);
                    } else {
                        newActions = [...actions];
                        newActions.push(actionsResponse.data);
                    }
                    setActions(newActions);
                } else if (typeof actionsResponse.data === 'string') {
                    let newActions = actions.filter(action => action._id !== actionsResponse.data);
                    setActions(newActions);
                }
            }
        }
    }, [actionsResponse])

    // On Ice-Breakers Response
    useEffect(() => {
        if (iceBreakersResponse) {
            if (Array.isArray(iceBreakersResponse.data)) {
                const table = generateTable(iceBreakersResponse.data, 'iceBreakers');
                setIceBreakerTable(table);
                setIceBreakers(iceBreakersResponse.data);
            } else if (typeof iceBreakersResponse.data === 'object') {
                let newIceBreakers;
                const isExists = iceBreakers.some(iceBreaker => iceBreaker._id === iceBreakersResponse.data?._id);
                if (isExists) {
                    newIceBreakers = iceBreakers.map(iceBreaker => iceBreaker._id === iceBreakersResponse.data?._id ? iceBreakersResponse.data : iceBreaker);
                } else {
                    newIceBreakers = [...iceBreakers];
                    newIceBreakers.push(iceBreakersResponse.data);
                }
                const table = generateTable(newIceBreakers, 'iceBreakers');
                setIceBreakerTable(table);
                setIceBreakers(newIceBreakers);
            } else if (typeof iceBreakersResponse.data === 'string') {
                let newIceBreakers = iceBreakers.filter(iceBreaker => iceBreaker._id !== iceBreakersResponse.data);
                const table = generateTable(newIceBreakers, 'iceBreakers');
                setIceBreakerTable(table);
                setIceBreakers(newIceBreakers);
            }
        }
    }, [iceBreakersResponse])

    // On Greetings Response
    useEffect(() => {
        if (greetingsResponse) {
            if (Array.isArray(greetingsResponse.data)) {
                const table = generateTable(greetingsResponse.data, 'greetings');
                setGreetingTable(table);
                setGreetings(greetingsResponse.data);
            } else if (typeof greetingsResponse.data === 'object') {
                let newGreetings;
                const isExists = greetings.some(greeting => greeting._id === greetingsResponse.data?._id);
                if (isExists) {
                    newGreetings = greetings.map(greeting => greeting._id === greetingsResponse.data?._id ? greetingsResponse.data : greeting);
                } else {
                    newGreetings = [...greetings];
                    newGreetings.push(greetingsResponse.data);
                }
                const table = generateTable(newGreetings, 'greetings');
                setGreetingTable(table);
                setGreetings(newGreetings);
            } else if (typeof greetingsResponse.data === 'string') {
                let newGreetings = greetings.filter(greeting => greeting._id !== greetingsResponse.data);
                const table = generateTable(newGreetings, 'greetings');
                setGreetingTable(table);
                setGreetings(newGreetings);
            }
        }
    }, [greetingsResponse])

    // On Error
    useEffect(() => {
        if (productsError) {
            toast.error(productsError.data?.message ?? 'Something went wrong', toastConfig);
            productsSetError(null);
            return () => productsSetError(false);
        }
        if (membershipsError) {
            toast.error(membershipsError.data?.message ?? 'Something went wrong', toastConfig);
            membershipsSetError(null);
            return () => membershipsSetError(false);
        }
        if (giftsError) {
            toast.error(giftsError.data?.message ?? 'Something went wrong', toastConfig);
            giftsSetError(null);
            return () => productsSetError(false);
        }
        if (actionsError) {
            toast.error(actionsError.data?.message ?? 'Something went wrong', toastConfig);
            actionsSetError(null);
            return () => actionsSetError(false);
        }
        if (iceBreakersError) {
            toast.error(iceBreakersError.data?.message ?? 'Something went wrong', toastConfig);
            iceBreakersSetError(null);
            return () => iceBreakersSetError(false);
        }
        if (greetingsError) {
            toast.error(greetingsError.data?.message ?? 'Something went wrong', toastConfig);
            greetingsSetError(null);
            return () => greetingsSetError(false);
        }

    }, [productsError, membershipsError, giftsError, actionsError, iceBreakersError, greetingsError])

    // On Loading
    useEffect(() => {
        if (productsIsLoading || membershipsIsLoading || giftsIsLoading || actionsIsLoading || iceBreakersIsLoading || greetingsIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [productsIsLoading, membershipsIsLoading, giftsIsLoading, actionsIsLoading, iceBreakersIsLoading, greetingsIsLoading])

    // Open Modal on formData changing
    useEffect(() => {
        if (formData) handleOpenModal();
    }, [formData])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <Routes>
                <Route path="/" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={productTable ?? emptyTable('products')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faProductHunt}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>
                <Route path="/memberships" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={membershipTable ?? emptyTable('memberships')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faRibbon}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>
                <Route path="/gifts" element={
                    <Card sx={{my: 2, p: 1}}>
                        <ImgTable data={gifts}
                                  title={'gifts'}
                                  icon={<FontAwesomeIcon icon={faGift}/>}
                                  onClick={handleManageClick}
                        />
                    </Card>
                }/>
                <Route path="/actions" element={
                    <Card sx={{my: 2, p: 1}}>
                        <ImgTable data={actions}
                                  title={'actions'}
                                  icon={<FontAwesomeIcon icon={faGifts}/>}
                                  onClick={handleManageClick}/>
                    </Card>
                }/>
                <Route path="/ice-breakers" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={iceBreakerTable ?? emptyTable('iceBreakers')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faSnowflake}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>
                <Route path="/greetings" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={greetingTable ?? emptyTable('greetings')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faHandPeace}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>

            </Routes>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <Slide direction="down" in={openModal} timeout={700}>
                    <MDBox sx={modalStyle}>
                        <Card>
                            <CardContent>
                                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                    <MDTypography variant="h5">
                                        {formData?.item?._id ? 'Edit item in ' : 'Add item to '}
                                        {formData?.title ? `'${formData.title}'` : ''}
                                    </MDTypography>
                                    <MDButton iconOnly sx={{mr: -2}} onClick={handleCloseModal}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </MDButton>
                                </MDBox>

                                {formData && formData.item && Object.keys(formData.item).map(key => (
                                    (key === '_id' || key === '__v') ? null :
                                        (key === 'content' ?
                                                <MDBox key={key}>
                                                    <MDTypography variant="body1" sx={{
                                                        my: 2,
                                                        textAlign: 'center'
                                                    }}>
                                                        Content
                                                    </MDTypography>
                                                    {formData.item['content'].map((row, i) =>
                                                        <MDBox key={'content-' + i} display="flex" alignItems="center">
                                                            <MDTypography variant="button">
                                                                {cutAmountOf(row.option)}
                                                            </MDTypography>
                                                            <MDInput
                                                                variant="standard"
                                                                type="number"
                                                                data-field={row.option}
                                                                value={row.value}
                                                                onChange={e => handleChangeContent(i, e.target.value)}
                                                                sx={{width: '40px', ml: 'auto'}}
                                                            />
                                                            <MDButton iconOnly
                                                                      onClick={() => handleChangeContent(i, 'remove')}>
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </MDButton>
                                                        </MDBox>
                                                    )}
                                                    <MDBox textAlign="center" sx={{mt: 2}} display="flex"
                                                           flexDirection="column">

                                                        <ToggleButtonGroup
                                                            color="success"
                                                            size="small"
                                                            value={selectedOption}
                                                            fullWidth
                                                            exclusive
                                                            onChange={handleSelectContent}
                                                            sx={{my: 2}}
                                                        >
                                                            {productContent.map(content =>
                                                                <ToggleButton key={content.value}
                                                                              value={content.value}>{content.title}</ToggleButton>
                                                            )}
                                                        </ToggleButtonGroup>


                                                        <MDButton color="warning" size="small"
                                                                  onClick={handleAddContent}>
                                                            <FontAwesomeIcon icon={faPlus}
                                                                             style={{marginRight: '10px'}}/>Add content
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                                : (key === 'img' ?
                                                    <MDBox key={key} position="relative">

                                                        {formData.item?.img ?
                                                            <>
                                                                <MDBox component="img" width="300px"
                                                                       src={typeof formData.item?.img === 'string' ?
                                                                           process.env.REACT_APP_API_URL + '/' + formData.item?.img
                                                                           :
                                                                           previewFile(formData.item?.img)
                                                                       }
                                                                />

                                                                <MDButton
                                                                    iconOnly
                                                                    color="error"
                                                                    sx={{position: 'absolute', bottom: 0, right: 0}}
                                                                    onClick={() => setFormData({
                                                                        ...formData,
                                                                        item: {...formData.item, img: null}
                                                                    })}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes}/>
                                                                </MDButton>
                                                            </>
                                                            :
                                                            <StyledDropZone
                                                                accept="image/*"
                                                                onDrop={file => setFormData({
                                                                    ...formData,
                                                                    item: {...formData.item, img: file}
                                                                })}
                                                                children={"Add Profile Photo *"}
                                                                className="picture-dropzone"
                                                            />
                                                        }
                                                    </MDBox>
                                                    :
                                                    <MDBox key={key}>
                                                        <MDInput
                                                            variant="standard"
                                                            label={key}
                                                            data-field={key}
                                                            type={typeof formData.item[key]}
                                                            value={formData.item[key]}
                                                            onChange={e => handleChangeFormData(key, e.target.value)}
                                                            fullWidth
                                                        />
                                                    </MDBox>)
                                        )
                                ))}

                            </CardContent>
                            <CardActions>
                                <MDBox display="flex" justifyContent="space-around" sx={{width: "100%"}}>
                                    <MDButton size="small" color="success" sx={{px: 3}}
                                              onClick={handleSaveChanges}>Save</MDButton>
                                    <MDButton size="small" color="dark" onClick={handleCloseModal}>Cancel</MDButton>
                                </MDBox>
                            </CardActions>
                        </Card>
                    </MDBox>
                </Slide>
            </Modal>
        </MDBox>
    );
};

export default ProductOptions;