import React, {useState} from 'react';
import {Menu, MenuItem, Table, TableCell} from "@mui/material";
import MDBox from "../../components/MDBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEllipsisV,
    faEnvelope,
    faPaperPlane,
    faPlus,
    faRedoAlt,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import moment from "moment";

const tableHeadStyle = {
    background: '#eff2f5'
};

const NewslettersTable = ({newsletters, change}) => {

    const generateAnchorElList = newsletters => newsletters.reduce((a, v) => ({...a, [v._id]: null}), {});
    const [anchorElList, setAnchorElList] = useState(() => generateAnchorElList(newsletters));
    const handleOpen = (e, id) => setAnchorElList({...anchorElList, [id]: e.currentTarget});
    const handleClose = () => setAnchorElList(generateAnchorElList(newsletters));

    return (
        <>
            <MDBox display="flex" px={2} mb={2}>
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    mt={-3}
                    mr={2}
                    width={60}
                    height={60}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FontAwesomeIcon icon={faEnvelope}/>
                </MDBox>
                <MDTypography variant="h6" textAlign="center" sx={{my: 1}} textTransform="uppercase">
                    Newsletters
                </MDTypography>
            </MDBox>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell style={tableHeadStyle}>Created</TableCell>
                        <TableCell style={tableHeadStyle}>Title</TableCell>
                        <TableCell style={tableHeadStyle}>Sent</TableCell>
                        <TableCell style={tableHeadStyle}>Recipients</TableCell>
                        <TableCell align="right" style={tableHeadStyle}>
                            <MDButton color="success"
                                      iconOnly
                                      onClick={() => change('add')}
                            >
                                <FontAwesomeIcon icon={faPlus}/>
                            </MDButton>
                        </TableCell>
                    </TableRow>
                    {newsletters && newsletters.length > 0 && newsletters.map(newsletter => (
                        <TableRow key={newsletter._id}>
                            <TableCell sx={{py: 0}}>
                                <MDTypography variant="body2" color="text">
                                    {moment(newsletter.createdAt).format('MM/D/YYYY')}
                                </MDTypography>
                                <MDTypography variant="body2" color="text">
                                    {moment(newsletter.createdAt).format('HH:mm a')}
                                </MDTypography>
                            </TableCell>
                            <TableCell sx={{py: 0}}>
                                <MDTypography variant="h6" color="text">
                                    {newsletter.title}
                                </MDTypography>
                            </TableCell>
                            <TableCell sx={{py: 0}}>
                                <MDTypography variant="body2" color="text">
                                    {newsletter.sentAt ?
                                        moment(newsletter.sentAt).format('MM/D/YYYY')
                                        : '-'
                                    }
                                </MDTypography>
                                <MDTypography variant="body2" color="text">
                                    {newsletter.sentAt ?
                                        moment(newsletter.sentAt).format('HH:mm a')
                                        : ''
                                    }
                                </MDTypography>
                            </TableCell>
                            <TableCell sx={{py: 0}}>{newsletter.recipients.length}</TableCell>
                            <TableCell sx={{py: 0}} align="right">
                                <MDButton
                                    iconOnly size="small" circular
                                    onClick={e => handleOpen(e, newsletter._id)}
                                >
                                    <FontAwesomeIcon icon={faEllipsisV}/>
                                </MDButton>
                                <Menu
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={anchorElList[newsletter._id]}
                                    open={Boolean(anchorElList[newsletter._id])}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                        <MDButton variant="contained" color="white" size="small" iconOnly
                                                  onClick={() => change('send', newsletter._id)}
                                        >
                                            <FontAwesomeIcon icon={newsletter.sentAt ? faRedoAlt : faPaperPlane}/>
                                        </MDButton>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                        <MDButton variant="contained" color="white" size="small" iconOnly
                                                  onClick={() => change('edit', newsletter._id)}
                                        >
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </MDButton>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{minWidth: '3.5rem'}}>
                                        <MDButton variant="contained" color="white" size="small" iconOnly
                                                  onClick={() => change('delete', newsletter._id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </MDButton>
                                    </MenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default NewslettersTable;