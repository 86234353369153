import React, {useEffect, useRef, useState} from 'react';
import EmailEditor from "react-email-editor";
import MDBox from "../components/MDBox";
import {FormControl, InputLabel, MenuItem, Select, Stack, Switch} from "@mui/material";
import MDButton from "../components/MDButton";
import MDInput from "../components/MDInput";
import MDTypography from "../components/MDTypography";
import {AUTH} from "../utils/serverUrls";
import useServer from "../hooks/useServer";
import {PuffLoader} from "react-spinners";
import {spinnerColor, spinnerCss, toastConfig} from "../utils/constants";
import {toast, ToastContainer} from "react-toastify";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const emailTypes = [
    'newsletter', 'notification', 'email'
];

const HtmlEditor = ({template, email, changeTemplate, changeEmail}) => {
    const emailEditorRef = useRef(null);
    const [ready, setReady] = useState(false);
    const [title, setTitle] = useState(email ? email.title : '');
    const [recipients, setRecipients] = useState(email ? email.recipients : []);
    const [type, setType] = useState(email ? email.type : '');
    // User Filters
    const [filters, setFilters] = useState({isAdmin: false, isFake: false});
    const [checkSubscribers, setCheckSubscribers] = useState(false);
    const [checkWithProfile, setCheckWithProfile] = useState(false);
    const [checkWithoutProfile, setCheckWithoutProfile] = useState(false);
    const [checkActivated, setCheckActivated] = useState(false);
    const [checkNotActivated, setCheckNotActivated] = useState(false);
    const [checkWithFake, setCheckWithFake] = useState(false);
    const [usersResponse, usersError, usersIsLoading, usersSendRequest, usersSetError] = useServer(AUTH);
    const [sync, setSync] = useState(!!email);

    // On Change Filters: download users if email mode
    useEffect(() => {
        if (email && !sync) usersSendRequest({method: 'get', params: filters});
    }, [filters, sync])

    // On Users Respond
    useEffect(() => {
        if (usersResponse && usersResponse.data?.users?.length > 0) {
            setRecipients(usersResponse.data.users.map(user => ({
                email: user.email,
                firstname: user.profile ? user.profile.firstname : null,
                lastname: user.profile ? user.profile.lastname : null
            })));
        } else setRecipients([]);
    }, [usersResponse])

    // Change Users Filter
    useEffect(() => {
        if (!sync) setSync(true);
        if (checkSubscribers) {
            setFilters(prevState => ({...prevState, subscribedForNewsletters: true}))
        } else if (filters.subscribedForNewsletters) setFilters(prevState => {
            const {subscribedForNewsletters, ...newState} = prevState;
            return newState;
        })
        if (checkWithProfile || checkWithoutProfile) {
            setFilters(prevState => ({...prevState, hasProfile: checkWithProfile}))
        } else setFilters(prevState => {
            const {hasProfile, ...newState} = prevState;
            return newState;
        })
        if (checkActivated || checkNotActivated) {
            setFilters(prevState => ({...prevState, activated: checkActivated}))
        } else setFilters(prevState => {
            const {activated, ...newState} = prevState;
            return newState;
        })

        if (checkWithFake) {
            setFilters(prevState => {
                const {isFake, ...returnState} = prevState;
                return returnState;
            })
        } else if (!filters.isFake) setFilters(prevState => ({...prevState, isFake: false}))
    }, [checkSubscribers, checkWithProfile, checkWithoutProfile, checkActivated, checkNotActivated, checkWithFake])

    // On Error
    useEffect(() => {
        if (usersError) {
            toast.error(usersError.data?.message ?? 'Something went wrong', toastConfig);
            usersSetError(null);
        }
        return () => usersSetError(null);
    }, [usersError])

    // Save Data
    const saveData = () => {
        emailEditorRef.current.editor.exportHtml(template => {
            if (email) {
                if (!title) return toast.error('No title!', toastConfig);
                if (!type) return toast.error('The email type NOT selected!', toastConfig);
                if (recipients.length === 0) return toast.error('No recipients?', toastConfig);
                const exportEmail = {
                    title,
                    type,
                    html: template.html,
                    design: template.design,
                    recipients,
                    createdAt: new Date(),
                    sentAt: null
                };
                if (email._id) exportEmail._id = email._id;
                changeEmail(exportEmail);
            } else {
                changeTemplate(template);
            }
        });
    };

    // Set Design (Template)
    const onReady = () => {
        if (template && template.design) emailEditorRef.current?.editor.loadDesign(template.design);
        setReady(true);
    }

    return (
        <MDBox my={2}>
            <ToastContainer/>
            <Stack direction="row" spacing={1} sx={{mb: 2}}>
                <MDButton color="success" size="small" disabled={!ready} onClick={saveData}>Save</MDButton>
                <MDButton color="success" size="small" disabled={!ready}
                          onClick={() => changeTemplate(null)}>Cancel</MDButton>
                {email &&
                    <MDBox display="flex" width="100%" alignItems="center">
                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkSubscribers} onChange={() => setCheckSubscribers(!checkSubscribers)}
                                    size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => setCheckSubscribers(true)}
                            >
                                Subscribers
                            </MDTypography>
                        </MDBox>

                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkWithProfile} onChange={() => {
                                if (checkWithoutProfile) setCheckWithoutProfile(false);
                                setCheckWithProfile(!checkWithProfile)
                            }} size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => {
                                    setCheckWithProfile(true);
                                    setCheckWithoutProfile(false);
                                }}
                            >
                                With Profile
                            </MDTypography>
                        </MDBox>

                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkWithoutProfile} onChange={() => {
                                if (checkWithProfile) setCheckWithProfile(false);
                                setCheckWithoutProfile(!checkWithoutProfile)
                            }} size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => {
                                    setCheckWithoutProfile(true);
                                    setCheckWithProfile(false);
                                }}
                            >
                                Without Profile
                            </MDTypography>
                        </MDBox>


                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkActivated} onChange={() => {
                                if (checkNotActivated) setCheckNotActivated(false);
                                setCheckActivated(!checkActivated)
                            }} size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => {
                                    setCheckActivated(true);
                                    setCheckNotActivated(false);
                                }}
                            >
                                Activated
                            </MDTypography>
                        </MDBox>

                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkNotActivated} onChange={() => {
                                if (checkActivated) setCheckActivated(false);
                                setCheckNotActivated(!checkNotActivated)
                            }} size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => {
                                    setCheckNotActivated(true);
                                    setCheckActivated(false);
                                }}
                            >
                                Not Activated
                            </MDTypography>
                        </MDBox>

                        <MDBox display="flex" alignItems="center" flexDirection="column" ml={2}>
                            <Switch checked={checkWithFake} onChange={() => setCheckWithFake(!checkWithFake)} size="small"/>
                            <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                                sx={{cursor: "pointer", userSelect: "none", whiteSpace: 'nowrap'}}
                                onClick={() => {
                                    setCheckNotActivated(true);
                                    setCheckActivated(false);
                                }}
                            >
                                With fakes
                            </MDTypography>
                        </MDBox>

                        {sync &&
                            <MDButton color="dark" variant="outlined" sx={{mx: 2}} iconOnly
                                onClick={() => setSync(false)}
                            >
                                <FontAwesomeIcon icon={faSync}/>
                            </MDButton>
                        }
                        <MDBox display="flex" flexDirection="column" alignItems="center" ml='auto'>
                            {usersIsLoading ? <PuffLoader size={40} color={spinnerColor} css={spinnerCss}/>
                                :
                                <>
                                    <MDTypography variant="h6">
                                        {recipients?.length ?? 0}
                                    </MDTypography>
                                    <MDTypography variant="button">
                                        recipients
                                    </MDTypography>
                                </>
                            }
                        </MDBox>
                    </MDBox>
                }
            </Stack>
            {email &&
                <Stack direction="row" spacing={1}>
                    <FormControl style={{width: '150px'}}>
                        <InputLabel id="type-label">Email Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type"
                            sx={{p: 1.5}}
                            label="Email Type"
                            value={type ?? null}
                            onChange={e => setType(e.target.value)}
                            MenuProps={{variant: 'selectedMenu'}}
                        >
                            <MenuItem value=""/>
                            {emailTypes.map(type => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MDInput
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </Stack>
            }

            <MDBox my={2}>
                <EmailEditor ref={emailEditorRef} onReady={onReady}/>
            </MDBox>
        </MDBox>
    );
};

export default HtmlEditor;