import axios from "axios";

export function TokenInterceptor() {
    axios.interceptors.request.use(
        (req) => {
            const controller = new AbortController();
            const token = localStorage.getItem('token');
            if (!token && !req.url.includes('/api/auth/admin-login')) {
                return controller.abort();
            } else {
                if (req.url.includes('/api/')) req.url = process.env.REACT_APP_API_URL + req.url;
                req.headers['Authorization'] = `Bearer ${token}`;
                req.headers['Content-Type'] = req.data instanceof FormData ?
                    'application/x-www-form-urlencoded'
                    : 'application/json';
                return req;
            }
        },
        (err) => {
            return Promise.reject(err);
        }
    );
}