import React, {useContext, useEffect, useRef, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import MDBox from "../components/MDBox";
import {
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    Modal,
    Slide,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MDButton from "../components/MDButton";
import useServer from "../hooks/useServer";
import {CHECK_DUPLICATE_EMAILS, COMMON_DATA, UPLOAD_DATA} from "../utils/serverUrls";
import FormData from "form-data";
import usePagination from "../hooks/usePagination";
import MDInput from "../components/MDInput";
import {SyncLoader} from "react-spinners";
import {initialPaginate, modalWideStyle, toastConfig} from "../utils/constants";
import UsersList from "../elements/UsersList";
import MDTypography from "../components/MDTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt, faPaperPlane, faRedo, faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import defaultAvatar from "assets/images/default/default-picture.jpg";
import moment from "moment";
import {SpinnerContext} from "../contexts/spinner/Spinner";

const searchOptions = [
    {label: 'First Name', value: 'firstname'},
    {label: 'Last Name', value: 'lastname'},
    {label: 'Email', value: 'email'},
    {label: 'Country', value: 'country'}
];

const sortOptions = [
    {label: 'newest', value: {column: 'registeredAt', order: 'desc'}},
    {label: 'oldest', value: {column: 'registeredAt', order: 'asc'}},
    {label: 'email', value: {column: 'email', order: 'asc'}}
];

const toggleOptions = [
    // {label: 'men', value: true},
    // {label: 'women', value: true}
];

/**
 * @param {string | Blob} url - The source image
 * @param {number} aspectRatio - The aspect ratio (ex.: 1 , 16 / 9)
 * @return {Promise<HTMLCanvasElement>} A Promise that resolves with the resulting image as a canvas element
 */
function crop(url, aspectRatio) {
    // we return a Promise that gets resolved with our canvas element
    return new Promise((resolve) => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio;
            } else if (inputImageAspectRatio < aspectRatio) {
                outputHeight = inputWidth / aspectRatio;
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * 0.5;
            const outputY = (outputHeight - inputHeight) * 0.5;

            // create a canvas that will present the output image
            const outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            const ctx = outputImage.getContext('2d');
            ctx.drawImage(inputImage, outputX, outputY);
            resolve(outputImage);
        };

        // start loading our image
        inputImage.src = typeof url === 'string' ? url : URL.createObjectURL(url);
    });
}

function convertCanvasToFile(canvas, name) {
    const type = 'image/jpg';
    return new Promise((resolve) => {
        canvas.toBlob(blob => {
            const file = new File([blob], name, {type});
            resolve(file);
        }, type);
    });
}

function filterUploadData(emails, uploadData) {
    if (uploadData) {
        const newData = {profiles: [], avatars: [], photos: []};
        if (!emails || !emails.length) return newData;
        uploadData.profiles?.map(profile => {
            if (emails.includes(profile.user?.email)) {
                newData.profiles.push(profile);
                const avatar = uploadData.avatars.find(avatar => avatar?.name === profile.avatar);
                if (avatar) newData.avatars.push(avatar);
                const photos = uploadData.photos.filter(photo => photo?.name?.includes(profile.avatar));
                if (photos) photos.map(photo => newData.photos.push(photo));
            }
        });
        return newData;
    } else return null;
}

const ImportData = () => {
    const [commonDataResponse, commonDataError, commonDataIsLoading, commonDataSendRequest, commonDataSetError] = useServer(COMMON_DATA);
    const [uploadDataResponse, uploadDataError, uploadDataIsLoading, uploadDataSendRequest, uploadDataSetError] = useServer(UPLOAD_DATA);
    const [checkEmailsResponse, checkEmailsError, checkEmailsIsLoading, checkEmailsSendRequest, checkEmailsSetError] = useServer(CHECK_DUPLICATE_EMAILS);
    const {setPagination, paginate, setPaginate, paginationContainer} = usePagination(10);
    const [uploadData, setUploadData] = useState(null);
    const [users, setUsers] = useState([]);
    const [commonData, setCommonData] = useState(null);
    const [lastUpdateDate, setLastUpdateDate] = useState('2020-01-01');
    const [withAvatar, setWithAvatar] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [search, setSearch] = useState(null);
    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [uploadPhotos, setUploadPhotos] = useState([]);
    const inputCSV = useRef(null);
    const inputPhotos = useRef(null);
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);

    const handleSendUsers = e => {
        e.preventDefault();
        if (uploadData) {
            const emails = users.map(user => user.email);
            checkEmailsSendRequest({method: 'post', data: {emails}})
        }
    }

    const setUsersWithUploadData = data => {
        const avatarImages = [];
        if (uploadData) {
            let filteredProfiles = [...uploadData.profiles];
            if (data) {
                const {search, filter, sort} = data;
                // Search
                if (search) {
                    switch (search.searchField?.value) {
                        case 'email':
                            filteredProfiles = uploadData.profiles.filter(profile => profile.user?.[search.searchField?.value]?.toLowerCase().includes(search.searchText.toLowerCase()));
                            break;
                        case 'country':
                            filteredProfiles = uploadData.profiles.filter(profile => profile[search.searchField?.value]?.name?.toLowerCase().includes(search.searchText.toLowerCase()));
                            break;
                        default:
                            filteredProfiles = uploadData.profiles.filter(profile => profile[search.searchField?.value]?.toLowerCase().includes(search.searchText.toLowerCase()));
                            break;
                    }
                }

                if (sort && sort.sortField?.value) {
                    const {column, order} = sort.sortField.value;
                    if (order === 'asc') {
                        filteredProfiles = filteredProfiles.sort((a, b) => (a.user[column] > b.user[column]) ? 1 : -1)
                    } else filteredProfiles = filteredProfiles.sort((a, b) => (a.user[column] < b.user[column]) ? 1 : -1)
                }
                if (filter) {
                    filter.map(f => {
                        if (f.label === 'men' && f.value) {
                            filteredProfiles = filteredProfiles.filter()
                        }
                    })
                }

            }

            const newUsers = filteredProfiles.map((profile, index) => {
                    const avatarImage = uploadData.avatars.find(avatar => avatar?.name === profile.avatar);
                    const photos = uploadData.photos
                        .filter(photo => photo?.name?.includes(profile.avatar))
                        .map(photo => URL.createObjectURL(photo));
                    if (avatarImage) avatarImages.push(avatarImage);
                    return (
                        {
                            ...profile.user,
                            _id: 'user' + index,
                            profile: {
                                firstname: profile.firstname,
                                lastname: profile.lastname,
                                avatar: avatarImage ? URL.createObjectURL(avatarImage) : '',
                                photos: photos ?? []
                            }
                        })
                }
            );
            return {newUsers, avatarImages};
        } else return {newUsers: [], avatarImages: []};
    }

    const handleCloseModal = () => setSelectedProfile(null);

    const uploadFile = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const reader = new FileReader();
        reader.onloadend = (e) => {
            const text = (e.target.result);
            const arr = text.split('\n');
            const jsonObj = [];
            const headers = arr[0].split('","');
            for (let i = 1; i < arr.length; i++) {
                const data = arr[i].split('","');
                let obj = {};
                for (let j = 0; j < data.length; j++) {
                    if (headers[j]?.trim() === '"pid') {
                        obj[headers[j]?.trim().substring(1)] = data[j].trim().toString().substring(1);
                    } else if (headers[j]?.trim() === 'pinfo') {
                        obj[headers[j]?.trim()] = data[j].trim().toString().replace("&#039;", "'");
                    } else {
                        obj[headers[j]?.trim()] =
                            data[j].trim().toString() === "\\N" ||
                            data[j].trim().toString() === "\\N\""
                                ? null
                                : data[j].trim().toString();
                    }
                }
                jsonObj.push(obj);
            }
            const filteredObj = jsonObj.filter(item => item.pcountry && item.plastupd > lastUpdateDate);
            const profiles = [];
            const avatars = [];
            let photos = [];
            const countries = [];
            const getIndex = name => {
                let result = '';
                if (commonData?.countries) {
                    commonData.countries.map(country => {
                        if (country.name.includes(name)) result = country;
                    })
                }
                return result;
            }

            const getAvatar = async (id, withAvatar) => {
                let path = null;
                if (uploadPhotos.length) {
                    path = uploadPhotos.find(photo => photo.name?.includes(id + '-1-')) ??
                        (
                            uploadPhotos.find(photo => photo.name?.includes(id + '-1+')) ??
                            uploadPhotos.find(photo => photo.name?.includes(id + '-1'))
                        )
                }
                if (!path && withAvatar) return null;
                const canvas = await crop(path ?? defaultAvatar, 1);
                return await convertCanvasToFile(canvas, id);
            }

            const getPhotos = id => {
                const result = [];
                for (let i = 1; i < 6; i++) {
                    if (uploadPhotos.length) {
                        let item = uploadPhotos.find(photo => photo.name?.includes(id + '-' + i + '-')) ??
                            (
                                uploadPhotos.find(photo => photo.name?.includes(id + '-' + i + '+')) ??
                                uploadPhotos.find(photo => photo.name?.includes(id + '-' + i))
                            )
                        if (item) result.push(item);
                    }
                }
                return result;
            }

            const getRandom = array => {
                return array[Math.floor(Math.random() * array.length)];
            }

            Promise.all(filteredObj.map(async item => {
                const avatar = await getAvatar(item.pid, withAvatar);
                if (!avatar) return;
                const photoArray = getPhotos(item.pid);
                if (!countries.includes(item.pcountry)) countries.push(item.pcountry);
                const user = {
                    email: item.pemail,
                    password: item.passwd,
                    registeredAt: new Date(item.plastvis),
                    isAdmin: false,
                    subscribedForNewsletters: false,
                    subscribedForNotifications: false,
                    profile: null,
                    activated: true,
                    activationCode: null,
                    activationExpired: null,
                    userLastAction: null,
                    f: true
                };
                const profile = {
                    firstname: item.pfirst,
                    lastname: item.plast,
                    createdAt: new Date(item.plastvis),
                    birthday: new Date(item.pbirth),
                    country: getIndex(item.pcountry),
                    city: item.pcity,
                    source: commonData.sources.find(item => item.name === 'other'), //'62e723866d6f32143493388b',
                    gender: item.psex === "m" ? commonData.genders.find(item => item.name === 'male') :
                        (item.psex === "f" ? commonData.genders.find(item => item.name === 'female') : commonData.genders.find(item => item.name === 'other')),
                    seeking: item.pseek === "m" ? commonData.genders.find(item => item.name === 'male') :
                        (item.pseek === "w" ? commonData.genders.find(item => item.name === 'female') : commonData.genders.find(item => item.name === 'other')),
                    goal: getRandom(commonData.goals),
                    maritalStatus: item.pstatus === "1" ? commonData.maritalStatuses.find(item => item.name === 'single')
                        : (item.pstatus === "2" ? commonData.maritalStatuses.find(item => item.name === 'married') : commonData.maritalStatuses.find(item => item.name === 'divorced')),
                    childrenAmount: item.pchild === "0" ? commonData.children.find(item => item.name === '0')
                        : (item.pstatus === "1" ? commonData.children.find(item => item.name === '1')
                            : (item.pstatus === "2" ? commonData.children.find(item => item.name === '2') : commonData.children.find(item => item.name === '2+'))),
                    hairColor: item.phair === "Black" ? commonData.hairColors.find(item => item.name === 'black')
                        : (item.phair === "Brown" ? commonData.hairColors.find(item => item.name === 'brown')
                            : (item.phair === "Blond" ? commonData.hairColors.find(item => item.name === 'blond')
                                : (item.phair === 'Red' ? commonData.hairColors.find(item => item.name === 'red') : commonData.hairColors.find(item => item.name === 'other')))),
                    eyeColor: item.peyes === "Black" ? commonData.eyeColors.find(item => item.name === 'black')
                        : (item.peyes === "Brown" ? commonData.eyeColors.find(item => item.name === 'brown')
                            : (item.peyes === "Blue" ? commonData.eyeColors.find(item => item.name === 'blue')
                                : (item.peyes === 'Hazel' ? commonData.eyeColors.find(item => item.name === 'hazel')
                                    : (item.peyes === 'Green' ? commonData.eyeColors.find(item => item.name === 'green')
                                        : commonData.eyeColors.find(item => item.name === 'gray'))))),
                    education: item.peduc === 'h' ? commonData.educations.find(item => item.name.includes('High'))
                        : (item.peduc === 'u' ? commonData.educations.find(item => item.name.includes('Master'))
                            : commonData.educations.find(item => item.name.includes('Associate'))),
                    religion: item.prelig?.includes('Islam') ? commonData.religions.find(item => item.name === 'Islam')
                        : (item.prelig?.includes('Christian') ? commonData.religions.find(item => item.name === 'Christianity')
                            : (item.prelig === "Buddhist" ? commonData.religions.find(item => item.name === 'Buddhist')
                                : (item.prelig === 'Non-religious' ? commonData.religions.find(item => item.name === 'Non-religious')
                                    : commonData.religions.find(item => item.name === 'other')))),
                    smokingHabit: item.psmoke === 'n' ? commonData.smokingHabits.find(item => item.name.includes('no'))
                        : (item.psmoke === 'y' ? commonData.smokingHabits.find(item => item.name.includes('yes'))
                            : commonData.smokingHabits.find(item => item.name.includes('sometimes'))),
                    drinkingHabit: item.pdrink === 'n' ? commonData.drinkingHabits.find(item => item.name.includes('no'))
                        : (item.pdrink === 'y' ? commonData.drinkingHabits.find(item => item.name.includes('yes'))
                            : commonData.drinkingHabits.find(item => item.name.includes('sometimes'))),
                    user,
                    occupation: item.poccup,
                    about: item.pinfo?.replaceAll('&#039;', "'"),
                    height: +item.pheight ?? 0,
                    weight: +item.pweight ?? 0,
                    hobbies: [],
                    languages: [commonData.languages.find(item => item.name.includes('english'))],
                    avatar: item.pid,
                    photos: [],
                    favorites: null,
                    likes: null,
                    liked: null,
                    blocked: null,
                    isPremium: false,
                    premiumEnds: null,
                    amountOfLetters: 0,
                    amountOfGifts: 0,
                    amountOfActions: 0,
                    amountOfLetsMatch: null,
                    amountOfIceBreakers: null,
                    amountOfGreetings: null
                };

                profiles.push(profile);
                avatars.push(avatar);
                photos = photos.concat(photoArray);
            })).then(() => {
                setUploadData({profiles, avatars, photos});
            }).finally(() => setIsLoading(false))
        };
        reader.readAsText(e.target.files[0]);
    }

    const resetUsers = e => {
        e.preventDefault();
        const {newUsers, avatarImages} = setUsersWithUploadData();
        setUsers(newUsers);
        avatarImages.map(avatarImage => URL.revokeObjectURL(avatarImage));
    }

    const handleDeleteUsers = ids => {
        setUsers(prev => prev.filter(user => !ids.includes(user._id)))
    }

    const handleClickDetails = id => {
        const user = users.find(user => user._id === id);
        const email = user?.email;
        const avatar = user?.profile?.avatar;
        const photos = user?.profile?.photos ?? [];
        const profile = uploadData.profiles.find(profile => profile.user?.email === email);
        setSelectedProfile({...profile, avatar, photos});
    }

    // On load page: download common data;
    useEffect(() => {
        commonDataSendRequest({method: 'get'});
    }, [])

    // On common data response
    useEffect(() => {
        if (commonDataResponse) {
            setCommonData(commonDataResponse.data);
        }

    }, [commonDataResponse])

    // On check duplicates response
    useEffect(() => {
        if (checkEmailsResponse && checkEmailsResponse.data?.duplicateEmails) {
            let emails;
            const duplicateEmails = checkEmailsResponse.data.duplicateEmails;
            if (duplicateEmails.length) {
                emails = users
                    .filter(user => !duplicateEmails.find(email => email === user.email))
                    .map(user => user.email);
            } else emails = users.map(user => user.email);
            const data = filterUploadData(emails, uploadData);
            if (data) {
                let formData = new FormData();
                data.avatars.map(avatar => formData.append('avatars', avatar));
                data.profiles.map(profile => formData.append('profiles', JSON.stringify(profile)));
                data.photos.map(photo => formData.append('photos', photo));
                uploadDataSendRequest({method: 'post', data: formData});
            }
        }
    }, [checkEmailsResponse])

    // Open modal on profile select
    useEffect(() => {
        if (selectedProfile) setOpenModal(true);
        else if (openModal) setOpenModal(false);
        return () => setOpenModal(false);
    }, [selectedProfile])

    // Set users on load uploadData
    useEffect(() => {
        const {newUsers, avatarImages} = setUsersWithUploadData();
        setUsers(newUsers);
        return () => {
            if (avatarImages?.length) avatarImages.map(avatarImage => URL.revokeObjectURL(avatarImage));
        }
    }, [uploadData])

    // On search / filter / sort change: set users
    useEffect(() => {
        if (search || filter || sort) {
            const {newUsers, avatarImages} = setUsersWithUploadData({search, filter, sort});
            setUsers(newUsers);
            return () => {
                if (avatarImages?.length) avatarImages.map(avatarImage => URL.revokeObjectURL(avatarImage));
            }
        }
    }, [search, sort, filter])

    // Pagination
    useEffect(() => {
        const total = users?.length ?? 0;
        const onPage = 10;
        const pages = Math.ceil(total / onPage);
        setPagination({currentPage: 1, onPage, pages, total});
        setPaginate({...initialPaginate});
    }, [users])

    // On upload data response
    useEffect(() => {
        if (uploadDataResponse) {
            const uploaded = uploadDataResponse.data?.length ?? 0;
            const duplicated = checkEmailsResponse?.data?.duplicateEmails?.length ?? 0;
            Swal.fire({
                icon: 'success',
                title: 'New Users Uploaded!',
                text: `Uploaded: ${uploaded} users. Duplicates: ${duplicated} emails`,
            })
            setUploadPhotos([]);
            setUploadData(null);
            inputCSV.current = null;
            inputPhotos.current = null;
            setUsers([]);
        }
    }, [uploadDataResponse])

    // On errors
    useEffect(() => {
        if (uploadDataError) {
            toast.error(uploadDataError.data?.message ?? 'Something went wrong', toastConfig);
            uploadDataSetError(null);
        }
        if (commonDataError) {
            toast.error(commonDataError.data?.message ?? 'Something went wrong', toastConfig);
            commonDataSetError(null);
        }
        if (checkEmailsError) {
            toast.error(checkEmailsError.data?.message ?? 'Something went wrong', toastConfig);
            checkEmailsSetError(null);
        }

        return () => {
            uploadDataSetError(null);
            commonDataSetError(null);
            checkEmailsSetError(null);
        }
    }, [uploadDataError, commonDataError, checkEmailsError])

    // On Loading
    useEffect(() => {
        if (uploadDataIsLoading || commonDataIsLoading || checkEmailsIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);

        return () => setShowSpinner(false);
    }, [uploadDataIsLoading, commonDataIsLoading, checkEmailsIsLoading])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <MDBox>
                <MDBox component="form">
                    <Card sx={{mb: 2, p: 1}}>
                        <MDBox display="flex" alignItems="center">
                            <MDInput size="small" type="date" label="Date" disabled={!!uploadData}
                                     value={lastUpdateDate} onChange={e => setLastUpdateDate(e.target.value)}
                            />
                            <FormControlLabel style={{marginLeft: 2}} control={
                                <Switch checked={withAvatar} disabled={!!uploadData}
                                        onChange={() => setWithAvatar(!withAvatar)}/>
                            } label="Avatar"/>
                            <MDTypography variant="body2" color="text">
                                Users: {users.length ?? 0}
                            </MDTypography>
                            <Stack direction="row" spacing={2} sx={{ml: 'auto'}}>
                                {
                                    isLoading ?
                                        <SyncLoader color="#4CAF50" size={10} margin={1}/>
                                        :
                                        <>
                                            <MDButton variant="contained" size="small" component="label" color="dark"
                                                      disabled={!!uploadData || !!uploadPhotos.length}>
                                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{marginRight: 5}}/>
                                                photos
                                                <input type="file" hidden multiple ref={inputPhotos}
                                                       onChange={e => setUploadPhotos(Object.values(e.target.files))}/>
                                            </MDButton>
                                            <MDButton variant="contained" size="small" component="label" color="info"
                                                      disabled={!commonData || !!uploadData}>
                                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{marginRight: 5}}/>
                                                csv
                                                <input type="file" hidden onChange={uploadFile} ref={inputCSV}
                                                />
                                            </MDButton>
                                        </>
                                }

                                <MDButton variant="contained" size="small" color="primary" onClick={resetUsers}
                                          xs={{ml: 'auto'}}
                                          disabled={!uploadData || uploadData?.profiles?.length === users?.length}>
                                    <FontAwesomeIcon icon={faRedo} style={{marginRight: 5}}/>
                                    Reset
                                </MDButton>

                                <MDButton variant="contained" size="small" color="success" onClick={handleSendUsers}
                                          disabled={!uploadData} xs={{ml: 'auto'}}>
                                    <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 5}}/>
                                    Send
                                </MDButton>
                                <MDButton variant="contained" size="small" color="error" onClick={() => {
                                    setUploadData(null);
                                    inputCSV.current = null;
                                    inputPhotos.current = null;
                                    setUploadPhotos([]);
                                }} disabled={!uploadData}>
                                    <FontAwesomeIcon icon={faTimesCircle} style={{marginRight: 5}}/>
                                    Cancel
                                </MDButton>
                            </Stack>
                        </MDBox>
                    </Card>
                </MDBox>
                <Card sx={{mb: 2, p: 1}}>
                    <UsersList
                        title="Import"
                        users={users}
                        searchOptions={searchOptions}
                        sortOptions={sortOptions}
                        toggleOptions={toggleOptions}
                        handleSearch={(searchText, searchField) => setSearch({searchText, searchField})}
                        handleFilter={toggles => setFilter(toggles)}
                        handleSort={(sortField) => setSort({sortField})}
                        handleDeleteUsers={handleDeleteUsers}
                        handleClickDetails={handleClickDetails}
                    />
                </Card>
            </MDBox>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxHeight: '100vh',
                    overflowY: 'auto',
                    width: '100%'
                }}
            >
                <Slide direction="down" in={openModal} timeout={700}>
                    <MDBox sx={modalWideStyle}>
                        <Card>
                            <CardContent>
                                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                    <MDButton iconOnly sx={{ml: 'auto', mr: -2}} onClick={handleCloseModal}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </MDButton>
                                </MDBox>
                                {
                                    selectedProfile ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox
                                                    component="img"
                                                    src={selectedProfile.avatar}
                                                    sx={{maxWidth: '100%', height: 'auto', width: '450px'}}
                                                    borderRadius="xl"
                                                />
                                                <MDTypography variant="h5" my={2}
                                                              textAlign="center">Photos: {selectedProfile.photos?.length ?? 0}</MDTypography>

                                                {selectedProfile.photos?.length ?
                                                    selectedProfile.photos.map((photo, i) => (
                                                        <MDBox
                                                            key={'photo-' + i}
                                                            component="img"
                                                            src={photo}
                                                            sx={{maxWidth: '100%', height: 'auto', width: '450px'}}
                                                            borderRadius="xl"
                                                        />
                                                    ))
                                                    : null
                                                }

                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <MDBox>
                                                    <MDTypography variant="h5" ml={5} mb={2}>Personal
                                                        Info</MDTypography>
                                                    <Table sx={{mb: 2}}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Full
                                                                        Name: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.firstname + ' ' + selectedProfile.lastname}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Birthday: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{moment(selectedProfile.birthday).format('ll')}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Gender: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.gender.name}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Country: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.country.name}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">City: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.city}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Languages: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {selectedProfile.languages && selectedProfile.languages.length ?
                                                                        selectedProfile.languages.map((language, i) => (
                                                                            i === 0 ?
                                                                                <MDTypography key={'lang-' + i}
                                                                                              variant="button">{language.name}</MDTypography>
                                                                                :
                                                                                <MDTypography key={'lang-' + i}
                                                                                              variant="button">, {language.name}</MDTypography>

                                                                        ))
                                                                        : ''
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </MDBox>

                                                <MDBox>
                                                    <MDTypography variant="h5" ml={5} mb={2}>User Account</MDTypography>
                                                    <Table sx={{mb: 2}}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Email: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user.email}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">User
                                                                        registered: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{moment(selectedProfile.user?.registeredAt).format('ll')}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Subscribed
                                                                        For Newsletters: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user?.subscribedForNewsletters ? 'yes' : 'no'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Subscribed
                                                                        For Notifications: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user?.subscribedForNotifications ? 'yes' : 'no'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Activated: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user?.activated ? 'yes' : 'no'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Admin: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user?.isAdmin ? 'yes' : 'no'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Fake: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.user?.f ? 'yes' : 'no'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </MDBox>

                                                <MDBox>
                                                    <MDTypography variant="h5" ml={5} mb={2}>Body
                                                        Parameters</MDTypography>
                                                    <Table sx={{mb: 2}}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Height: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.height} cm</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Weight: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.weight} kg</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Hair
                                                                        Color: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.hairColor?.name}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Eye
                                                                        Color: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.eyeColor?.name}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </MDBox>

                                                <MDBox>
                                                    <MDTypography variant="h5" ml={5} mb={2}>Profile Data</MDTypography>
                                                    <Table sx={{mb: 2}}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Seeking: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.seeking?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Goal: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.goal?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Marital
                                                                        Status: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.maritalStatus?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Children
                                                                        Amount: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.childrenAmount?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Education: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.education?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Occupation: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.occupation ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Religion: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.religion?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Drinking
                                                                        Habit: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.drinkingHabit?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Smoking
                                                                        Habit: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.smokingHabit?.name ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Hobbies: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {selectedProfile.hobbies && selectedProfile.hobbies.length ?
                                                                        selectedProfile.hobbies.map((hobby, i) => (
                                                                            i === 0 ?
                                                                                <MDTypography key={'hobby-' + i}
                                                                                              variant="button">{hobby.name ?? '-'}</MDTypography>
                                                                                :
                                                                                <MDTypography key={'hobby-' + i}
                                                                                              variant="button">, {hobby.name ?? '-'}</MDTypography>

                                                                        ))
                                                                        : ''
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </MDBox>

                                                <MDBox sx={{mb: 2}}>
                                                    <MDTypography variant="h5" ml={5} mb={2}>About</MDTypography>
                                                    <MDTypography variant="button"
                                                                  color="text">{selectedProfile.about ?? '-'}</MDTypography>
                                                </MDBox>

                                                <MDBox>
                                                    <MDTypography variant="h5" ml={5} mb={2}>Membership
                                                        info</MDTypography>
                                                    <Table sx={{mb: 2}}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button"
                                                                                  fontWeight="bold">Membership: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.isPremium ? 'premium' : 'free'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Membership
                                                                        until: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.premiumEnds ? moment(selectedProfile.premiumEnds).format('ll') : '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Letters: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfLetters ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Gifts: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfGifts ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Actions: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfActions ?? '-'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Lets Match: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfLetsMatch ?? 'unlimited'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Ice Breakers: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfIceBreakers ?? 'unlimited'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography variant="button" fontWeight="bold">Amount
                                                                        Of Greetings: </MDTypography>
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    <MDTypography
                                                                        variant="button">{selectedProfile.amountOfGreetings ?? 'unlimited'}</MDTypography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                        : ''
                                }
                            </CardContent>
                        </Card>
                    </MDBox>
                </Slide>
            </Modal>
        </MDBox>
    );
};

export default ImportData;