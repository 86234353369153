import React, {useContext, useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {
    Autocomplete,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    Slide,
    TextField
} from "@mui/material";
import DataTable from "../elements/DataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MDBox from "../components/MDBox";
import {ADVANTAGE, ARTICLE, ARTICLE_SEARCH, CONCEPT, MEMBER, STAT_INFO, STORY} from "../utils/serverUrls";
import useServer from "../hooks/useServer";
import {DEFAULT_PAGINATION_ARTICLES, modalStyle, toastConfig} from "../utils/constants";
import {SpinnerContext} from "../contexts/spinner/Spinner";
import {generateTable} from "../utils/functions";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import {faClipboardList, faGem, faPaperclip, faScroll, faTimes, faUsers} from "@fortawesome/free-solid-svg-icons";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import MDInput from "../components/MDInput";
import {StyledDropZone} from "react-drop-zone";
import Swal from "sweetalert2";
import FormData from "form-data";
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as brandsIcons from "@fortawesome/free-brands-svg-icons";
import usePagination from "../hooks/usePagination";

const initialFormData = {item: null, title: ''};

const colors = [
    'primary', 'secondary', 'info', 'warning', 'success', 'error', 'light', 'dark'
];

const newAdvantage = {
    color: '',
    icon: '',
    title: '',
    description: ''
};

const newStory = {
    img: null,
    title: '',
    review: '',
    author: ''
};

const newMember = {
    img: null,
    name: '',
    description: '',
    position: ''
};

const newConcept = {
    img: null,
    title: '',
    description: ''
};

const newArticle = {
    img: null,
    title: '',
    short: '',
    description: '',
    author: '',
    onHomePage: "false",
    createdAt: new Date()
};

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const getIconForStat = (title) => {
    if (title === 'advantages') return 'faGem';
    if (title === 'stories') return 'faScroll';
    if (title === 'concepts') return 'faClipboardList';
    if (title === 'members') return 'faUsers';
    else return 'faCoffee';
}

const FrontOptions = () => {
    const {pathname} = useLocation();
    const [showSpinner, setShowSpinner] = useContext(SpinnerContext);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [advantages, setAdvantages] = useState([]);
    const [stories, setStories] = useState([]);
    const [members, setMembers] = useState([]);
    const [concepts, setConcepts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [infoStat, setInfoStat] = useState([]);
    const [advantagesResponse, advantagesError, advantagesIsLoading, advantagesSendRequest, advantagesSetError] = useServer(ADVANTAGE);
    const [storiesResponse, storiesError, storiesIsLoading, storiesSendRequest, storiesSetError] = useServer(STORY);
    const [membersResponse, membersError, membersIsLoading, membersSendRequest, membersSetError] = useServer(MEMBER);
    const [conceptsResponse, conceptsError, conceptsIsLoading, conceptsSendRequest, conceptsSetError] = useServer(CONCEPT);
    const [articlesResponse, articlesError, articlesIsLoading, articlesSendRequest, articlesSetError] = useServer(ARTICLE);
    const [searchArticlesResponse, searchArticlesError, searchArticlesIsLoading, searchArticlesSendRequest, searchArticlesSetError] = useServer(ARTICLE_SEARCH);
    const [infoStatResponse, infoStatError, infoStatIsLoading, infoStatSendRequest, infoStatSetError] = useServer(STAT_INFO);

    const {setPagination, paginate, paginationContainer} = usePagination(DEFAULT_PAGINATION_ARTICLES);

    const previewFile = file => URL.createObjectURL(file);

    const handleCloseModal = () => setFormData(initialFormData);

    const handleSaveChanges = () => {
        let isValid = true;
        Object.keys(formData.item).map(key => {
            if (key !== '__v' && !formData.item[key]) {
                isValid = false;
            }
        })
        if (isValid) {
            const options = {method: formData.item?._id ? 'patch' : 'post', data: formData.item};
            if (typeof formData.item.img === 'object') {
                let fd = new FormData();
                fd.append('img', formData.item.img)
                fd.append('item', JSON.stringify(formData.item))
                options.data = fd;
            }
            if (formData.title === 'advantages') advantagesSendRequest(options);
            if (formData.title === 'stories') storiesSendRequest(options);
            if (formData.title === 'members') membersSendRequest(options);
            if (formData.title === 'concepts') conceptsSendRequest(options);
            if (formData.title === 'articles') articlesSendRequest(options);
            setOpenModal(false);
        } else toast.error('Empty fields in form', toastConfig);
    }

    const handleChangeFormData = (key, value) => setFormData({...formData, item: {...formData.item, [key]: value}});

    const handleManageClick = (isEdit, title, id) => {
        if (isEdit && title && !id) {    // add new
            let item;
            if (title === 'advantages') item = newAdvantage;
            if (title === 'stories') item = newStory;
            if (title === 'members') item = newMember;
            if (title === 'concepts') item = newConcept;
            if (title === 'articles') item = newArticle;
            setFormData({item, title});
        } else if (isEdit && title && id) {   // edit
            let item;
            if (title === 'advantages') item = advantages.find(advantage => advantage._id === id);
            if (title === 'stories') item = stories.find(story => story._id === id);
            if (title === 'members') item = members.find(member => member._id === id);
            if (title === 'concepts') item = concepts.find(concept => concept._id === id);
            if (title === 'articles') item = articles.find(article => article._id === id);
            setFormData({item, title});
        } else if (!isEdit && title && id) {  // delete
            Swal.fire({
                icon: 'question',
                text: 'Delete item?',
                confirmButtonText: 'delete',
                confirmButtonColor: 'red',
                showDenyButton: true,
                denyButtonText: 'cancel',
                denyButtonColor: 'green',
                width: '320px'
            }).then(result => {
                if (result.isConfirmed) {
                    const option = {method: 'delete', data: {id}};
                    if (title === 'advantages') advantagesSendRequest();
                    if (title === 'stories') storiesSendRequest(option);
                    if (title === 'members') membersSendRequest(option);
                    if (title === 'concepts') conceptsSendRequest(option);
                    if (title === 'articles') articlesSendRequest(option);
                } else if (result.isDenied) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Item is not deleted',
                        showConfirmButton: false,
                        timer: 1500,
                        width: '320px'
                    })
                }
            })
        }
    }

    // On change pathname
    useEffect(() => {
        if (pathname === '/front-options') {
            infoStatSendRequest({method: 'get'});
        }
        if (pathname === '/front-options/advantages') {
            advantagesSendRequest({method: 'get'});
        }
        if (pathname === '/front-options/stories') {
            storiesSendRequest({method: 'get'});
        }
        if (pathname === '/front-options/members') {
            membersSendRequest({method: 'get'});
        }
        if (pathname === '/front-options/concepts') {
            conceptsSendRequest({method: 'get'});
        }
        if (pathname === '/front-options/articles') {
            searchArticlesSendRequest({method: 'post', params: Object.assign(paginate)})
        }
    }, [pathname, paginate])

    // On Advantages Respond
    useEffect(() => {
        if (advantagesResponse) {
            if (Array.isArray(advantagesResponse.data)) {
                setAdvantages(advantagesResponse.data);
            } else if (typeof advantagesResponse.data === 'string') {
                setAdvantages(advantages.filter(advantage => advantage._id !== advantagesResponse.data));
            } else if (typeof advantagesResponse.data === 'object') {
                if (formData.item?._id) {
                    let newAdvantages = advantages.map(advantage => advantage._id === advantagesResponse.data?._id ?
                        advantagesResponse.data : advantage);
                    setAdvantages(newAdvantages);
                } else setAdvantages([...advantages, advantagesResponse.data]);
            }
            setFormData(initialFormData);
        }
    }, [advantagesResponse])

    // On Stories Respond
    useEffect(() => {
        if (storiesResponse) {
            if (Array.isArray(storiesResponse.data)) {
                setStories(storiesResponse.data);
            } else if (typeof storiesResponse.data === 'string') {
                setStories(stories.filter(story => story._id !== storiesResponse.data));
            } else if (typeof storiesResponse.data === 'object') {
                if (formData.item?._id) {
                    let newStories = stories.map(story => story._id === storiesResponse.data?._id ?
                        storiesResponse.data : story);
                    setStories(newStories);
                } else setStories([...stories, storiesResponse.data]);
            }
            setFormData(initialFormData);
        }
    }, [storiesResponse])

    // On Members Respond
    useEffect(() => {
        if (membersResponse) {
            if (Array.isArray(membersResponse.data)) {
                setMembers(membersResponse.data);
            } else if (typeof membersResponse.data === 'string') {
                setMembers(members.filter(member => member._id !== membersResponse.data));
            } else if (typeof membersResponse.data === 'object') {
                if (formData.item?._id) {
                    let newMembers = members.map(member => member._id === membersResponse.data?._id ?
                        membersResponse.data : member);
                    setMembers(newMembers);
                } else setMembers([...members, membersResponse.data]);
            }
            setFormData(initialFormData);
        }
    }, [membersResponse])

    // On Concepts Respond
    useEffect(() => {
        if (conceptsResponse) {
            if (Array.isArray(conceptsResponse.data)) {
                setConcepts(conceptsResponse.data);
            } else if (typeof conceptsResponse.data === 'string') {
                setConcepts(concepts.filter(concept => concept._id !== conceptsResponse.data));
            } else if (typeof conceptsResponse.data === 'object') {
                if (formData.item?._id) {
                    let newConcepts = concepts.map(concept => concept._id === conceptsResponse.data?._id ?
                        conceptsResponse.data : concept);
                    setConcepts(newConcepts);
                } else setConcepts([...concepts, conceptsResponse.data]);
            }
            setFormData(initialFormData);
        }
    }, [conceptsResponse])

    // On Articles Respond
    useEffect(() => {
        if (articlesResponse) {
            console.log(articlesResponse.data)
            if (Array.isArray(articlesResponse.data)) {
                setArticles(articlesResponse.data);
            } else if (typeof articlesResponse.data === 'string') {
                setArticles(articles.filter(article => article._id !== articlesResponse.data));
            } else if (typeof articlesResponse.data === 'object') {
                if (formData.item?._id) {
                    let newArticles = articles.map(article => article._id === articlesResponse.data?._id ?
                        articlesResponse.data : article);
                    setArticles(newArticles);
                } else setArticles([...articles, articlesResponse.data]);
            }
            setFormData(initialFormData);
        }
    }, [articlesResponse])

    // On Search Articles Response
    useEffect(() => {
        if (searchArticlesResponse) {
            setArticles(searchArticlesResponse.data?.articles);
            setPagination(searchArticlesResponse.data?.pagination);
            setFormData(initialFormData);
        }
    }, [searchArticlesResponse])

    // On Stat Info Response
    useEffect(() => {
        if (infoStatResponse && infoStatResponse.data && infoStatResponse.data.length > 0) {
            const newStat = [];
            infoStatResponse.data.map(stat => {
                newStat.push(
                    {
                        title: stat.title,
                        icon: getIconForStat(stat.title),
                        color: colors[getRandomNumber(0, colors.length - 1)],
                        value: stat.value
                    }
                );
            })
            setInfoStat(newStat);
        }
    }, [infoStatResponse])

    // On Error
    useEffect(() => {
        if (advantagesError) {
            toast.error(advantagesError.data?.message ?? 'Something went wrong', toastConfig);
            advantagesSetError(null);
        }
        if (storiesError) {
            toast.error(storiesError.data?.message ?? 'Something went wrong', toastConfig);
            storiesSetError(null);
        }
        if (membersError) {
            toast.error(membersError.data?.message ?? 'Something went wrong', toastConfig);
            membersSetError(null);
        }
        if (conceptsError) {
            toast.error(conceptsError.data?.message ?? 'Something went wrong', toastConfig);
            conceptsSetError(null);
        }
        if (articlesError) {
            toast.error(articlesError.data?.message ?? 'Something went wrong', toastConfig);
            articlesSetError(null);
        }
        if (infoStatError) {
            toast.error(infoStatError.data?.message ?? 'Something went wrong', toastConfig);
            infoStatSetError(null);
        }
        if (searchArticlesError) {
            toast.error(searchArticlesError.data?.message ?? 'Something went wrong', toastConfig);
            searchArticlesSetError(null);
        }
        return () => {
            advantagesSetError(null);
            storiesSetError(null);
            membersSetError(null);
            conceptsSetError(null);
            articlesSetError(null);
            infoStatSetError(null);
            searchArticlesSetError(null);
        }
    }, [advantagesError, storiesError, membersError, conceptsError, articlesError, infoStatError, searchArticlesError])

    // On Loading
    useEffect(() => {
        if (advantagesIsLoading || storiesIsLoading || membersIsLoading || conceptsIsLoading ||
            searchArticlesIsLoading || articlesIsLoading || infoStatIsLoading) setShowSpinner(true);
        else if (showSpinner) setShowSpinner(false);
        return () => setShowSpinner(false);
    }, [advantagesIsLoading, storiesIsLoading, membersIsLoading, conceptsIsLoading, articlesIsLoading, infoStatIsLoading, searchArticlesIsLoading])

    // On formData change
    useEffect(() => {
        if (formData.item) setOpenModal(true);
        else if (openModal) setOpenModal(false);
        return () => setOpenModal(false);
    }, [formData])

    return (
        <MDBox sx={{p: {xs: 1, md: 2, xl: 3}}}>
            <ToastContainer/>
            <Routes>
                <Route path="/" element={
                    <Grid container spacing={2}>
                        {infoStat && infoStat.length > 0 && infoStat.map((stat, i) => (
                            <Grid item xs={6} key={i}>
                                <Card sx={{my: 2, p: 1}}>
                                    <MDBox display="flex" px={2} py={1}>
                                        <MDBox
                                            color="white"
                                            bgColor={stat.color ?? 'info'}
                                            variant="gradient"
                                            borderRadius="lg"
                                            shadow="lg"
                                            opacity={1}
                                            p={2}
                                            mt={-3}
                                            mr={2}
                                            width={60}
                                            height={60}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <FontAwesomeIcon
                                                icon={(regularIcons[stat.icon] || solidIcons[stat.icon] || brandsIcons[stat.icon]) ?? solidIcons['faCoffee']}/>
                                        </MDBox>
                                        <MDTypography variant="subtitle2" color="text" sx={{my: 1, ml: 'auto'}}
                                                      textTransform="uppercase">
                                            {stat.title ?? 'title'}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox p={2} ml="auto">
                                        <MDTypography variant="h4">{stat.value ?? 0}</MDTypography>
                                    </MDBox>
                                    <MDBox>
                                        <Link to={`/front-options/${stat.title}`}>
                                            <MDButton fullWidth variant="contained" size="small" color="white">
                                                details
                                            </MDButton>
                                        </Link>
                                    </MDBox>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                }/>
                <Route path="/advantages" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={generateTable(advantages, 'advantages')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faGem}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>
                <Route path="/stories" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={generateTable(stories, 'stories')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faScroll}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>

                <Route path="/members" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={generateTable(members, 'members')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faUsers}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>

                <Route path="/concepts" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={generateTable(concepts, 'concepts')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            icon={<FontAwesomeIcon icon={faClipboardList}/>}
                            manage
                            onManageClick={handleManageClick}
                        />
                    </Card>
                }/>

                <Route path="/articles" element={
                    <Card sx={{my: 2, p: 1}}>
                        <DataTable
                            table={generateTable(articles, 'articles')}
                            showTotalEntries={false}
                            entriesPerPage={false}
                            hideHeader={true}
                            icon={<FontAwesomeIcon icon={faPaperclip}/>}
                            manage
                            onManageClick={handleManageClick}
                        />

                        {paginationContainer}
                    </Card>
                }/>
            </Routes>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxHeight: '100vh',
                    overflowY: 'auto'
                }}
            >
                <Slide direction="down" in={openModal} timeout={700}>
                    <MDBox sx={modalStyle}>
                        <Card>
                            <CardContent>
                                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                    <MDTypography variant="h5">
                                        {formData?.item?._id ? 'Edit item in ' : 'Add item to '}
                                        {formData?.title ? `'${formData.title}'` : ''}
                                    </MDTypography>
                                    <MDButton iconOnly sx={{mr: -2}} onClick={handleCloseModal}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </MDButton>
                                </MDBox>
                                {formData && formData.item && Object.keys(formData.item).map(key => {
                                    switch (key) {
                                        case '_id':
                                            return null;
                                        case '__v':
                                            return null;
                                        case 'createdAt':
                                            return null;
                                        case 'img':
                                            return <MDBox key={key} position="relative">
                                                {formData.item?.img ?
                                                    <>
                                                        <MDBox component="img" width="300px"
                                                               src={typeof formData.item?.img === 'string' ?
                                                                   process.env.REACT_APP_API_URL + '/' + formData.item?.img
                                                                   :
                                                                   previewFile(formData.item?.img)
                                                               }
                                                        />

                                                        <MDButton
                                                            iconOnly
                                                            color="error"
                                                            sx={{position: 'absolute', bottom: 0, right: 0}}
                                                            onClick={() => setFormData({
                                                                ...formData,
                                                                item: {...formData.item, img: null}
                                                            })}
                                                        >
                                                            <FontAwesomeIcon icon={faTimes}/>
                                                        </MDButton>
                                                    </>
                                                    :
                                                    <StyledDropZone
                                                        accept="image/*"
                                                        onDrop={file => setFormData({
                                                            ...formData,
                                                            item: {...formData.item, img: file}
                                                        })}
                                                        children={"Add Image *"}
                                                        className="picture-dropzone"
                                                    />
                                                }
                                            </MDBox>;
                                        case 'color':
                                            return <Autocomplete
                                                key={key}
                                                id="colors"
                                                fullWidth
                                                options={colors}
                                                value={formData.item?.color || null}
                                                onChange={(e, v) => handleChangeFormData(key, v)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                size="small"
                                                autoHighlight
                                                sx={{my: 1}}
                                                renderOption={(props, option) => (
                                                    <MDBox component="li"
                                                           sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                        <MDBox
                                                            bgColor={option}
                                                            borderRadius="lg"
                                                            shadow="lg"
                                                            p={2}
                                                            mr={2}
                                                        />
                                                        {option}
                                                    </MDBox>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Choose a color"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />;
                                        case 'description':
                                            return <MDInput
                                                key={key}
                                                value={formData.item?.description ?? ''}
                                                onChange={e => handleChangeFormData(key, e.target.value)}
                                                fullWidth
                                                label="Description..."
                                                multiline
                                                rows={5}
                                                sx={{my: 1}}
                                            />;
                                        case 'review':
                                            return <MDInput
                                                key={key}
                                                value={formData.item?.review ?? ''}
                                                onChange={e => handleChangeFormData(key, e.target.value)}
                                                fullWidth
                                                label="Review..."
                                                multiline
                                                rows={5}
                                                sx={{my: 1}}
                                            />;
                                        case 'short':
                                            return <MDInput
                                                key={key}
                                                value={formData.item?.short ?? ''}
                                                onChange={e => handleChangeFormData(key, e.target.value)}
                                                fullWidth
                                                label="Short..."
                                                multiline
                                                rows={5}
                                                sx={{my: 1}}
                                            />;
                                        case 'onHomePage':
                                            return <FormControl key={key}>
                                                <MDTypography variant="button" color="text">On Home Page</MDTypography>
                                                <RadioGroup row name="on-home-page"
                                                            value={formData.item[key]}
                                                            onChange={e => handleChangeFormData(key, e.target.value)}
                                                >
                                                    <FormControlLabel value="true" control={<Radio/>} label="Yes"/>
                                                    <FormControlLabel value="false" control={<Radio/>} label="No"/>
                                                </RadioGroup>
                                            </FormControl>;
                                        default:
                                            return <MDBox key={key}>
                                                <MDInput
                                                    variant="standard"
                                                    label={key}
                                                    data-field={key}
                                                    type={typeof formData.item[key]}
                                                    value={formData.item[key]}
                                                    onChange={e => handleChangeFormData(key, e.target.value)}
                                                    fullWidth
                                                    sx={{my: 1}}
                                                />
                                            </MDBox>;
                                    }
                                })}

                            </CardContent>
                            <CardActions>
                                <MDBox display="flex" justifyContent="space-around" sx={{width: "100%"}}>
                                    <MDButton size="small" color="success" sx={{px: 3}}
                                              onClick={handleSaveChanges}
                                              disabled={advantagesIsLoading}
                                    >
                                        Save
                                    </MDButton>
                                    <MDButton size="small" color="dark" onClick={handleCloseModal}>Cancel</MDButton>
                                </MDBox>
                            </CardActions>
                        </Card>
                    </MDBox>
                </Slide>
            </Modal>
        </MDBox>
    );
};

export default FrontOptions;