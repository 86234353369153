import React from 'react';
import {Route, Routes} from "react-router-dom";
import ProfileOptions from "./pages/ProfileOptions";
import ProductOptions from "./pages/ProductOptions";
import Dashboard from "./pages/Dashboard";
import FrontOptions from "./pages/FrontOptions";
import Users from "./pages/Users";
import UserInfo from "./pages/UserInfo";
import Newsletters from "./pages/Newsletters";
import Payments from "./pages/Payments";
import Settings from "./pages/Settings";
import HtmlEditor from "./pages/HtmlEditor";
import ImportData from "./pages/ImportData";

const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/profile-options" element={<ProfileOptions/>}/>
            <Route path="/product-options/*" element={<ProductOptions/>}/>
            <Route path="/front-options/*" element={<FrontOptions/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/users/:id" element={<UserInfo/>}/>
            <Route path="/newsletters" element={<Newsletters/>}/>
            <Route path="/payments" element={<Payments/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/import-data" element={<ImportData/>}/>
            <Route path="/html-edit" element={<HtmlEditor/>}/>
            <Route path="/*" element={<Dashboard/>}/>
        </Routes>

    );
};

export default MainRoutes;